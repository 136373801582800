import { useCallback } from 'react';
import { TurmasInputsValuesObject } from '../../../../../types/turmas/turmas-inputs-values-object.type';
import { TurmaStatusForInput } from '../../../../../enum/turmas/turma-status-for-input.enum';
import { nowDate } from '../../../../../util/format';
import { Operators } from '../../../../../enum/operators.enum';
import { useTurmasPredicateStore } from '../../../../../store/turmas/turmas-predicate.store';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { usePessoaIdStore } from '../../../../../store/global/pessoa-id.store';

interface UseAddPredicateOnFindTurmasProps {
  inputValuesObject: TurmasInputsValuesObject;
}

export default function useAddPredicateOnFindTurmas({
  inputValuesObject,
}: UseAddPredicateOnFindTurmasProps) {
  const { status, modalidade, curso } = inputValuesObject;

  const { turmasPredicate } = useTurmasPredicateStore();

  const addPredicateBasedOnStatus = useCallback(() => {
    if (status === TurmaStatusForInput.Finished) {
      turmasPredicate.removeOption('ativo');
      return turmasPredicate.addOption(
        'dataFim',
        nowDate(),
        Operators.LESS_THAN,
      );
    }

    turmasPredicate.addOption('ativo', true);
    return turmasPredicate.addOption(
      'dataFim',
      nowDate(),
      Operators.GREATER_THAN_OR_EQUAL,
    );
  }, [status]);

  const addPredicateBasedOnModalidade = useCallback(() => {
    if (modalidade && modalidade !== 'TDS')
      return turmasPredicate.addOption('cursoModalidadeId', modalidade);

    return turmasPredicate.removeOption('cursoModalidadeId');
  }, [modalidade]);

  const addPredicateBasedOnCurso = useCallback(() => {
    if (curso && curso !== 'TDS')
      return turmasPredicate.addOption('cursoId', curso);

    return turmasPredicate.removeOption('cursoId');
  }, [curso]);

  const { unidade } = useGlobalContext();

  const { pessoaId } = usePessoaIdStore();

  const addPredicateDefault = useCallback(() => {
    if (!unidade.id) return;

    turmasPredicate.addOption('unidadePessoa.pessoa.id', pessoaId);
    turmasPredicate.addOption('unidade.id', unidade.id);
    turmasPredicate.sort = 'desc';
    turmasPredicate.addSort('dataFim');

    if (inputValuesObject.searchbar)
      turmasPredicate.addOption(
        'descricao',
        inputValuesObject.searchbar,
        Operators.CONTAINS,
      );
  }, [inputValuesObject.searchbar, pessoaId, turmasPredicate, unidade.id]);

  const addAllPredicates = useCallback(() => {
    addPredicateBasedOnStatus();
    addPredicateBasedOnModalidade();
    addPredicateBasedOnCurso();
    addPredicateDefault();
  }, [
    addPredicateBasedOnCurso,
    addPredicateBasedOnModalidade,
    addPredicateBasedOnStatus,
    addPredicateDefault,
  ]);

  return {
    addAllPredicates,
  };
}
