import { AxiosResponse } from 'axios';
import {
  ITrilhaCleanDTO,
  ITrilhaDTO,
  ITrilhaFullDTO
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import http from './../helpers/http-common';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useTrilhaService = () => {
  const resourceURL = 'trilha';

  const findDestaques = (
    acessoB2c: boolean,
    acessoB2b: boolean,
    acessoComercial: boolean,
    predicate: Predicate = new Predicate(),
    tipoUnidade: number
  ): Promise<AxiosResponse<ITrilhaFullDTO[]>> =>
    http.get<ITrilhaFullDTO[]>(
      resourceURL + `/destaque/${acessoB2c}/${acessoB2b}/${acessoComercial}/${tipoUnidade}`,
      formatHttpParams(predicate),
    );

  const findTrilhaFilterCustom = (
    acessoB2c: boolean,
    acessoB2b: boolean,
    acessoComercial: boolean,
    tipoUnidade: number,
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<ITrilhaFullDTO[]>> =>
    http.get<ITrilhaFullDTO[]>(
      resourceURL +
      `/trilha-filtro/${acessoB2c}/${acessoB2b}/${acessoComercial}/${tipoUnidade}`,
      formatHttpParams(predicate),
    );

  const findFullCustom = (
    acessoB2c: boolean,
    acessoB2b: boolean,
    acessoComercial: boolean,
    tipoUnidade: number,
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<ITrilhaFullDTO>>> =>
    http.get<PageableResponse<ITrilhaFullDTO>>(
      resourceURL +
      `/trilha-lista/${acessoB2c}/${acessoB2b}/${acessoComercial}/${tipoUnidade}`,
      formatHttpParams(predicate),
    );

  return {
    ...baseFind<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
    ...baseFindById<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
    ...baseFindList<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
    ...baseCreate<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
    ...baseUpdate<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
    ...baseDelete(resourceURL),
    findDestaques,
    findTrilhaFilterCustom,
    findFullCustom,
  };
};
