import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ICursoFullDTO } from '../../models/happy-code-api.model';

interface ItemProps {
  width: string[];
  image: string;
  url: string;
  item?: ICursoFullDTO;
}

export const Item = ({ image, url, width, item }: ItemProps) => {
  return (
    <>
      <div className="d-flex-row j-start-a-center w-100 row-items">
        <div className="item" style={{ width: width[0] }}>
          <img src={image} alt="" />
        </div>
        <div className="item" style={{ width: width[1] }}>
          {item?.descricao}
        </div>
        <div className="item" style={{ width: width[2], paddingLeft: 25 }}>
          {item?.negocio?.descricao}
        </div>
        <div className="item" style={{ width: width[3], paddingLeft: 25 }}>
          {item?.duracaoMinutos / 60}
        </div>
        <div className="item" style={{ width: width[4] }}>
          <Link to={url}>
            <Button className="button-primary">
              Ver Aulas <Unicons.UilArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
