import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@material-ui/lab';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemArquivo } from '../../components/Item/arquivo';
import { Sidebar } from '../../components/Sidebar';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import {
  ICursoAulaPadraoDTO,
  IMaterialDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoAulaPadraoService } from '../../services/curso-aula-padrao.service';
import { useMaterialService } from '../../services/material.service';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const Arquivos = () => {
  const navigate = useNavigate();
  const { setIsGlobalLoading } = useGlobalContext();
  const materialService = useMaterialService();
  const cursoAulaPadraoService = useCursoAulaPadraoService();
  const predicate = new Predicate();
  const { idAula } = useParams<any>();
  const [materiais, setMateriais] = useState<IMaterialDTO[]>();
  const [aula, setAula] = useState<ICursoAulaPadraoDTO>();
  const [response, setResponse] = useState<PageableResponse<IMaterialDTO>>();

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    predicate.removeAllOption();

    const typingTimeout = setTimeout(() => {
      if (search) {
        predicate.addOption('descricao', search, Operators.CONTAINS);
      }

      fetch();
    }, 1200);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const fetch = () => {
    setIsGlobalLoading(true);

    const parseIdToNumber = parseInt(idAula);

    cursoAulaPadraoService
      .findById({ id: parseIdToNumber })
      .then((response) => {
        setAula(response?.data);
        predicate.addOption('cursoAulaPadrao.id', idAula);
        predicate.addOption('ativo' , true )
        return materialService.find(predicate);
      })
      .then((response) => {
        setMateriais(response?.data?.content);
        setResponse(response.data);
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };

  useEffect(() => {
    if (idAula) {
      fetch();
    }
  }, [idAula]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    fetch();
  };

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Button className="button-blue" onClick={() => navigate(-1)}>
              <Unicons.UilArrowLeft />
              Voltar
            </Button>
          </div>
          <div className="w-100 mb-20">
            <p>#{idAula}</p>
            <h1>
              {aula?.descricao} ({materiais?.length || 0} materiais)
            </h1>
            <p>Materiais de apoio para preparação das aulas</p>
          </div>
          <div className="w-100 mb-20 table">
            <ul className="d-flex-row j-start-a-center table-head w-100">
              <li style={{ width: '24%' }}></li>
              <li style={{ width: '5%' }}>
                <span>ID</span>
                <Unicons.UilDirection size={20} />
              </li>
              <li style={{ width: '26%' }}>
                <span>Nome Material</span>
                <Unicons.UilDirection size={20} />
              </li>
              <li style={{ width: '15%' }}>
                <span>Tamanho</span>
                <Unicons.UilDirection size={20} />
              </li>
              <li style={{ width: '10%' }}>
                <span>Download</span>
              </li>
            </ul>
            <div className="w-100">
              {materiais?.map?.((item) => {
                return (
                  <ItemArquivo
                    tipo={item?.tipoMaterial}
                    width={['26%', '5%', '30%', '35%', '15%', '10%']}
                    material={true}
                    nameArquivo={item?.descricao}
                    name={item?.codigo}
                    tamanho={item?.tamanhoArquivo}
                    url={item?.enderecoArquivo}
                    id={item?.id}
                  />
                );
              })}
              {/* <ItemArquivo icon="PDF" width={['5%', '75%', '15%', '15%']} material={true} name="Apresentação do curso.pdf" tamanho="40.5 Mb" url="/" /> */}
              {/* <ItemArquivo icon="Media" width={['5%', '75%', '15%', '15%']} material={true} name="Apresentação do curso.mp3" tamanho="100.5 Mb" url="/" /> */}
              {/* <ItemArquivo icon="Video" width={['5%', '75%', '15%', '15%']} material={true} name="Apresentação do curso.mp4" tamanho="1.5 Gb" url="/" /> */}
              {/* <ItemArquivo icon="Link" width={['5%', '75%', '15%', '15%']} material={false} name="Apresentação do curso.ppt" tamanho="--" url="/" /> */}
            </div>
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination
              onChange={(event, page) => onPageChange(event, page - 1)}
              page={response ? response.pageable.pageNumber + 1 : 0}
              count={response ? response.totalPages : 0}
            />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
