import * as Unicons from '@iconscout/react-unicons';
import { Button, Form, Input, Pagination, Select } from 'antd';
import { Link } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const CursosSearch = () => {
  const { Option } = Select;

  function handleChange(value: string) {}

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Link to="/cursos">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
            <h1 className="fav-title">Cursos (999)</h1>
          </div>
          <Form
            id="box-form"
            className="d-flex-row j-start-a-center w-100 mb-10"
          >
            <div className="d-flex-row j-start-a-center input-box w-100">
              <Unicons.UilSearch
                size="20"
                color="var(--dark-secondary)"
                className="iconForm"
              />
              <Form.Item name="search" noStyle={true}>
                <Input placeholder="Buscar trilha por curso ou status" />
              </Form.Item>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Curso
              <Select
                defaultValue="Todos"
                style={{ width: 85 }}
                onChange={handleChange}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Curso
              <Select
                defaultValue="Status"
                style={{ width: 85 }}
                onChange={handleChange}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
          </Form>
          <div className="w-100 mb-10 result">
            <p>
              Encontramos <span>04 Resultados</span> para sua busca
            </p>
          </div>
          <div className="d-flex-row j-between-a-start w-100 mb-20 flex-wrap"></div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
