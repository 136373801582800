import {
  ITopicoAulaCleanDTO,
  ITopicoAulaDTO,
  ITopicoAulaFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useTopicoAulaService = () => {
  const resourceURL = 'topico-aula';

  return {
    ...baseFind<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(
      resourceURL,
    ),
    ...baseFindById<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(
      resourceURL,
    ),
    ...baseFindList<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(
      resourceURL,
    ),
    ...baseCreate<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(
      resourceURL,
    ),
    ...baseDelete(resourceURL),
  };
};
