import './conteudo-aula-label-paragrafo.styles.scss';
import {
  useConteudoAulaLabelParagrafo
} from './use-conteudo-aula-label-paragrafo.hook';

interface ConteudoAulaLabelParagrafoProps {
  label: string;
  paragrafo: string;
}

export default function ConteudoAulaLabelParagrafo({
                                                     label,
                                                     paragrafo
                                                   }: ConteudoAulaLabelParagrafoProps) {
  const { convertParagrafoToTitleCase } = useConteudoAulaLabelParagrafo();

  return (
    <div className={'conteudo-aula-label-paragrafo'}>
      <label className={'conteudo-aula-label-paragrafo__label'}>{label}</label>

      <p className={'conteudo-aula-label-paragrafo__paragrafo'}>
        {convertParagrafoToTitleCase(paragrafo, label)}
      </p>
    </div>
  );
}
