import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IPasswordChangeProfile,
  IPasswordChangeRequest,
  IUserAuthDTO,
  IUsuarioCleanDTO,
  IUsuarioDTO,
  IUsuarioFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useUsuarioService = () => {
  const resourceURL = 'usuario';

  const getPrincipal = async (): Promise<AxiosResponse<IUserAuthDTO>> => {
    return await http.get<IUserAuthDTO>(resourceURL + '/principal');
  };

  const recuperaSenha = async (email: string) => {
    return await http.post<void>(
      resourceURL + `/recupera-senha?login=${email}`,
      null,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_PASSWORD_API}`,
        },
      },
    );
  };

  const alteraSenha = async (payload: IPasswordChangeRequest) => {
    return await http.post<void>(resourceURL + `/altera-senha`, payload, {
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_PASSWORD_API}`,
      },
    });
  };

  const alteraSenhaProfile = async (payload: IPasswordChangeProfile) => {
    return await http.post<void>(
      resourceURL + `/profile/atualiza-senha`,
      payload,
    );
  };

  return {
    getPrincipal,
    recuperaSenha,
    alteraSenha,
    alteraSenhaProfile,
    ...baseCreate<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
    ...baseFind<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
    ...baseFindById<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(
      resourceURL,
    ),
    patchFull: (
      toUpdate: any,
    ): Promise<IAxiosResponseCustom<IUsuarioCleanDTO>> =>
      http.patch(`${resourceURL}/full`, toUpdate),
    patch: (toUpdate: any): Promise<IAxiosResponseCustom<IUsuarioCleanDTO>> =>
      http.patch(`${resourceURL}`, toUpdate),
  };
};
