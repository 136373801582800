import { useCallback, useState } from 'react';
import { PageableResponse } from '../../../models/response.model';
import { ICursoFullDTO } from '../../../models/happy-code-api.model';
import { useCursoService } from '../../../services/curso.service';
import useAddPredicateOnQueryParam from './use-add-predicate-on-query-param.hook';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useHandlePageChangeHook } from '../../../hooks/use-handle-page-change.hook';

export default function EuseFindCursos() {
  const cursoService = useCursoService();
  const { addPredicateOnQueryParam } = useAddPredicateOnQueryParam();
  const onPageChange = useHandlePageChangeHook();
  const { usuario } = useGlobalContext();
  const { acessoB2b, acessoB2c } = usuario || {};

  const [cursosData, setCursosData] =
    useState<PageableResponse<ICursoFullDTO>>();

  const findCurso = useCallback(
    async (page?: number): Promise<void> => {
      if (!acessoB2b || !acessoB2c) return;

      const predicate = addPredicateOnQueryParam(page);

      await cursoService
        .findFull(predicate)
        .then(({ data }) => setCursosData(data));
    },
    [acessoB2b, acessoB2c, addPredicateOnQueryParam],
  );

  return {
    cursos: {
      data: cursosData,
      getData: findCurso,
      onPageChange: onPageChange(findCurso),
    },
  };
}
