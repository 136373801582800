import SidebarAndContainer from "../../../components/sidebar-and-container/sidebar-and-container.component";
import { useGlobalContext } from "../../../context/GlobalContext"

import { Pagination } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import Title from "../../../components/title/title.component";
import { IPastaDTO } from "../../../models/happy-code-api.model";
import { PageableResponse } from "../../../models/response.model";
import { usePastaService } from "../../../services/pasta.service";
import { Predicate } from "../../../models/predicate.model";
import { PastaContainer } from "./components/PastaContainer";
import FiltrosPasta from "./components/PastaContainer/pastaFiltros";

export default function MaterialDeApoio() {
  const {usuario} = useGlobalContext();
  const [data, setData] = useState<PageableResponse<IPastaDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
  const pastaService = usePastaService();
  const {setIsGlobalLoading} = useGlobalContext(); 

  const findPastas =  () => {
   setIsGlobalLoading(true)
   predicate.addOption('ativo', true);
   const acessUser = verifyUser();
   
   if(acessUser) predicate.addOption(acessUser, false);
   pastaService.find(predicate).then((response) => {
     setData(response.data);
     
   }).finally(() => setIsGlobalLoading(false))
  }

const verifyUser = () => {
  if(usuario.acessoB2b && !usuario.acessoB2c){
    return "acessoB2c"
  }

  if(!usuario.acessoB2b && usuario.acessoB2c){
    return "acessoB2b"
  }

  if(usuario.acessoB2b && usuario.acessoB2c){
    return undefined;
  }
}

const onPageChange = (_: any, page: number) => {
  predicate.setPage(page - 1);
  findPastas();
}

  useEffect(() => {
    if(usuario) findPastas();
  }, [usuario]);
 
  return (
    <SidebarAndContainer materiaisApoio={true}>
      <Fragment>
      <div className="webinars-all-container__title-back-button">
        <Link to="/instrutor" className="button-second">
          <Unicons.UilArrowLeft />
          Voltar
        </Link>

        <Title title="Material de apoio" />
      </div>

      <div className="webinars-all-container__search-bar">
       <FiltrosPasta
       findPasta={findPastas}
       predicate={predicate}
       />
      </div>

      <div className="webinars-all-container__webinars-cards">
        {data && data.content.map((pasta) => (
          <PastaContainer Pasta={pasta} />
        ))}
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
        onChange={(event, page) => onPageChange(event, page)}
        current={data ? data.pageable.pageNumber + 1 : 0}
        total={data ? data.totalPages : 0}
        />
      </div>
    </Fragment>
    </SidebarAndContainer>
  )
}