import './upload-container.style.scss';
import { Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { token } from '../../../../util/store';
import useUploadContainer from './use-upload-container.hook';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../../enum/lancamento-aula/habilidade-competencia-escala.enum';
import { listAccordionFormStore } from '../../store/list-accordion-form.store';

interface UploadContainerProps {
  idMatricula: number;
}

export default function UploadContainer({ idMatricula }: UploadContainerProps) {
  const {
    upload,
    projectScaleOptions,
    form
  } = useUploadContainer({ idMatricula });
  const { updateAccordionForm } = listAccordionFormStore();

  return (
    <div className={'upload-container'}>
      <div
        className={'upload-container__select-input'}
      >
        <label className={'select-input__label'}>Avaliação</label>
        <Select
          onChange={(value: number) => updateAccordionForm(idMatricula, 'projetoValue', value)}
          disabled={form.disabled}
          className={'select-input__input'}
          options={projectScaleOptions}
          value={form.selectValue}
          defaultValue={HabilidadeCompetenciaEscalaEnum.NEUTRO}
        />
      </div>
      <div className={'upload-container__project-upload'}>
        <label className={'project-upload__label'}>Upload de projeto</label>
        <Upload
          headers={{ Authorization: `Bearer ${token()}` }}
          action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/projetos`}
          onChange={upload.onChange}
          maxCount={1}
          beforeUpload={upload.before}
          className={'project-upload__upload'}
          defaultFileList={form.projectLinkValue ? [form.projectLinkValue] : []}
          disabled={form.disabled}
        >
          <UploadOutlined className={'upload__upload-icon'} />
        </Upload>
      </div>
    </div>
  );
}
