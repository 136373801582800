import http from '../helpers/http-common';
import {
  IAvaliacaoTableDTO,
  IMatriculaProvaCleanDTO,
  IMatriculaProvaDTO,
  IMatriculaProvaFullDTO,
} from "../models/happy-code-api.model";
import { IAxiosResponseCustom } from "../models/http.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { AxiosResponse } from "axios";
import { PageableResponse } from "../models/response.model";
import { formatHttpParams, Predicate } from "../models/predicate.model";

export const useMatriculaProvaService = () => {
  const resourceURL = 'matricula-prova';

  const enviarNota = (
    body: IMatriculaProvaCleanDTO,
  ): Promise<IAxiosResponseCustom> => http.post(`${resourceURL}`, body);

  const patchNotas = (body): Promise<IAxiosResponseCustom> =>
    http.patch(`${resourceURL}`, body);

  const getAvaliacaoTable = (
    id: number,
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IAvaliacaoTableDTO>>> =>
    http.get<PageableResponse<IAvaliacaoTableDTO>>(
      `${resourceURL}/avaliacao-table/${id}`,
      formatHttpParams(predicate),
    );

  return {
    ...baseFind<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseFindById<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseFindList<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseCreate<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    patchNotas,
    enviarNota,
    getAvaliacaoTable,
  };
};
