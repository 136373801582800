import * as Unicons from '@iconscout/react-unicons';
import { Button, Radio, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Emoji1 from '../../assets/img/classificacao/emoji1.svg';
import Emoji2 from '../../assets/img/classificacao/emoji2.svg';
import Emoji3 from '../../assets/img/classificacao/emoji3.svg';
import Emoji4 from '../../assets/img/classificacao/emoji4.svg';
import Instrutores from '../../assets/img/classificacao/instrutores.svg';
import { ModalClassificacaoWebinar } from '../../components/Modal/classificacaoWebinar';
import { Sidebar } from '../../components/Sidebar';
import { useGlobalContext } from '../../context/GlobalContext';
import {
  IFeedbackWebinarDTO,
  IWebinarDTO,
} from '../../models/happy-code-api.model';
import { useAcaWebinarService } from '../../services/aca-webinar';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const WebinarClassificacao = () => {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const navigate = useNavigate();
  const webinarService = useAcaWebinarService();
  const [feedback, setFeedback] = useState<IFeedbackWebinarDTO>();
  const [webinar, setWebinar] = useState<IWebinarDTO>();
  const [isOpen, setIsOpen] = useState(false);
  const { idWebinar } = useParams();

  const findWebinar = () => {
    setIsGlobalLoading(true);

    const parseIdToNumber = parseInt(idWebinar);

    webinarService
      .findById({ id: parseIdToNumber })
      .then((response) => {
        setWebinar(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onChangeModal = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onVoltarTela = () => navigate(-1);

  useEffect(() => {
    findWebinar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarAndContainer>
      <div className="dashboard">
        <div className="d-flex-row j-start-a-center mb-20">
          <Button className="button-blue" onClick={onVoltarTela}>
            <Unicons.UilArrowLeft />
            Voltar
          </Button>
          <h1 className="fav-title">{webinar?.nome}</h1>
        </div>
        <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
          <div
            id="box-aula-white"
            style={{ width: '69%' }}
            className="box-white"
          >
            <div style={{ width: '100%', height: 'auto' }}>
              <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
                <div
                  id="box-aula-white"
                  style={{ width: '100%' }}
                  className="box-white"
                >
                  <h2 className="title-classificacao mb-10">Muito bem!</h2>
                  <div className="d-flex-row j-center-a-center mb-20">
                    <img src={Instrutores} alt="instrutores" />
                  </div>
                  <p className="descricao-classificacao mb-20">
                    Parabéns pela conclusão do Webinar, nos conte como foi
                    experiência:
                  </p>
                  <div className="d-flex-row j-start-a-start w-100 mb-10">
                    <Radio.Group
                      onChange={(e) => {
                        const value = e.target.value;
                        setFeedback((prevFeedback: IFeedbackWebinarDTO) => ({
                          ...prevFeedback,
                          numeroNps: value,
                          webinar: {
                            ...prevFeedback?.webinar,
                            id: webinar?.id,
                          },
                          usuario: {
                            ...prevFeedback?.usuario,
                            id: usuario?.id,
                          },
                        }));
                      }}
                      value={feedback?.numeroNps} // Make sure to set the value of the Radio.Group
                    >
                      <Space direction="vertical">
                        <div
                          className={`${
                            feedback?.numeroNps === 1
                              ? 'box-radio-select'
                              : null
                          } box-radio mb-10`}
                        >
                          <Radio value={1}>
                            <img src={Emoji1} alt="emoji" className="emoji" />{' '}
                            Muito satisfeito
                          </Radio>
                        </div>
                        <div
                          className={`${
                            feedback?.numeroNps === 2
                              ? 'box-radio-select'
                              : null
                          } box-radio mb-10`}
                        >
                          <Radio value={2}>
                            <img src={Emoji2} alt="emoji" className="emoji" />{' '}
                            Satisfeito
                          </Radio>
                        </div>
                        <div
                          className={`${
                            feedback?.numeroNps === 3
                              ? 'box-radio-select'
                              : null
                          } box-radio mb-10`}
                        >
                          <Radio value={3}>
                            <img src={Emoji3} alt="emoji" className="emoji" />{' '}
                            Pouco satisfeito
                          </Radio>
                        </div>
                        <div
                          className={`${
                            feedback?.numeroNps === 4
                              ? 'box-radio-select'
                              : null
                          } box-radio mb-10`}
                        >
                          <Radio value={4}>
                            <img src={Emoji4} alt="emoji" className="emoji" />{' '}
                            Totalmente insatisfeito
                          </Radio>
                        </div>
                      </Space>
                    </Radio.Group>
                  </div>
                  {!feedback?.id ? (
                    <div className="d-flex-row j-end-a-center">
                      <Button
                        onClick={onChangeModal}
                        className="button-primary"
                      >
                        Classificar
                      </Button>
                    </div>
                  ) : null}
                </div>
                <ModalClassificacaoWebinar
                  open={isOpen}
                  onClose={onClose}
                  feedback={feedback}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarAndContainer>
  );
};
