import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/img/header/logo.svg';
import layout from '../../assets/img/login/img-people.png';
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { useUsuarioService } from '../../services/usuario.service';
import '../Login/style.css';
import { useNotificationService } from './../../services/notification.service';
import './style.css';

interface TInputs {
  password: string;
  repassword: string;
}

export const RecuperarSenha: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TInputs>();
  const notification = useNotificationService();
  const userService = useUsuarioService();
  const navigate = useNavigate();
  const password = useRef({});
  const { token } = useParams();

  const { setIsGlobalLoading } = useGlobalContext();
  password.current = watch('password', '');

  const onRecuperaSenha = (data: TInputs) => {
    setIsGlobalLoading(true);
    const payload = {
      token,
      senha: data.password,
    };
    userService
      .alteraSenha(payload)
      .then(() => {
        notification({
          message: 'Sucesso!',
          description:
            'Sua senha foi alterada com sucesso, entre com sua nova senha!',
        });
        navigate('');
      })
      .catch((error) =>
        notification({
          message: 'Erro!',
          description: error.response.data.message,
          type: 'error',
        }),
      )
      .finally(() => setIsGlobalLoading(false));
  };

  return (
    <form
      onSubmit={handleSubmit(onRecuperaSenha)}
      className="container-login"
      style={{ backgroundColor: 'var(--white-color' }}
    >
      <img src={logo} alt={'logo'} className="logo-login" />
      <img src={layout} alt={'logo'} className="img-layout" />
      <div className="formulario-box">
        <div className="formulario">
          <div className="arrow">
            <Link to="/">
              <Button className="button-second" icon={<ArrowLeftOutlined />}>
                Voltar
              </Button>
            </Link>
          </div>
          <h3>Recuperação de senha</h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant diam nibh id sollicitudin. Malesuada amet.</p> */}
          <ul
            className="password-requisitos"
            style={{ color: 'var(--white-color' }}
          >
            <li>Mínimo 5 caracteres</li>
            <li>Pelo menos 1 letra maiúscula</li>
            <li>Pelo menos 1 número</li>
          </ul>
          <div className="input">
            <Unicons.UilLock size="20" color="#6A7C96" className="iconForm" />
            <input
              type="password"
              placeholder="Digite a senha"
              {...register('password', {
                required: true,
                pattern: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z!@#$%&*]{5,}$/,
              })}
            />
            {errors.password && (
              <small className="input-error">
                A senha digitada não possui os requisitos acima
              </small>
            )}
          </div>

          <div className="input">
            <Unicons.UilLock size="20" color="#6A7C96" className="iconForm" />
            <input
              type="password"
              placeholder="Digite novamente a senha"
              {...register('repassword', {
                validate: (value) =>
                  value === password.current || 'As senhas não combinam',
              })}
            />
            {errors.repassword && (
              <small className="input-error">{errors.repassword.message}</small>
            )}
          </div>
          <button
            type="submit"
            className="button-second"
            style={{ width: '100%' }}
          >
            <span>Enviar</span>
          </button>
        </div>
      </div>
    </form>
  );
};
