import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Input, List } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import avatar from '../../assets/img/header/avatar.png';
import { Sidebar } from '../../components/Sidebar';
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import {
  IAlunoDTO,
  IAlunoObservacaoDTO,
  IFrequenciaFullDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useFrequenciaService } from '../../services/frequencia.service';
import { PageableResponse } from './../../models/response.model';
import { useAlunoObservacaoService } from './../../services/aluno-observacao.service';
import { useAlunoService } from './../../services/aluno.service';
import './style.css';

export const TurmasObservacoes: React.FunctionComponent = () => {
  const { alunoId, idFrequencia } = useParams();
  const [observacaoList, setObservacaoList] =
    useState<PageableResponse<IAlunoObservacaoDTO>>();
  const [observacao, setObservacao] = useState<string>();
  const [selectedObservacao, setSelectedObservacao] =
    useState<IAlunoObservacaoDTO>();
  const [aluno, setAluno] = useState<IAlunoDTO>();
  const [frequencia, setFrequencia] = useState<IFrequenciaFullDTO>();
  const alunoService = useAlunoService();
  const [predicate] = useState<Predicate>(new Predicate());
  const alunoObservacaoService = useAlunoObservacaoService();
  const frequenciaService = useFrequenciaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const navigate = useNavigate();
  const { TextArea } = Input;

  const getAluno = () => {
    const changeIdToNumber = parseInt(alunoId);

    return alunoService.findById({ id: changeIdToNumber }).then((response) => {
      setAluno(response.data);
    });
  };

  const findAlunoObservacao = () => {
    predicate.addOption('alunoId', alunoId);
    predicate.addOption('frequenciaId', idFrequencia);
    return alunoObservacaoService.find(predicate).then((response) => {
      setObservacaoList(response.data);
    });
  };

  const saveObservacao = () => {
    const payload = {
      descricao: observacao,
      ativo: true,
      frequencia: { id: frequencia.id },
      id: null,
    };
    if (observacao && observacao.length >= 1) {
      setIsGlobalLoading(true);
      return alunoObservacaoService
        .create(payload)
        .then(() => {
          findAlunoObservacao();
          setSelectedObservacao(null);
          setObservacao(null);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  };

  const deleteObservacao = (id) => {
    setIsGlobalLoading(true);
    return alunoObservacaoService
      .delete(id)
      .then(() => {
        findAlunoObservacao();
        setSelectedObservacao(null);
        setObservacao(null);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const updateObservacao = () => {
    setIsGlobalLoading(false);
    selectedObservacao.descricao = observacao;
    return alunoObservacaoService
      .patch(selectedObservacao)
      .then(() => {
        setSelectedObservacao(null);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const getFrequencia = () => {
    const parseIdToNumber = parseInt(idFrequencia);

    return frequenciaService
      .findFullById({ id: parseIdToNumber })
      .then((response) => {
        setFrequencia(response.data);
      });
  };

  const handleSubmit = () => {
    if (selectedObservacao) {
      updateObservacao();
    } else {
      saveObservacao();
    }
  };

  useEffect(() => {
    if (alunoId || idFrequencia) {
      setIsGlobalLoading(true);
      Promise.all([findAlunoObservacao(), getFrequencia(), getAluno()]).finally(
        () => setIsGlobalLoading(false),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alunoId, idFrequencia]);

  useEffect(() => {
    if (selectedObservacao) {
      setObservacao(selectedObservacao.descricao);
    } else {
      setObservacao(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alunoId, idFrequencia]);

  useEffect(() => {
    if (selectedObservacao) {
      setObservacao(selectedObservacao.descricao);
    } else {
      setObservacao(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObservacao]);

  return (
    <div className="container">
      <Sidebar gestaoSalaAula />
      <div className="franquia" id="aluno-detalhe-row">
        {/* <div className="space1"></div> */}
        <div
          className="franquia-row"
          id="row-aluno-box"
          style={{ marginTop: 70 }}
        >
          <div className="head-botoes" id="detalhe-turma">
            <Button
              onClick={() => navigate(-1)}
              className="button-second"
              icon={<ArrowLeftOutlined />}
            >
              Voltar
            </Button>
            <h5 style={{ marginLeft: 15 }}>Observações de aluno</h5>
          </div>
          <div className="box-data-turma">
            <h5>Aluno</h5>
            <div className="input-box">
              <div id="avatar-skills">
                <img src={avatar} alt={'avatar'} className="avatar" />
                <h6 style={{ textAlign: 'start' }}>{aluno?.pessoa?.nome}</h6>
              </div>
            </div>
            <h5>Observação</h5>
            <div className="input-box" id="text-area-observacoes">
              <TextArea
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                rows={6}
                maxLength={230}
                placeholder="Adicione aqui a observação"
              />
            </div>
            <h6>
              {observacao && observacao.length > 0 ? observacao.length : '0'}
              /230
            </h6>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => navigate(-1)}
                className="button-second"
                style={{ marginRight: 20 }}
              >
                Voltar
              </Button>
              <Button className="button-primary" onClick={() => handleSubmit()}>
                {' '}
                {selectedObservacao
                  ? 'Atualizar observação'
                  : 'Registrar observação'}
              </Button>
            </div>
          </div>
          <List
            style={{ width: '100%', background: 'white' }}
            header={<div>Observações</div>}
            bordered
            dataSource={observacaoList?.content}
            renderItem={(item) => (
              <List.Item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div style={{ alignSelf: 'center' }}>{item.descricao}</div>
                  <div style={{ display: 'flex' }}>
                    <Button
                      onClick={() => deleteObservacao(item)}
                      danger
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                    <Button
                      onClick={() => setSelectedObservacao(item)}
                      type="primary"
                      shape="circle"
                      style={{ marginLeft: 10 }}
                      icon={<EditOutlined />}
                    />
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};
