import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IRespostaCleanDTO,
  IRespostaDTO,
  IRespostaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcaRespostaService = () => {
  const resourceURL = 'aca-resposta';

  const saveFullAll = (
    body: any,
  ): Promise<IAxiosResponseCustom<IRespostaFullDTO>> =>
    http.post(`${resourceURL}/full/all`, body);

    const deleteAll = (idUsuario: number, idTrilha: number): Promise<AxiosResponse<void>> => {
      return http.delete(`${resourceURL}/delete-respostas/${idUsuario}/${idTrilha}`);
    };
    

  return {
    ...baseFindList<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(
      resourceURL,
    ),
    ...baseFind<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(resourceURL),
    ...baseFindList<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<IRespostaCleanDTO, IRespostaDTO, IRespostaFullDTO>(
      resourceURL,
    ),
    saveFullAll,
    deleteAll,
  };
};
