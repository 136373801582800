import { useParams } from "react-router-dom";
import { ChangeEvent, useCallback, useState } from "react";
import { PageableResponse } from "../../../models/response.model";
import { ILancamentoAulasTableDTO } from "../../../models/happy-code-api.model";
import { useAulaService } from "../../../services/aula.service";
import { Predicate } from "../../../models/predicate.model";
import { useGlobalContext } from "../../../context/GlobalContext";

export default function useFindAulasByTurmaId() {
  const { id } = useParams();
  const service = useAulaService();
  const { setIsGlobalLoading } = useGlobalContext();

  const [aulasTableData, setAulasTableData] =
    useState<PageableResponse<ILancamentoAulasTableDTO>>();
  const [aulaPage, setAulaPage] = useState<number>(0);

  const buildPredicate = useCallback((page?: number) => {
    const predicate = new Predicate();

    if (page) predicate.setPage(page);

    return predicate;
  }, []);

  const findAulasByTurmaId = useCallback(
    async (page?: number) => {
      if (!id) return;

      const parseIdToInt = parseInt(id);

      const predicate = buildPredicate(page);

      await service
        .findAulasFromTurmaId(parseIdToInt, predicate)
        .then(({ data }) => setAulasTableData(data));
    },
    [buildPredicate, id],
  );

  const onPageAulasTableChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    event.preventDefault();

    setIsGlobalLoading(true);

    setAulaPage(page);
    findAulasByTurmaId(page).finally(() => setIsGlobalLoading(false));
  };

  return {
    aulas: {
      data: aulasTableData,
      function: findAulasByTurmaId,
      onPageChange: onPageAulasTableChange,
      page: aulaPage,
    },
  };
}
