import '../../instrutor.styles.scss';
import Title from '../../../../components/title/title.component';
import { Button } from 'antd';
import './class-management.styles.scss';
import { useNavigate } from 'react-router-dom';
import useClassManagement from './use-class-management.hook';
import { ITurmaFullDTO } from '../../../../models/happy-code-api.model';
import ClassCard from '../class-card/class-card.component';

export default function ClassManagement() {
  const { userIsInstructor, hasOutgoingClass, outgoingClass, isB2B } =
    useClassManagement();

  const navigate = useNavigate();

  return (
    userIsInstructor && !isB2B && (
      <div className="instrutor__cards-wrapper">
        <div className="class-management__title-see-button">
          <Title title="Gestão de turmas" />
          <Button onClick={() => navigate('/turmas')} className="button-line">
            Ver gestão de turmas
          </Button>
        </div>

        <div className="class-management__class-cards-wrapper">
          {hasOutgoingClass &&
            outgoingClass.map((turma: ITurmaFullDTO) => (
              <ClassCard turma={turma} key={turma.id} />
            ))}
        </div>
      </div>
    )
  );
}
