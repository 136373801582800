interface Props {
  name?: string;
  cor1?: string;
  cor2?: string;
  estilo?: string;
  ativo?: boolean;
  onClick?: any;
}

export const Tag = (props: Props) => {
  const { name, cor2, ativo, onClick } = props;

  return (
    <>
      <div
        onClick={onClick}
        className={`tag trilha-label`}
        style={{
          cursor: 'pointer',
          backgroundColor: ativo ? cor2 : cor2 + '66',
        }}
      >
        <span style={{ color: '#fff' }}>{name}</span>
      </div>
    </>
  );
};
