import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IPessoaCleanDTO,
  IPessoaCustomCleanDTO,
  IPessoaCustomDTO,
  IPessoaDTO,
  IPessoaFullDTO,
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const usePessoaService = () => {
  const resourceURL = 'pessoa';

  const findPessoaSemUsuario = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IPessoaDTO>>> =>
    http.get<PageableResponse<IPessoaDTO>>(
      resourceURL + '/sem-usuario',
      formatHttpParams(predicate),
    );

  const findByIdCustom = (
    id: number,
  ): Promise<AxiosResponse<IPessoaCustomCleanDTO>> =>
    http.get<IPessoaCustomCleanDTO>(resourceURL + `/custom/${id}`);

  const findCustomClean = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IPessoaCustomCleanDTO>>> =>
    http.get<PageableResponse<IPessoaCustomCleanDTO>>(
      resourceURL + `/custom/clean`,
      formatHttpParams(predicate),
    );

  const findCustom = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IPessoaCustomDTO>>> =>
    http.get<PageableResponse<IPessoaCustomDTO>>(
      resourceURL + `/custom/`,
      formatHttpParams(predicate),
    );

  const patchClean = (
    pessoaData: IPessoaCustomCleanDTO,
  ): Promise<AxiosResponse<IPessoaCustomCleanDTO>> =>
    http.patch<IPessoaCustomCleanDTO>(resourceURL, pessoaData);

  return {
    findPessoaSemUsuario,
    ...baseFind<IPessoaCleanDTO, IPessoaDTO, IPessoaFullDTO>(resourceURL),
    ...baseCreate<IPessoaCleanDTO, IPessoaDTO, IPessoaFullDTO>(resourceURL),
    ...baseUpdate<IPessoaCleanDTO, IPessoaDTO, IPessoaFullDTO>(resourceURL),
    ...baseFindById<IPessoaCleanDTO, IPessoaDTO, IPessoaFullDTO>(resourceURL),
    findByIdCustom,
    patchClean,
    findCustomClean,
    findCustom,
  };
};
