import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IUsuarioCursoCleanDTO,
  IUsuarioTopicoAulaCleanDTO,
  IUsuarioTopicoAulaDTO,
  IUsuarioTopicoAulaFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useUsuarioTopicoAulaService = () => {
  const resourceURL = 'usuario-topico-aula';

  const confirmarVisualizacaoTopico = (
    idTopico: number,
  ): Promise<AxiosResponse<IUsuarioCursoCleanDTO>> =>
    http.post(`${resourceURL}/visualizacao/topico/${idTopico}`);

  return {
    ...baseFind<
      IUsuarioTopicoAulaCleanDTO,
      IUsuarioTopicoAulaDTO,
      IUsuarioTopicoAulaFullDTO
    >(resourceURL),
    ...baseFindById<
      IUsuarioTopicoAulaCleanDTO,
      IUsuarioTopicoAulaDTO,
      IUsuarioTopicoAulaFullDTO
    >(resourceURL),
    ...baseFindList<
      IUsuarioTopicoAulaCleanDTO,
      IUsuarioTopicoAulaDTO,
      IUsuarioTopicoAulaFullDTO
    >(resourceURL),
    ...baseCreate<
      IUsuarioTopicoAulaCleanDTO,
      IUsuarioTopicoAulaDTO,
      IUsuarioTopicoAulaFullDTO
    >(resourceURL),
    ...baseUpdate<
      IUsuarioTopicoAulaCleanDTO,
      IUsuarioTopicoAulaDTO,
      IUsuarioTopicoAulaFullDTO
    >(resourceURL),
    ...baseDelete(resourceURL),
    confirmarVisualizacaoTopico,
  };
};
