import { baseFindList } from '../base/base-find-list.service';
import {
  ITipoConteudoCleanDTO,
  ITipoConteudoDTO,
  ITipoConteudoFullDTO
} from '../../models/happy-code-api.model';


export const useLearnTipoConteudoService = () => {
  const resourceUrl = '/learn/v1/tbtipoconteudo';

  return {
    ...baseFindList<
      ITipoConteudoCleanDTO,
      ITipoConteudoDTO,
      ITipoConteudoFullDTO
    >(resourceUrl)
  };
};
