import { create } from "zustand";

type Store = {
  turmaIsEnglish: boolean;
  setTurmaIsEnglish: (data: boolean) => void;
};

export const turmaIsEnglishStore = create<Store>((set) => ({
  turmaIsEnglish: false,
  setTurmaIsEnglish: (data) => set({ turmaIsEnglish: data }),
}));
