import { Button, Radio, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalConfirmacao } from '../../components/Modal/confirmacao';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import { useGlobalContext } from '../../context/GlobalContext';
import {
  IAlternativaDTO,
  IQuestaoDTO,
  IQuestaoFullDTO,
  IRespostaDTO,
  ITrilhaDTO,
  IUsuarioDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAcaQuestaoService } from '../../services/aca-questao';
import { useAcaRespostaService } from '../../services/aca-resposta';
import { useNotificationService } from '../../services/notification.service';
import { useTrilhaService } from '../../services/trilha.service';
import { getPrimitive } from '../../util/store';
import './components/avaliacao-trilha.css';
import { useAvaliacaoNavigationHook } from './hooks/use-avaliacao-hook';
import ModalCreate from '../../components/ModalCreateAvaliacao';

export const  TrilhaAvaliacao = () => {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const notification = useNotificationService();
  const questaoService = useAcaQuestaoService();
  const respostaService = useAcaRespostaService();
  const trilhaService = useTrilhaService();
  const [predicate] = useState<Predicate>(new Predicate());
  const [data, setData] = useState<IQuestaoFullDTO[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alternativas, setAlternativas] = useState<IRespostaDTO[]>([]);
  const [resposta, setResposta] = useState<IRespostaDTO[]>([]);
  const [trilha, setTrilha] = useState<ITrilhaDTO>();
  const { idTrilha } = useParams();
  const [nota, setNota] = useState<number | null>(0);
  const [alunoAprovado, setAlunoAprovado] = useState<undefined | boolean>();
  const [showAprovacaoModal, setShowAprovacaoModal] = useState(false);
  const {
    currentQuestionIndex,
    goToNextQuestion,
    goToPreviousQuestion,
    setCurrentQuestionIndex,
  } = useAvaliacaoNavigationHook(data);

  const findQuestao = () => {
    questaoService.findByIdTrilha(parseInt(idTrilha)).then((response) => {
      setData(response.data);
    });
  };

  const findResposta = () => {
    if (!usuario.id) return;
    predicate.addOption('trilha.id', idTrilha);
    predicate.addOption('usuario.id', usuario?.id);
    respostaService.findList(predicate).then((response) => {
      setResposta(response.data);
    });
  };

  const findTrilha = () => {
    trilhaService.findById({ id: parseInt(idTrilha) }).then((response) => {
      setTrilha(response.data);
    });
  };

  const onChange = (idAlternativa: number, questao: IQuestaoFullDTO) => {
    const respostas = alternativas.filter((a) => a.questao.id !== questao.id);
    respostas.push({
      alternativa: { id: idAlternativa } as IAlternativaDTO,
      questao: { id: questao.id } as IQuestaoDTO,
      trilha: { id: idTrilha } as any,
      usuario: { id: usuario?.id } as IUsuarioDTO,
      id: null,
      ativo: true,
    });
    setAlternativas(respostas);
  };

  const onChangeModal = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const converterNumeroLetra = (indexAlternativa) => {
    if (indexAlternativa >= 0 && indexAlternativa < 26) {
      return String.fromCharCode(65 + indexAlternativa);
    } else {
      throw new Error('Índice fora do intervalo válido (0 a 25).');
    }
  };

  const renderButton = () => {
    if (resposta.length <= 0) {
      const allQuestionsAnswered = data.every((question) =>
        alternativas.some((a) => a.questao.id === question.id),
      );
      return (
        <>
          <Tooltip
            title={!allQuestionsAnswered ? 'Assinale todas as questões' : ''}
          >
            <Button
              disabled={!allQuestionsAnswered}
              onClick={onChangeModal}
              className="button-primary"
            >
              Finalizar questionário
            </Button>
          </Tooltip>
        </>
      );
    }
    return (
      <Button onClick={resetarProva} className="button-primary">
        Tentar novamente
      </Button>
    );
  };

  const avaliacaoRespondida = (questao, alternativa) => {
    const alternativaSelecionada = alternativas.find(
      (a) =>
        a?.questao?.id === questao?.id &&
        a?.alternativa?.id === alternativa?.id,
    );
    const respostaSalva = resposta.find(
      (r) =>
        r?.questao?.id === questao?.id &&
        r?.alternativa?.id === alternativa?.id,
    );

    if (alternativaSelecionada && !resposta.find((r) => r?.id)?.id) {
      return 'box-radio-select';
    }

    if (resposta.find((r) => r?.id)) {
      if (alternativa?.resposta) {
        return 'box-radio-select-correto';
      } else if (
        respostaSalva?.alternativa?.id === alternativa.id &&
        !alternativa?.resposta
      ) {
        return 'box-radio-select-errado';
      }
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const totalQuestoes = data.length;
      let respostasCorretas = 0;

      data.forEach((questao) => {
        const alternativaCorreta = questao.alternativas.find((a) => a.resposta);
        const respostaSelecionada = alternativas.find(
          (r) => r.questao.id === questao.id,
        )?.alternativa;

        if (
          alternativaCorreta &&
          respostaSelecionada &&
          alternativaCorreta.id === respostaSelecionada.id
        ) {
          respostasCorretas++;
        }
      });

      const novaNota = (respostasCorretas / totalQuestoes) * 100;

      setNota(novaNota);
    }
  }, [alternativas, data]);

  useEffect(() => {
    const loadData = async () => {
      setIsGlobalLoading(true);
      try {
        await Promise.all([findQuestao(), findResposta(), findTrilha()]);
        verifyUserAvaliacao();
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      } finally {
        setIsGlobalLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  const verifyUserAvaliacao = () => {
    const aprovado = getPrimitive('aprovado');

    if (aprovado === 'true') {
      setAlunoAprovado(true);
      setShowAprovacaoModal(true);
    }
    return;
  };

  const resetarProva = () => {
    setIsGlobalLoading(true);
    respostaService
      .deleteAll(usuario?.id, parseInt(idTrilha))
      .then(() => {
        setResposta([]);
        setAlternativas([]);
        setCurrentQuestionIndex(0);
        notification({
          description: `Prova reiniciada com sucesso.`,
          type: 'success',
          message: 'Sucesso!',
        });
      })
      .then(() => findQuestao())
      .finally(() => setIsGlobalLoading(false));
  };

  const currentQuestao = data[currentQuestionIndex];

  const verificarSeAlunoPassou = async () => {
    const notaMinima = trilha?.percentualAcertos;
    if (nota >= notaMinima) {
      setAlunoAprovado(true);
    } else {
      setAlunoAprovado(false);
    }
    setShowAprovacaoModal(true);
  };

  return (
    <SidebarAndContainer>
      <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
        <div
          id="box-aula-white"
          className="box-white"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className="title-avaliacao-trilha mb-10">
              Avaliação da trilha: {trilha?.descricao}
            </h1>
            <h2 className="title-avaliacao mb-10">
              <br />
              Porcentagem de acerto mínima: {trilha?.percentualAcertos}%
            </h2>
          </div>
          {data && currentQuestao && (
            <div key={currentQuestao.id}>
              <div className="flex-container" id="box-questao">
                <p>
                  <strong>{currentQuestionIndex + 1}.</strong>
                </p>
                {currentQuestao?.descricao?.includes('<') ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: currentQuestao?.descricao,
                    }}
                  ></p>
                ) : (
                  <p>
                    {currentQuestionIndex + 1}. {currentQuestao?.descricao}
                  </p>
                )}
              </div>
              <div className="d-flex-row j-start-a-start w-100 mb-10">
                <Radio.Group
                  onChange={(e) => onChange(e.target.value, currentQuestao)}
                  value={
                    resposta.find((a) => a?.questao?.id === currentQuestao?.id)
                      ?.alternativa?.id ||
                    alternativas.find((a) => a.questao.id === currentQuestao.id)
                      ?.alternativa?.id
                  }
                  disabled={resposta.find((r) => r?.id)?.id ? true : false}
                >
                  <Space direction="vertical">
                    {currentQuestao &&
                      currentQuestao.alternativas.map((alternativa, index) => (
                        <div
                          key={index}
                          className={`${avaliacaoRespondida(
                            currentQuestao,
                            alternativa,
                          )} box-radio mb-10`}
                        >
                          <Radio
                            value={alternativa?.id}
                            className="alternativa-descricao"
                          >
                            <div
                              className="alternativa-descricao"
                              style={{ paddingLeft: '10px' }}
                            >
                              Alternativa {converterNumeroLetra(index)}
                              {alternativa?.descricao?.includes('<') ? (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: alternativa.descricao,
                                    }}
                                  ></p>
                                </>
                              ) : (
                                <>
                                  <p>{alternativa.descricao}</p>
                                </>
                              )}
                            </div>
                          </Radio>
                        </div>
                      ))}
                  </Space>
                </Radio.Group>
              </div>
              {resposta.find((a) => a?.questao?.id === currentQuestao?.id)
                ?.alternativa?.id && trilha?.mostrarRespostas ? (
                <div>
                  <h2 className="title-avaliacao mb-10">
                    Resposta correta: Alternativa{' '}
                    {converterNumeroLetra(
                      currentQuestao?.alternativas?.findIndex(
                        (a) => a?.resposta,
                      ),
                    )}
                  </h2>
                  <p className="descricao-avaliacao mb-20">
                    {
                      resposta.find(
                        (r) => r?.questao?.id === currentQuestao?.id,
                      )?.alternativa?.justificativa
                    }
                  </p>
                </div>
              ) : null}
              {data && data.length > 1 ? <div className="line" /> : null}
            </div>
          )}

          <div
            className="d-flex-row j-center-a-center"
            style={{ marginTop: '20px' }}
          >
            <div className="buttons-center">
              <Button
                onClick={goToPreviousQuestion}
                disabled={currentQuestionIndex === 0}
                style={{ marginRight: '10px' }}
              >
                Anterior
              </Button>
              {currentQuestionIndex + 1} de {data.length}
              <Button
                onClick={goToNextQuestion}
                disabled={currentQuestionIndex === data.length - 1}
                style={{ marginLeft: '10px' }}
              >
                Próxima
              </Button>
            </div>
          </div>
          <div className="d-flex-row j-center-a-center mt-20">
            {renderButton()}
          </div>
        </div>
      </div>
      <ModalCreate
        aproved={alunoAprovado}
        open={showAprovacaoModal}
        title={alunoAprovado ? 'Parabéns!' : 'Que pena!'}
        subtitle={
          alunoAprovado
            ? `Você atingiu a nota mínima e foi aprovado, volte para a página da trilha.
            ${` ${nota !== 0 ?" Porcentagem de acerto:" + nota.toFixed(0)+ "%" : ''}`}
            `
            : `Você não atingiu a nota mínima. Tente novamente. ${ 'Porcentagem de acerto: ' + nota.toFixed(0) + '%'} `
        }
        textButton2={
          alunoAprovado ? 'Voltar para a trilha' : 'Refazer Avaliação'
        }
        styleButton2="button-primary"
        link2= {`/cursos/trilha/${idTrilha}`}
        setShowAprovacaoModal={setShowAprovacaoModal}
      />
      <ModalConfirmacao
        open={isOpen}
        onClose={onClose}
        findResposta={findResposta}
        alternativas={alternativas}
        idTrilha={idTrilha}
        usuario={usuario}
        nota={nota}
        verificarSeAlunoPassou={verificarSeAlunoPassou}
      />
    </SidebarAndContainer>
  );
};
