import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import {
  IAcaAulaDTO,
  IFeedbackDTO,
  ITopicoAulaDTO,
} from '../../models/happy-code-api.model';
import useModalClassificacao from './use-modal-classificacao';

interface ModalClassificacaoProps {
  open: boolean;
  onClose: () => void;
  feedback: IFeedbackDTO;
  topico: ITopicoAulaDTO;
  proximaAula: IAcaAulaDTO;
}

export const ModalClassificacao = ({
  open,
  onClose,
  feedback,
  proximaAula,
}: ModalClassificacaoProps) => {
  const { saveFeedback } = useModalClassificacao({
    feedback,
    proximaAula,
    onClose,
  });

  const buttonPrimaryText = !!proximaAula ? 'Concluir Aula' : 'Concluir';

  if (!open) return null;

  return (
    <div className="d-flex-row j-center-a-center modal-container">
      <div className="d-flex-column j-start-a-center modal">
        <div className="d-flex-row j-center-a-center circle mb-10">
          <Unicons.UilCheck color="var(--check)" size={80} />
        </div>
        <h1 className="mb-10">
          Obrigado.
          <br />
          Recebemos sua avaliação
        </h1>
        <div className="line"></div>
        <div className="d-flex-row button-box w-100">
          <Button onClick={onClose} className="button-second mr-15">
            Retornar ao curso
          </Button>
          <Button onClick={saveFeedback} className="button-primary">
            {buttonPrimaryText}
            <Unicons.UilArrowRight size={24} color="var(--white-color)" />
          </Button>
        </div>
      </div>
    </div>
  );
};
