import './conteudo-aula-box.styles.scss';
import ConteudoAulaLabelParagrafo
  from '../conteudo-aula-label-paragrafo/conteudo-aula-label-paragrafo.component';
import {
  getTipoConteudoByValue
} from '../../../../../functions/get-tipo-conteudo-by-value.function';
import {
  conteudoMinistradoDataStore
} from '../../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';

export default function ConteudoAulaBox() {
  const { conteudoMinistradoData } = conteudoMinistradoDataStore();

  return (
    <div className={'conteudo-aula-box'}>
      <h5 className="aula-informacao-box__titulo">Conteúdo Ministrado</h5>

      <div className={'conteudo-aula-box__info-wrapper'}>
        <ConteudoAulaLabelParagrafo
          label={'Tipo'}
          paragrafo={getTipoConteudoByValue(conteudoMinistradoData?.tipo)}
        />

        <ConteudoAulaLabelParagrafo
          label={'Conteúdo'}
          paragrafo={conteudoMinistradoData?.conteudoDescricao}
        />

        <ConteudoAulaLabelParagrafo
          label={'Descrição'}
          paragrafo={conteudoMinistradoData?.descricao}
        />
      </div>
    </div>
  );
}
