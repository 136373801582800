import React, { ChangeEvent, Fragment, ReactNode } from "react";
import { Button } from "antd";
import { Pagination } from "@material-ui/lab";
import { PageableResponse } from "../../models/response.model";
import "./generic-pageable-table.styles.scss";
import { ColumnInterface } from "../../interfaces/table/column.interface";

interface GenericPageableTableProps<T> {
  data: PageableResponse<T>;
  columns: ColumnInterface<T>[];
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
  rowActions?: {
    conditionToRender?: (item: T) => boolean;
    label: string | ((item: T) => ReactNode);
    onClick: (item: T) => void;
  }[];
}

export default function GenericPageableTable<T>({
  data,
  columns,
  onPageChange,
  rowActions,
}: GenericPageableTableProps<T>) {
  return (
    <>
      <table className={"generic-table-wrapper"}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                className={"generic-table-wrapper__header-cell"}
                key={column.key}
              >
                {column.title}
              </th>
            ))}
            {rowActions && <th></th>}
          </tr>
          <tr className="generic-table-wrapper__divider-row">
            <td colSpan={columns.length + (rowActions ? 1 : 0)}></td>
          </tr>
        </thead>

        <tbody>
          {data?.content?.length === 0 ||
            (!data && (
              <tr>
                <td colSpan={columns.length + (rowActions ? 1 : 0)}>
                  Nenhum registro encontrado
                </td>
              </tr>
            ))}

          {data?.content?.map((item, index) => (
            <Fragment key={index}>
              <tr>
                {columns.map(({ key, render }) => (
                  <td key={key} className={"generic-table-wrapper__body-cell"}>
                    {render ? render(item) : item[key]}
                  </td>
                ))}
                {rowActions && (
                  <td className={"generic-table-wrapper__action-cell"}>
                    {rowActions.map((action, index) => (
                      <Button
                        key={index}
                        onClick={() => action.onClick(item)}
                        className="button-line"
                      >
                        {typeof action.label === "function"
                          ? action.label(item)
                          : action.label}
                      </Button>
                    ))}
                  </td>
                )}
              </tr>
              {index < data.content.length - 1 && (
                <tr className="generic-table-wrapper__divider-row">
                  <td colSpan={columns.length + (rowActions ? 1 : 0)}></td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>

      <div className="paginacao">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={data ? data?.pageable?.pageNumber + 1 : 0}
          count={data ? data?.totalPages : 0}
        />
      </div>
    </>
  );
}
