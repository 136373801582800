import { useCallback, useState } from 'react';
import { Predicate } from '../../../models/predicate.model';
import { useGlobalContext } from '../../../context/GlobalContext';

export default function useAddPredicateOnQueryParam() {
  const { unidade } = useGlobalContext();

  const [predicate, setPredicateValue] = useState<Predicate>();

  const addPredicateOnQueryParam = useCallback(
    (page?: number): Predicate => {
      const predicate = new Predicate();

      predicate.addOption('ativo', true);

      predicate.addOption('cursoUnidades.unidade.id', unidade.id);

      if (page) predicate.setPage(page);

      setPredicateValue(predicate);

      return predicate;
    },
    [unidade.id],
  );

  return {
    predicate,
    addPredicateOnQueryParam,
  };
}
