import { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import {
  IBoxTurmaInformacaoDTO,
  ITurmaCleanDTO,
  ITurmaDTO,
  ITurmaFullDTO,
  ITurmaKpiDTO,
} from "../models/happy-code-api.model";
import { formatHttpParams, Predicate } from "../models/predicate.model";
import { isAfterDate, isBeforeDate, isDateBetween } from "../util/format";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useTurmaService = () => {
	const resourceURL = 'turma';

  const getKpi = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<ITurmaKpiDTO>> => {
    return http.get(`${resourceURL}/kpi`, formatHttpParams(predicate));
  };

  const getStatusTurma = (turma: ITurmaFullDTO) => {
    if (isBeforeDate(turma.dataInicio)) {
      return 'Não Iniciado';
    }
    if (isDateBetween(turma.dataInicio, turma.dataFim)) {
      return 'Em andamento';
    }
    if (isAfterDate(turma.dataFim)) {
      return 'Finalizado';
    }
    return null;
  };

  const findBoxTurmaInformacao = (
    idTurma: number,
  ): Promise<AxiosResponse<IBoxTurmaInformacaoDTO>> =>
    http.get(`${resourceURL}/box-turma-informacao/${idTurma}`);

  const checkIfTurmaIsHappyEnglish = (
    idTurma: number,
  ): Promise<AxiosResponse<boolean>> =>
    http.get(`${resourceURL}/is-happy-english/${idTurma}`);

	return {
		...baseFind<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseFindList<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseFindById<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseCreate<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseUpdate<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		getKpi,
		getStatusTurma,
    findBoxTurmaInformacao,
    checkIfTurmaIsHappyEnglish
	};
};
