import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from 'antd';
import { Sidebar } from '../../components/Sidebar';

import UserWelcomeCard from '../../components/user-welcome-card/user-welcome-card.component';
import { Item } from '../../components/Item/cursos';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const InstrutorTurmas = () => {
  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <UserWelcomeCard />
          <h1>Meus cursos (5)</h1>
          <p className="mb-20">Materiais de apoio para preparação das aulas</p>
          <div className="w-100 mb-20 table">
            <ul className="d-flex-row j-start-a-center table-head w-100">
              <li style={{ width: '5%' }}></li>
              <li style={{ width: '50%' }}>
                <span>Nome do Curso</span>
                <Unicons.UilDirection size={20} />
              </li>
              <li style={{ width: '15%' }}>
                <span>Turmas</span>
                <Unicons.UilDirection size={15} />
              </li>
              <li style={{ width: '15%' }}>
                <span>Alunos Ativos</span>
                <Unicons.UilDirection size={15} />
              </li>
              <li style={{ width: '15%' }}>
                <span>Aulas</span>
                <Unicons.UilDirection size={20} />
              </li>
            </ul>
            <div className="w-100">
              <Item
                width={['5%', '50%', '15%', '15%', '15%']}
                image="assets/img/item/digital-arts.svg"
                url="/materiais/aula"
              />
              <Item
                width={['5%', '50%', '15%', '15%', '15%']}
                image="assets/img/item/ppt.svg"
                url="/materiais/aula"
              />
              <Item
                width={['5%', '50%', '15%', '15%', '15%']}
                image="assets/img/item/letramento.svg"
                url="/materiais/aula"
              />
              <Item
                width={['5%', '50%', '15%', '15%', '15%']}
                image="assets/img/item/game.svg"
                url="/materiais/aula"
              />
              <Item
                width={['5%', '50%', '15%', '15%', '15%']}
                image="assets/img/item/roblox.svg"
                url="/materiais/aula"
              />
            </div>
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
