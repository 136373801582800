import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IFeedbackWebinarDTO } from '../../models/happy-code-api.model';
import { useAcaFeedbackWebinarService } from '../../services/aca-feedback-webinar';
import { useNotificationService } from '../../services/notification.service';

interface Props {
  open: boolean;
  onClose: () => void;
  feedback: IFeedbackWebinarDTO;
}

export const ModalClassificacaoWebinar = (props: Props) => {
  const { open, onClose, feedback } = props;
  const { setIsGlobalLoading } = useGlobalContext();
  const notification = useNotificationService();
  const navigate = useNavigate();
  const useFeedbackWebinarService = useAcaFeedbackWebinarService();

  const saveFeedback = () => {
    setIsGlobalLoading(true);
    useFeedbackWebinarService
      .create(feedback)
      .then(() => {
        navigate(`/webinars/all`);
        notification({
          description: `Feedback salvo com sucesso.`,
          type: 'success',
          message: 'Sucesso!',
        });
      })
      .finally(() => setIsGlobalLoading(false));
    onClose();
  };

  if (!open) return null;

  return (
    <>
      <div className="d-flex-row j-center-a-center modal-container">
        <div className="d-flex-column j-start-a-center modal">
          <div className="d-flex-row j-center-a-center circle mb-10">
            <Unicons.UilCheck color="var(--check)" size={80} />
          </div>
          <h1 className="mb-10">
            Obrigado.
            <br />
            Recebemos sua avaliação
          </h1>
          <div className="line"></div>
          <div className="d-flex-row button-box w-100">
            <Button onClick={saveFeedback} className="button-primary">
              Finalizar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
