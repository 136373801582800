import { Button, Form, Input, Radio } from 'antd';
import Instrutores from '../../assets/img/classificacao/instrutores.svg';
import { ModalClassificacao } from '../../components/Modal/modal-classificacao';
import { IAcaAulaDTO, ITopicoAulaDTO } from '../../models/happy-code-api.model';
import useAulaClassificaco from './use-aula-classificacao';

interface AulaClassificacaoProps {
  topico: ITopicoAulaDTO;
  proximaAula: IAcaAulaDTO;
  aula: IAcaAulaDTO;
  setFeedbackRespondido: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AulaClassificacao = ({
  topico,
  proximaAula,
  aula,
  setFeedbackRespondido,
}: AulaClassificacaoProps) => {
  const {
    feedback,
    feedbackRangeContent,
    isOpen,
    feedbackAvaliadoExist,
    onProximaAulaClick,
    toggleModalOpen,
    feedbackCardIsActived,
    aulaClassificacaoForm,
    submitAulaClassificacao,
  } = useAulaClassificaco({
    topico,
    proximaAula,
    aula,
    setFeedbackRespondido,
  });

  return (
    <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
      <div id="box-aula-white" style={{ width: '100%' }} className="box-white">
        <h2 className="title-classificacao mb-10">Muito bem!</h2>
        <div className="d-flex-row j-center-a-center mb-20">
          <img src={Instrutores} alt="instrutores" />
        </div>

        {feedbackAvaliadoExist && (
          <div className="d-flex-row j-end-a-center">
            <Button onClick={onProximaAulaClick} className="button-primary">
              {!!proximaAula ? 'Concluir aula' : 'Concluir'}
            </Button>
          </div>
        )}

        {!feedbackAvaliadoExist && (
          <>
            <p className="descricao-classificacao mb-20">
              Parabéns pela conclusão da aula, nos conte como foi experiência:
            </p>
            <Form
              layout="vertical"
              className="d-flex-column j-start-a-start w-100 mb-10"
              onFieldsChange={submitAulaClassificacao}
              form={aulaClassificacaoForm}
            >
              <Form.Item name="feedback-numero-nps" className="w-100">
                <Radio.Group value={feedback?.numeroNps}>
                  {feedbackRangeContent.map(({ image, content }, index) => (
                    <Radio
                      key={index}
                      value={index + 1}
                      className={`box-radio mb-10 ${
                        feedbackCardIsActived(index + 1) && 'box-radio-select'
                      }`}
                    >
                      <img src={image} alt="emoji" className="emoji" />
                      <span
                        className={`${
                          feedbackCardIsActived(index + 1) &&
                          'box-radio-select__text'
                        }`}
                      >
                        {content}
                      </span>
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="feedback-text-area"
                className="feedback-text-area"
                label="Feedback"
              >
                <Input.TextArea bordered rows={4} maxLength={200} />
              </Form.Item>
              <div className="w-100 mt-10 d-flex-row j-end-a-center">
                <Button onClick={toggleModalOpen} className="button-primary">
                  Classificar
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
      <ModalClassificacao
        open={isOpen}
        onClose={toggleModalOpen}
        feedback={feedback}
        topico={topico}
        proximaAula={proximaAula}
      />
    </div>
  );
};
