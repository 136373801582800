import './styles.css';

interface Props {
  setEventEye: React.Dispatch<React.SetStateAction<boolean>>;
  eventEye: boolean;
  style?: React.CSSProperties;
}

const PasswordEye: React.FunctionComponent<Props> = ({
  setEventEye,
  eventEye,
  style,
}: Props) => {
  return (
    <span
      style={style}
      className="box-eye"
      onClick={() => setEventEye(!eventEye)}
    >
      {!eventEye ? (
        <div className="open-eye">
          <span className="retina" />
        </div>
      ) : (
        <div className="open-eye" onClick={() => setEventEye(!eventEye)}>
          <span className="retina" />
          <span className="closed-eye" />
        </div>
      )}
    </span>
  );
};

export default PasswordEye;
