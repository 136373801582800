interface Props {
  image: string;
  name: string;
  formatura: string;
}

export const MentorMini = (props: Props) => {
  const { image, name, formatura } = props;

  return (
    <>
      <div className="box-mini-mentor d-flex-row j-start-a-center mb-5">
        <img src={image} alt="avatar" />
        <div className="info-mentor">
          <h3 className="mb-5">{name}</h3>
          <p>Formação em: {formatura}</p>
        </div>
      </div>
    </>
  );
};
