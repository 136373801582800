import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuDetalheUserProfile from '../../components/MenuDetalheUser/perfil';
import { Sidebar } from '../../components/Sidebar';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { IUsuarioFullDTO } from '../../models/happy-code-api.model';
import { useUsuarioService } from '../../services/usuario.service';
import { Avatar } from './../../components/Avatar/index';
import './style.css';

export default function Profile() {
  const { usuario: usuarioLogado, setIsGlobalLoading } = useGlobalContext();
  const usuarioService = useUsuarioService();
  const navigate = useNavigate();

  const [usuario, setUsuario] = useState<IUsuarioFullDTO>();

  useEffect(() => {
    setIsGlobalLoading(true);

    if (!usuarioLogado) return;

    usuarioService
      .findFullById(usuarioLogado)
      .then(({ data }) => {
        setUsuario(data);
      })
      .finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioLogado]);

  return (
    <div className="container">
      <Sidebar profile />
      <MenuDetalheUserProfile usuario={usuario} />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
        <div className="space1"></div>
        <div className="franquia-row">
          <h5>Meu Perfil</h5>
          <div className="row-revisao">
            <div className="box-revisao">
              <div className="dados">
                <h4>Dados Básicos</h4>
                <div className="box-aluno">
                  <Avatar size={115} arquivo={usuario?.arquivo} />
                  <div className="dados-colaborador">
                    <div className="nome">
                      <h5>Nome Completo</h5>
                      <span style={{ fontSize: 18 }}>
                        {usuario?.pessoa.nome}
                      </span>
                    </div>
                    <div className="dados-franquia"></div>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <h4>Função</h4>
                <div className="flex">
                  <div className="tags-perfil mr10">
                    <Tags tipo={usuario?.funcao.descricao} />
                  </div>
                  <div className="tags-perfil">
                    <Tags tipo={usuario?.perfil.codigo} />
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados wp50">
                <h4>Contato</h4>

                <div className="dados-franquia" style={{ flexWrap: 'wrap' }}>
                  <div className="cpf">
                    <h6>Email Pessoal</h6>
                    <span>{usuario?.pessoa.email}</span>
                  </div>
                  {usuario?.pessoa.contatos.map((contato, index) => (
                    <div className="cpf" key={index}>
                      <h6>{contato.tipoContatoCodigo}</h6>
                      <span>{contato.descricao}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                {usuario?.usuarioPaises.length > 0 ? (
                  <>
                    <h4>Paises</h4>
                    {usuario?.usuarioPaises.map((usuarioPais) => (
                      <div id="curso-usuarios" key={usuarioPais.id}>
                        <h5>{usuarioPais.paisNome}</h5>
                      </div>
                    ))}
                  </>
                ) : usuario?.usuarioClusters.length > 0 ? (
                  <>
                    <h4>Clusters</h4>
                    {usuario?.usuarioClusters.map((usuarioCluster) => (
                      <div id="curso-usuarios" key={usuarioCluster.id}>
                        <h5>{usuarioCluster.clusterDescricao}</h5>
                      </div>
                    ))}
                  </>
                ) : (
                  usuario?.usuarioUnidades.length > 0 && (
                    <>
                      <h4>Franquias</h4>
                      {usuario?.usuarioUnidades.map((usuarioUnidade) => (
                        <div id="curso-usuarios" key={usuarioUnidade.id}>
                          <h5>{usuarioUnidade.unidadeDescricao}</h5>
                        </div>
                      ))}
                    </>
                  )
                )}
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="botao-voltar-revisao">
        <Button
          className="button-second"
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
        >
          Voltar
        </Button>
      </div>
    </div>
  );
}
