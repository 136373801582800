import './select-options.styles.scss';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../../enum/lancamento-aula/habilidade-competencia-escala.enum';
import {
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  SentimentSatisfiedOutlined
} from '@mui/icons-material';

interface SkillAssessmentSelectOptionProps {
  value: HabilidadeCompetenciaEscalaEnum;
}

export default function SkillAssessmentSelectOption({ value }: SkillAssessmentSelectOptionProps) {
  const getSkillAssessmentOption = {
    [HabilidadeCompetenciaEscalaEnum.OTIMO]: {
      icon: <SentimentSatisfiedOutlined style={{ width: '20px' }} />,
      text: 'Otimo'
    },
    [HabilidadeCompetenciaEscalaEnum.NEUTRO]: {
      icon: <SentimentNeutralOutlined style={{ width: '20px' }} />,
      text: 'Neutro'
    },
    [HabilidadeCompetenciaEscalaEnum.PRECISA_MELHORAR]: {
      icon: <SentimentDissatisfiedOutlined style={{ width: '20px' }} />,
      text: 'Precisa melhorar'
    }
  };

  const skillAssessmentOption = getSkillAssessmentOption[value];

  return (
    <div className={'select-options-wrapper'}>
      {skillAssessmentOption.icon}
      <p>{skillAssessmentOption.text}</p>
    </div>
  );
}
