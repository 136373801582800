import { ITrilhaFullDTO } from '../../models/happy-code-api.model';

interface UseTrilhaCardProps {
  trilha: ITrilhaFullDTO;
}

export default function useTrilhaCard({ trilha }: UseTrilhaCardProps) {
  const { icone, arquivoIcone, capa, arquivoCapa } = trilha;

  const localEnvoirementFileUrl =
    process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY;

  const presentImageIcon = icone
    ? icone
    : localEnvoirementFileUrl + arquivoIcone?.url;

  const presentImageThumbnail = capa
    ? capa
    : localEnvoirementFileUrl + arquivoCapa?.url;

  return {
    presentImageIcon,
    presentImageThumbnail,
  };
}
