import { useState } from 'react';
import { ITrilhaFullDTO } from '../../../../../models/happy-code-api.model';

interface useTrilhaAccordionProps {
  trilha: ITrilhaFullDTO;
}
export default function useTrilhaAccordion({
  trilha,
}: useTrilhaAccordionProps) {
  const [trilhaAccordionIsOpen, setTrilhaAccordionIsOpen] = useState(false);

  const toggleTrilhaAccordionOpen = () =>
    setTrilhaAccordionIsOpen(!trilhaAccordionIsOpen);

  const { icone, arquivoIcone } = trilha;

  const iconFileBaseUrl =
    process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY;

  const trilhaImageIcon = icone ? icone : iconFileBaseUrl + arquivoIcone?.url;

  const totalCoins = trilha.cursos
    .map((item) => item.happyCoins)
    .reduce((sum: number, current: number) => sum + current, 0);

  return {
    trilhaAccordionIsOpen,
    toggleTrilhaAccordionOpen,
    totalCoins,
    trilhaImageIcon,
  };
}
