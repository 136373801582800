import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import {
  IAlunoDTO,
  IMatriculaDTO,
  IPessoaFullDTO,
  ISkillDTO,
  ITipoSkillDTO,
} from '../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../models/predicate.model';
import { useAlunoService } from '../../../../../services/aluno.service';
import { useMatriculaSkillService } from '../../../../../services/matricula-skill.service';
import { useMatriculaService } from '../../../../../services/matricula.service';
import { usePessoaService } from '../../../../../services/pessoa.service';
import { useSkillService } from '../../../../../services/skill.service';
import { useTipoSkillService } from '../../../../../services/tipo-skill.service';

const useSkillComponentState = () => {
  const [aluno, setAluno] = useState<IAlunoDTO>();
  const [matricula, setMatricula] = useState<IMatriculaDTO>();
  const [tipoSkills, setTipoSkills] = useState<ITipoSkillDTO[]>();
  const [todosTipoSkills, setTodosTipoSkills] = useState<ITipoSkillDTO[]>();
  const alunoService = useAlunoService();
  const matriculaService = useMatriculaService();
  const tipoSkillService = useTipoSkillService();
  const { matriculaId, idFrequencia } = useParams<any>();
  const [skills, setSkills] = useState<ISkillDTO[]>();
  const skillService = useSkillService();
  const [skillsSelecionadas, setSkillsSelecionadas] = useState([]);
  const [flModalAberto, setFlModalAberto] = useState(false);
  const matriculaSkillService = useMatriculaSkillService();
  const pessoaService = usePessoaService();
  const [flFetching, setFlFetching] = useState(true);
  const [flSalvando, setFlSalvando] = useState(false);
  const [pessoa, setPessoa] = useState<IPessoaFullDTO>();
  const { usuario } = useGlobalContext();

  const valoresSkill = {
    Nunca: 0,
    Raramente: 1,
    'Às vezes': 2,
    Frequentemente: 3,
    'Quase sempre': 4,
  };

  const onAbrirModal = () => {
    setFlModalAberto(true);
  };

  const fecharModal = () => {
    setFlModalAberto(false);
  };

  const getAluno = useCallback(
    (idAluno) => {
      return matriculaService
        .findById({ id: idAluno })
        .then((response) => {
          setMatricula(response.data);
          return alunoService.findFullById({ id: response.data.aluno.id });
        })
        .then((response) => {
          setAluno(response.data);
          return pessoaService
            .findFullById({ id: response.data.pessoa.id })
            .then((responsePessoa) => {
              setPessoa(responsePessoa.data);
            });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [matriculaService],
  );

  const tipoSkillAtual = useMemo(() => tipoSkills?.[0], [tipoSkills]);

  const onChangeSkill = (flChecked, nmOpcao: string, skill: ISkillDTO) => {
    setSkillsSelecionadas((skillsSelecionadasTemp) => {
      const index = skillsSelecionadasTemp.findIndex(
        (item) => item.id === skill.id,
      );
      skillsSelecionadasTemp[index].nmOpcaoSelecionada = flChecked
        ? nmOpcao
        : null;
      return [...skillsSelecionadasTemp];
    });
  };

  useEffect(() => {
    if (!matriculaId) return;

    const parseMatriculaIdToNumber = parseInt(matriculaId);

    setFlFetching(true);
    !aluno && getAluno(matriculaId);
    tipoSkillService
      .findTipoSkillNaoPreenchida(parseMatriculaIdToNumber)
      .then((response) => {
        setTipoSkills(response.data);
        setTodosTipoSkills(response.data);
      })
      .finally(() => setFlFetching(false));
  }, [matriculaId]);

  useEffect(() => {
    if (tipoSkillAtual) {
      const predicate = new Predicate();
      predicate.addOption('tipoSkill.id', tipoSkillAtual?.id);

      skillService.findFullList(predicate).then((response) => {
        setSkills(response.data);
        setSkillsSelecionadas(
          response.data?.map((skill) => {
            return {
              ...skill,
              nmOpcaoSelecionada: null,
            };
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoSkills]);

  const isChecked = useCallback(
    (nmOpcao, skill: ISkillDTO) => {
      if (!skillsSelecionadas || skillsSelecionadas?.length <= 0) return;

      const index = skillsSelecionadas.findIndex(
        (item) => item.id === skill.id,
      );

      if (index < 0) return;
      return skillsSelecionadas[index]?.nmOpcaoSelecionada === nmOpcao;
    },
    [skillsSelecionadas],
  );

  const onSalvarEirParaProximo = () => {
    const body = skillsSelecionadas.map((skill) => {
      let matriculaSkill = {
        matricula: {
          id: matriculaId,
        },
        skill: {
          id: skill.id,
        },
        frequencia: {
          id: idFrequencia,
        },
        vlOpcaoSkill: valoresSkill[skill.nmOpcaoSelecionada],
        dsOpcaoSkill: skill.nmOpcaoSelecionada,
      };
      return matriculaSkill;
    });

    setFlSalvando(true);

    matriculaSkillService
      .saveSkills(matriculaId, body)
      .then((response) => {
        if (response.status === 202) {
          const novosSkills = tipoSkills.filter(
            (tipoSkill) => tipoSkill.id !== tipoSkillAtual.id,
          );
          setTipoSkills(novosSkills);
        }
      })
      .finally(() => setFlSalvando(false));
    fecharModal();
  };

  const flAlgumNaoSelecionado = useMemo(
    () => skillsSelecionadas.some((item) => !item.nmOpcaoSelecionada),
    [skillsSelecionadas],
  );

  const proximoTipoSkill = useMemo(() => {
    if (!tipoSkills || tipoSkills.length <= 0) return;

    try {
      const indexAtual = tipoSkills.findIndex(
        (item) => item.id === tipoSkillAtual.id,
      );
      return tipoSkills[indexAtual + 1];
    } catch (e: any) {
      return null;
    }
  }, [tipoSkills, tipoSkillAtual]);

  const indexAtual = useMemo(() => {
    if (!todosTipoSkills || todosTipoSkills.length <= 0 || !tipoSkillAtual)
      return;

    return todosTipoSkills.findIndex((item) => item.id === tipoSkillAtual.id);
  }, [todosTipoSkills, tipoSkillAtual]);

  const paginaAtualSkill = useMemo(() => {
    if (!(indexAtual >= 0)) return;

    if (todosTipoSkills && todosTipoSkills.length > 0) {
      return `${indexAtual + 1} de ${todosTipoSkills.length}`;
    }

    return '';
  }, [todosTipoSkills, indexAtual]);

  return {
    aluno,
    tipoSkills,
    tipoSkillAtual,
    skills,
    skillsSelecionadas,
    onChangeSkill,
    isChecked,
    onSalvarEirParaProximo,
    flModalAberto,
    fecharModal,
    onAbrirModal,
    flAlgumNaoSelecionado,
    flFetching,
    proximoTipoSkill,
    flSalvando,
    paginaAtualSkill,
    indexAtual,
    usuario,
    pessoa,
  };
};

export default useSkillComponentState;
