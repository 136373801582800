import { useState } from "react";

export function useAvaliacaoNavigationHook(data) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);


  const goToNextQuestion = () => {
    if (currentQuestionIndex < data.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };


  return { currentQuestionIndex, goToNextQuestion, setCurrentQuestionIndex, goToPreviousQuestion };
}