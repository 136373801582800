import { Progress } from "antd";
import "./porcentagem-aulas.css";
import { boxTurmaInformacaoStore } from "../../store/box-turma-informacao.store";

export default function PorcentagemAulas() {
  const {
    boxTurmaInformacao: {
      quantidadeAulas,
      porcentagemAulasRealizadas,
      quantidadeAulasRealizadas,
    },
  } = boxTurmaInformacaoStore();

  return (
    <div className="porcentagem-aulas">
      <Progress
        type="circle"
        percent={porcentagemAulasRealizadas}
        size={80}
        strokeColor="var(--primary-color)"
        trailColor="rgba(203, 212, 237, 0.2)"
      />
      <div className="porcentagem-aulas__andamento-aulas">
        <h3 className="andamento-aulas__titulo">Andamento</h3>
        <h6 className="andamento-aulas__subtitulo">
          {quantidadeAulasRealizadas} de {quantidadeAulas} aulas
        </h6>
      </div>
    </div>
  );
}
