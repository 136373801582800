import UserWelcomeCard from '../../components/user-welcome-card/user-welcome-card.component';
import { CursoCard } from '../../components/Curso/curso-card.component';
import './_instrutor.css';
import useInstrutor from './use-instrutor.hook';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import IndicatorCard from './components/indicator-card/indicator-card.component';
import './instrutor.styles.scss';
import Title from '../../components/title/title.component';
import ClassManagement from './components/class-management/class-management.component';
import useFindUsuarioKpi from './hooks/use-find-usuario-kpi.hook';

export const Instrutor = () => {
  const { myCourses, iHaveCourses, iHaventCourses } = useInstrutor();
  useFindUsuarioKpi();

  return (
    <SidebarAndContainer instrutor={true}>
      <UserWelcomeCard />

      <div className="instrutor__cards-wrapper">
        <Title title="Meus indicadores" />
        <div className="indicators__cards">
          <IndicatorCard cardType="aulasAssistidas" />
          <IndicatorCard cardType="cursosEmAndamento" />
          <IndicatorCard cardType="cursosConcluidos" />
          <IndicatorCard cardType="trilhasConcluidas" />
        </div>
      </div>

      <ClassManagement />

      <div className="instrutor__cards-wrapper">
        <Title title="Meus cursos" />

        {iHaveCourses && (
          <div className="courses-cards-wrapper">
            {myCourses.map(({ id, curso }) => (
              <CursoCard
                key={id}
                progress={false}
                trilha={curso?.trilha}
                curso={curso}
              />
            ))}
          </div>
        )}
        {iHaventCourses && <div>sem cursos em andamento no momento</div>}
      </div>
    </SidebarAndContainer>
  );
};
