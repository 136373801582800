import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { IMaterialApoioDTO } from '../../../../../models/happy-code-api.model';
import './arquivos-all.styles.scss';
import UseArquivoMaterialApoioHook from './use-arquivo-hook';
interface PastaProps {
  MaterialApoio: IMaterialApoioDTO;
}

export const ArquivoContainer = ({ MaterialApoio }: PastaProps) => {
 const {handleDownload} = UseArquivoMaterialApoioHook()
 const fileType = MaterialApoio?.arquivo?.codigo.split('.').slice(-1)[0];
  
 const getFileIcon = (type: string) => {
  switch (type) {
    case 'jpeg':
    case 'jpg': 
    case'png':
    return <Unicons.UilScenery  size={120} />;
    
    case'mp4':
    case'mov':
    return <Unicons.UilVideo size={120} />;
 
    default:
      return <Unicons.UilFileDownload  size={120} />; // Ícone genérico
  }
};
  
  return (
    <div
      className="arquivo-card-container"
    >
      <div className="arquivo-item">
      {getFileIcon(fileType)}
      <div className='arquivo-title'><h1>{MaterialApoio?.descricao}.{fileType}</h1></div>
        <Button onClick={() => handleDownload(MaterialApoio)} className='button-primary'>Download</Button>
      </div>
    </div>
  );
};
