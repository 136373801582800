import { Sidebar } from '../../components/Sidebar';
import { Button, Select, Form, Input, Pagination } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import { Mentor } from '../../components/Mentor';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const MentoriasMentores = () => {
  const { Option } = Select;

  function handleChange(value: string) {}

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Link to="/mentorias">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
          </div>
          <h1 style={{ marginLeft: 0 }} className="fav-title mb-20">
            Mentores disponiveis (99)
          </h1>
          <Form
            id="box-form"
            className="d-flex-row j-start-a-center w-100 mb-20"
          >
            <div className="d-flex-row j-start-a-center input-box w-100">
              <Unicons.UilSearch
                size="20"
                color="var(--dark-secondary)"
                className="iconForm"
              />
              <Form.Item name="search" noStyle={true}>
                <Input placeholder="Buscar trilha por curso ou status" />
              </Form.Item>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Curso
              <Select
                defaultValue="Todos"
                style={{ width: 85 }}
                onChange={handleChange}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Formação
              <Select
                defaultValue="Status"
                style={{ width: 85 }}
                onChange={handleChange}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
          </Form>
          <div className="d-flex-row j-between-a-start flex-wrap w-100 mb-20">
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
