import { useParams } from 'react-router-dom';
import { useTrilhaService } from '../../../../services/trilha.service';
import { ITrilhaFullDTO } from '../../../../models/happy-code-api.model';
import { useCallback, useState } from 'react';

export default function useFindTrilhaById() {
  const { idTrilha } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const { findFullById } = useTrilhaService();

  const [trilhaData, setTrilhaData] = useState<ITrilhaFullDTO>();

  const findTrilhaById = useCallback(async () => {
    if (!idTrilha) return;

    await findFullById({ id: parseInt(idTrilha) }).then(({ data }) =>
      setTrilhaData(data),
    );
  }, [idTrilha]);

  return {
    trilhaData,
    findTrilhaById,
  };
}
