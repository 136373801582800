import http from '../../helpers/http-common';
import {
  IConteudoAulaDTO,
  ICreateConteudoMinistradoDTO,
  IUpdateConteudoMinistradoDTO
} from '../../models/happy-code-api.model';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';

export const useLearnConteudoMinistradoService = () => {
  const resourceUrl = '/learn/v1/tbconteudoministrado';

  const createConteudoMinistrado = async (body: ICreateConteudoMinistradoDTO) =>
    await http.post(`${resourceUrl}/create`, body);

  const findConteudoAula = useCallback(async (idAula: number): Promise<AxiosResponse<IConteudoAulaDTO>> =>
    await http.get<IConteudoAulaDTO>(`${resourceUrl}/conteudo-aula/${idAula}`), [resourceUrl]);

  const findConteudoAlreadyRegistered = useCallback(async (idAula: number): Promise<AxiosResponse<boolean>> =>
    await http.get<boolean>(`${resourceUrl}/conteudo-already-registered/${idAula}`), [resourceUrl]);

  const update = async (body: IUpdateConteudoMinistradoDTO) =>
    await http.patch(`${resourceUrl}/patch`, body);

  return {
    createConteudoMinistrado,
    findConteudoAula,
    findConteudoAlreadyRegistered,
    update
  };
};
