import { PresentImage } from '../../../../../components/ApresentarImagem';
import { CursoCard } from '../../../../../components/Curso/curso-card.component';
import { ITrilhaFullDTO } from '../../../../../models/happy-code-api.model';
import Coin from '../../../../../assets/img/dashboard/coin.svg';
import useTrilhaAccordion from './use-trilha-accordion.hook';
import * as Unicons from '@iconscout/react-unicons';
import './trilha-accordion.styles.scss';
import { Button } from 'antd';
import IconButton from '../../../../../components/icon-button/icon-button.component';

interface TrilhaAccordionProps {
  trilha: ITrilhaFullDTO;
  searchValue: string;
}
export default function TrilhaAccordion({
  trilha,
  searchValue,
}: TrilhaAccordionProps) {
  const {
    trilhaAccordionIsOpen,
    toggleTrilhaAccordionOpen,
    totalCoins,
    trilhaImageIcon,
  } = useTrilhaAccordion({ trilha });

  const { nome1, nome2, corNome2 } = trilha;

  const filteredCursos = trilha.cursos.filter(({ nome }) => {
    const nameToLowerCase = nome.toLowerCase();
    const searchValueToLowerCase = searchValue.toLowerCase();

    return nameToLowerCase.includes(searchValueToLowerCase);
  });

  return (
    <>
      <div className="trilha-accordion">
        <div
          onClick={toggleTrilhaAccordionOpen}
          className="trilha-accordion__top-accordion"
        >
          <div className="top-accordion__icon-trilha-name">
            <PresentImage size={40} link={trilhaImageIcon} />

            <h2 className="icon-trilha-name__trilha-name">
              {nome1} <span style={{ color: corNome2 }}>{nome2}</span>
            </h2>
          </div>

          <div className="top-accordion__rewards-coins-chevron-icon">
            {/* <div className="rewards-coins-chevron-icon__rewards-coins">
              <p className="rewards-coins__label">Conclua a trilha e ganhe:</p>

              <div className="rewards-coins__coins-value-wrapper">
                <p>h¢ {totalCoins}</p>
                <img src={Coin} alt="Happy Coins" className="coin-trilha" />
              </div>
            </div> */}

            {trilhaAccordionIsOpen && <IconButton icon={Unicons.UilAngleUp} />}
            {!trilhaAccordionIsOpen && (
              <IconButton icon={Unicons.UilAngleDown} />
            )}
          </div>
        </div>
        {trilhaAccordionIsOpen && (
          <div className="trilha-accordion__bottom-card-container">
            {filteredCursos.map((curso) => (
              <CursoCard
                progress={false}
                key={curso.id}
                trilha={trilha}
                curso={curso}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
