import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import { Webinar } from '../../components/webinar-card/webinar-card.component';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import useWebinarsAll from './use-webinars-all.hook';
import Title from '../../components/title/title.component';
import './webinars-all.styles.scss';

export const WebinarsAll = () => {
  const { webinarsPage, search, setSearch, onPageChange, webinarTitle } =
    useWebinarsAll();

  return (
    <SidebarAndContainer webinars={true}>
      <div className="webinars-all-container__title-back-button">
        <Link to="/instrutor" className="button-second">
          <Unicons.UilArrowLeft />
          Voltar
        </Link>

        <Title title={`Webinars  (${webinarTitle})`} />
      </div>

      <div className="webinars-all-container__search-bar">
        <i className="search-bar__icon">
          <Unicons.UilSearch size={20} color="var(--dark)" />
        </i>
        <input
          className="search-bar__input"
          placeholder="Buscar webinar"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="webinars-all-container__webinars-cards">
        {webinarsPage &&
          webinarsPage.content
            .filter(({ nome }) => {
              const nameLowerCase = nome.toLowerCase();
              const searchLowerCase = search.toLowerCase();

              return nameLowerCase.includes(searchLowerCase);
            })
            .map((webinar) => <Webinar webinar={webinar} />)}
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={webinarsPage ? webinarsPage.pageable.pageNumber + 1 : 0}
          count={webinarsPage ? webinarsPage.totalPages : 0}
        />
      </div>
    </SidebarAndContainer>
  );
};
