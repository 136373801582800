import { useCallback, useEffect, useState } from 'react';
import { Predicate } from '../../models/predicate.model';
import {
  IAcaAulaCleanDTO,
  IAcaCursoDTO,
} from '../../models/happy-code-api.model';
import { useAcaAulaService } from '../../services/aca-aula.service';

interface UseCursoProps {
  curso: IAcaCursoDTO;
}
export default function useCurso({ curso }: UseCursoProps) {
  const predicate = new Predicate();

  const aulaService = useAcaAulaService();

  const [classData, setClassData] = useState<IAcaAulaCleanDTO[]>([]);

  const findAcademyClass = useCallback(async () => {
    predicate.addOption('ativo', true);
    predicate.addOption('curso.id', curso.id);

    await aulaService
      .findClean(predicate)
      .then(({ data }) => setClassData(data.content));
  }, [curso.id]);

  useEffect(() => {
    const controller = new AbortController();

    findAcademyClass();

    return () => controller.abort();
  }, [findAcademyClass]);

  const calculateWorkload = () => {
    const schedules = classData.map(({ duracao }) => parseInt(duracao) || 0);

    const totalMinutes = schedules.reduce(
      (total, minutos) => total + minutos,
      0,
    );

    const hours = Math.floor(totalMinutes / 60);
    const minutesRemaining = totalMinutes % 60;

    const formattedHour = formatTime(hours);
    const formattedMinutes = formatTime(minutesRemaining);

    return `${formattedHour}:${formattedMinutes}`;
  };

  const formatTime = (hour: number): string => hour.toString().padStart(2, '0');

  const urlFile =
    process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY +
    curso?.arquivo?.url;

  const presentImageCourse = curso.capaCurso ? curso.capaCurso : urlFile;

  return { calculateWorkload, presentImageCourse };
}
