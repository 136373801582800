export interface Ferramenta {
  id: number;
  nome: string;
  modalidade: Modalidade;
  link: string;
  img: string;
}

export interface Curso {
  nome: string;
  ferramentas: Ferramenta[];
}

export interface FaixaEtaria {
  faixa: string; 
  cursos: Curso[];
}

export enum Modalidade {
  ONLINE = "online",
  APP = "aplicativo",
  INSTALADO = "instalado",
}


export const ferramentasB2C: FaixaEtaria[] = [
  {
    faixa: "Happy One",
    cursos: [
      {
        nome: "Exploradores Digitais",
        ferramentas: [
          {
            id: 1,
            nome: "STEM Play",
            modalidade: Modalidade.ONLINE,
            link: "https://app.stemplay.io/",
            img: "./assets/img/ferramentas-b2b/stem-play.png"
          },
          {
            id: 2,
            nome: "Scratchjr",
            modalidade: Modalidade.APP,
            link: "https://jfo8000.github.io/ScratchJr-Desktop/",
            img: "./assets/img/ferramentas-b2b/scratchjr.png"
          },
          {
            id: 3,
            nome: "Kahoot",
            modalidade: Modalidade.ONLINE,
           
            link: "https://kahoot.it/",
            img: "./assets/img/ferramentas-b2b/kahoot.png"
          }
        ]
      },
      // {
      //   nome: "Exploradores Musicais",
      //   ferramentas: []
      // },
      {
        nome: "Robótica Kids",
        ferramentas: [
          { id: 1, nome: "GO", modalidade: Modalidade.APP, link:"https://play.google.com/store/apps/details?id=com.w2.apps.go&hl=en&gl=US",  img: "./assets/img/ferramentas-b2c/go.png" },
          { id: 2, nome: "PATH", modalidade: Modalidade.APP, link:"https://play.google.com/store/apps/details?id=com.makewonder.pathandroid&hl=en&gl=US",  img: "./assets/img/ferramentas-b2c/path.png" },
          { id: 3, nome: "BLOCKLY", modalidade: Modalidade.APP, link:"https://play.google.com/store/apps/details?id=com.makewonder.blockly&hl=en&gl=US",  img: "./assets/img/ferramentas-b2c/blockly.png" },
          { id: 4, nome: "WONDER", modalidade: Modalidade.APP, link:"https://play.google.com/store/apps/details?id=com.makewonder.wonder&hl=en&gl=US",  img: "./assets/img/ferramentas-b2c/wonder.png" }
        ]
      }
    ]
  },
  {
    faixa: "Happy Plus",
    cursos: [
      {
        nome: "Cientistas Digitais",
        ferramentas: [
          {
            id: 1,
            nome: "STEM Play",
            modalidade: Modalidade.ONLINE,
           
            link: "https://app.stemplay.io/",
            img: "./assets/img/ferramentas-b2b/stem-play.png"
          },
          {
            id: 2,
            nome: "Code.org",
            modalidade: Modalidade.ONLINE,
           
            link: "https://code.org/",
            img: "./assets/img/ferramentas-b2c/code.png"
          },
          {
            id: 3,
            nome: "Scratch 3.0",
            modalidade: Modalidade.ONLINE,
           
            link: "https://scratch.mit.edu/",
            img: "./assets/img/ferramentas-b2b/scratch.png"
          }
        ]
      },
      {
        nome: "Games com IA",
        ferramentas: [
          { id: 1, nome: "Code.org", modalidade: Modalidade.ONLINE, link: "https://code.org/", img: "./assets/img/ferramentas-b2c/code.png" },
          { id: 2, nome: "Minecraft Education", modalidade: Modalidade.INSTALADO, link:"https://education.minecraft.net/en-us/get-started/download", img: "./assets/img/ferramentas-b2c/minecraft-edu.png" },
          { id: 3, nome: "Pictoblox", modalidade: Modalidade.INSTALADO, link:"https://pictoblox.ai/download/", img: "./assets/img/ferramentas-b2c/pictoblox.png" },
          { id: 4, nome: "Mblock", modalidade: Modalidade.INSTALADO, link:"https://www.mblock.cc/en-us/download/", img: "./assets/img/ferramentas-b2c/mblock.png" },
          { id: 5, nome: "ChatGPT", modalidade: Modalidade.ONLINE, link: "https://chat.openai.com/", img: "./assets/img/ferramentas-b2b/chat-gpt.png" }
        ]
      },
      // {
      //   nome: "Produtor Musical",
      //   ferramentas: []
      // },
      {
        nome: "Dev. Games Jr",
        ferramentas: [
          { id: 1, nome: "Gamefroot", modalidade: Modalidade.ONLINE, link: "https://gamefroot.com/", img: "./assets/img/ferramentas-b2c/gamefroot.png" },
          { id: 2, nome: "Flowlab", modalidade: Modalidade.ONLINE, link: "https://flowlab.io/", img: "./assets/img/ferramentas-b2c/flowlab.png" },
          { id: 3, nome: "Construct 3", modalidade: Modalidade.ONLINE, link: "https://editor.construct.net/", img: "./assets/img/ferramentas-b2b/construct3.png" },
          { id: 4, nome: "Piskelapp", modalidade: Modalidade.ONLINE, link: "https://www.piskelapp.com/", img: "./assets/img/ferramentas-b2c/piskel.png" },
          { id: 5, nome: "Pixton", modalidade: Modalidade.ONLINE, link: "https://www.pixton.com/", img: "./assets/img/ferramentas-b2c/pixton.png" },
          { id: 6, nome: "Codecombat", modalidade: Modalidade.ONLINE, link: "https://www.codecombat.com/", img: "./assets/img/ferramentas-b2c/code-combat.png" },
          { id: 7, nome: "Song Maker", modalidade: Modalidade.ONLINE, link: "https://musiclab.chromeexperiments.com/Song-Maker/", img: "./assets/img/ferramentas-b2c/song-maker.png" },
          { id: 8, nome: "Wombo Art", modalidade: Modalidade.ONLINE, link: "https://www.wombo.art/", img: "./assets/img/ferramentas-b2c/wombo.png" }
        ]
      },
      {
        nome: "Robótica com Arduino",
        ferramentas: [
          { id: 1, nome: "Tinkercad", modalidade: Modalidade.ONLINE, link: "https://www.tinkercad.com/", img: "./assets/img/ferramentas-b2b/tinkercad.png" },
          { id: 2, nome: "IDE Arduino", modalidade: Modalidade.INSTALADO, link:"https://www.arduino.cc/en/software",  img: "./assets/img/ferramentas-b2c/arduino-ide.png" }
        ]
      },
      {
        nome: "Dev. Apps Jr",
        ferramentas: [
          { id: 1, nome: "Thunkable", modalidade: Modalidade.ONLINE, link: "https://thunkable.com/", img: "./assets/img/ferramentas-b2b/thunkable.png" },
          { id: 2, nome: "Glideapps", modalidade: Modalidade.ONLINE, link: "https://www.glideapps.com/", img: "./assets/img/ferramentas-b2b/glide.png" },
          { id: 3, nome: "Audacity", modalidade: Modalidade.INSTALADO, link:"https://www.audacityteam.org/",  img: "./assets/img/ferramentas-b2c/audacity.png" },
          { id: 4, nome: "Kodular", modalidade: Modalidade.ONLINE, link: "https://www.kodular.io/", img: "./assets/img/ferramentas-b2c/kodular.png" },
          { id: 5, nome: "Cloudinary", modalidade: Modalidade.ONLINE, link: "https://cloudinary.com/", img: "./assets/img/ferramentas-b2c/cloudinary.png" },  
          { id: 6, nome: "Bubble", modalidade: Modalidade.ONLINE, link: "https://bubble.io/", img: "./assets/img/ferramentas-b2c/bubble.png" },
          { id: 7, nome: "Firebase", modalidade: Modalidade.ONLINE, link: "https://firebase.google.com/", img: "./assets/img/ferramentas-b2c/firebase.png" },
          { id: 8, nome: "Microsoft Translate API", modalidade: Modalidade.ONLINE, link: "https://www.microsoft.com/en-us/translator/business/", img: "./assets/img/ferramentas-b2c/translate-api.png" },
          { id: 9, nome: "Spotify", modalidade: Modalidade.ONLINE, link: "https://www.spotify.com/", img: "./assets/img/ferramentas-b2c/spotify.png" },
          { id: 10, nome: "Google Planilhas", modalidade: Modalidade.ONLINE, link: "https://www.google.com/sheets/about/", img: "./assets/img/ferramentas-b2c/google-planilhas.png" }
        ]
      },
      {
        nome: "Des. Criativo I",
        ferramentas: [
          { id: 1, nome: "Krita", modalidade: Modalidade.INSTALADO, link:"https://krita.org/en/download/krita-desktop/",  img: "./assets/img/ferramentas-b2c/krita.png" },
          { id: 2, nome: "Behance", modalidade: Modalidade.ONLINE, link: "https://www.behance.net/", img: "./assets/img/ferramentas-b2c/behance.png" },
          { id: 3, nome: "Magica Voxel", modalidade: Modalidade.INSTALADO, link:"https://ephtracy.github.io/", img: "./assets/img/ferramentas-b2c/magica-voxel.png" },
          { id: 4, nome: "VSDC", modalidade: Modalidade.INSTALADO, link:"https://www.videosoftdev.com/",  img: "./assets/img/ferramentas-b2c/vsdc.png" },
          { id: 5, nome: "App Runway", modalidade: Modalidade.ONLINE, link:"https://runwayml.com/", img: "./assets/img/ferramentas-b2c/runway.png" },
          { id: 6, nome: "Womp", modalidade: Modalidade.ONLINE, link:"https://womp.com/", img: "./assets/img/ferramentas-b2c/womp.png" }
        ]
      }
    ]
  },
  {
    faixa: "Happy Life",
    cursos: [
      {
        nome: "Influenciador Digital",
        ferramentas: [
          { id: 1, nome: "VSDC", modalidade: Modalidade.INSTALADO,link:"https://www.videosoftdev.com/", img: "./assets/img/ferramentas-b2c/vsdc.png" },
          { id: 2, nome: "YouTube", modalidade: Modalidade.ONLINE, link: "https://www.youtube.com", img: "./assets/img/ferramentas-b2c/youtube.png" },
          { id: 3, nome: "Tik Tok", modalidade: Modalidade.APP, link: "https://www.tiktok.com", img: "./assets/img/ferramentas-b2c/tiktok.png" },
          { id: 4, nome: "Instagram", modalidade: Modalidade.APP, link: "https://www.instagram.com", img: "./assets/img/ferramentas-b2c/instagram.png" },
          { id: 5, nome: "StreamYard", modalidade: Modalidade.INSTALADO, link:"https://streamyard.com/", img: "./assets/img/ferramentas-b2c/streamyard.png" },
          { id: 6, nome: "Canva", modalidade: Modalidade.ONLINE, link: "https://www.canva.com", img: "./assets/img/ferramentas-b2b/canva.png" },
          { id: 7, nome: "Anchor", modalidade: Modalidade.ONLINE, link: "https://anchor.fm", img: "./assets/img/ferramentas-b2c/anchor.png" },
          { id: 8, nome: "Audacity", modalidade: Modalidade.INSTALADO, link:"https://www.audacityteam.org/", img: "./assets/img/ferramentas-b2c/audacity.png" },
          { id: 9, nome: "Song Maker", modalidade: Modalidade.ONLINE, link: "https://musiclab.chromeexperiments.com/Song-Maker/", img: "./assets/img/ferramentas-b2c/song-maker.png" },
          { id: 10, nome: "Spotify", modalidade: Modalidade.ONLINE, link: "https://www.spotify.com", img: "./assets/img/ferramentas-b2c/spotify.png" }
        ]
      },
      {
        nome: "Dev. Games Pleno",
        ferramentas: [
          { id: 1, nome: "Game Maker", link:"https://www.yoyogames.com/gamemaker", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/game-maker.png" },
          { id: 2, nome: "Unity", link:"https://unity.com/download", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/unity.png" },
          { id: 3, nome: "Unreal Engine", link:"https://www.unrealengine.com/en-US/download", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/unreal-engine.png" },
          { id: 4, nome: "ChatGPT", modalidade: Modalidade.ONLINE, link: "https://chat.openai.com", img: "./assets/img/ferramentas-b2b/chat-gpt.png" }
        ]
      },
      {
        nome: "Dev. De Games e Apps em Python",
        ferramentas: [
          { id: 1, nome: "Visual Studio Code", link:"https://code.visualstudio.com/", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/vscode.png" },
          { id: 2, nome: "Interpretador de Python 3.12", link:"https://www.python.org/downloads/", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/python.png" },
          { id: 3, nome: "Framework Panda3D", link:"https://www.panda3d.org/download/", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/panda3d.png" },
          { id: 4, nome: "Framework Kivy", link:"https://kivy.org/#download", modalidade: Modalidade.INSTALADO, img: "./assets/img/ferramentas-b2c/kivy.png" }
        ]
      }
    ]
  }

];
