import { differenceInYears } from 'date-fns';
import { ITurmaFullDTO } from '../../../../models/happy-code-api.model';
import {
  formattedDate,
  isBeforeDate,
  isDateBetween,
} from '../../../../util/format';

type ClassStatus = 'naoIniciado' | 'emAndamento' | 'finalizado';

type StatusMap = {
  [key in ClassStatus]: {
    dotClass: string;
    textClass: string;
    text: string;
  };
};

interface UseClassCardProps {
  turma: ITurmaFullDTO;
}

export default function useClassCard({ turma }: UseClassCardProps) {
  const statusMap: StatusMap = {
    naoIniciado: {
      dotClass: 'class-status__dot-red',
      textClass: 'class-status__text-red',
      text: 'Não Iniciado',
    },
    emAndamento: {
      dotClass: 'class-status__dot-green',
      textClass: 'class-status__text-green',
      text: 'Em andamento',
    },
    finalizado: {
      dotClass: 'class-status__dot-red',
      textClass: 'class-status',
      text: 'Finalizado',
    },
  };

  const checkWhatStatusClassIs = (): StatusMap[ClassStatus] => {
    if (!turma || !turma.dataInicio || isBeforeDate(turma.dataInicio))
      return statusMap.naoIniciado;

    if (!turma.dataFim || isDateBetween(turma.dataInicio, turma.dataFim))
      return statusMap.emAndamento;

    return statusMap.finalizado;
  };

  const { dataFim, dataInicio, horarios } = turma;

  const initialDateFormatted = formattedDate(turma.dataInicio);
  const finalDateFormatted = formattedDate(turma.dataFim);

  const differenceInYearsBetweenClassDates = differenceInYears(
    dataFim,
    dataInicio,
  );

  const hasHorarios = horarios.length > 0;

  return {
    checkWhatStatusClassIs,
    initialDateFormatted,
    finalDateFormatted,
    differenceInYearsBetweenClassDates,
    hasHorarios,
  };
}
