import { useCallback, useEffect, useState } from 'react';
import { useAcaAulaService } from '../../services/aca-aula.service';
import { IAcaAulaInfoDTO } from '../../models/happy-code-api.model';

interface UseFetchClassroomInfosByCourseIdProps {
  courseId: string;
}

export default function useFetchClassroomInfosByCourseId({
  courseId,
}: UseFetchClassroomInfosByCourseIdProps) {
  const { findClassroomInfosByCourseId } = useAcaAulaService();
  const [classroomInfos, setClassroomInfos] = useState<IAcaAulaInfoDTO>({
    cargaHoraria: '00:00',
    quantidadeDeAulas: 0,
  });

  const fetchClassroomInfosByCourseId = useCallback(() => {
    if (!courseId) return;

    const formattedCourseId = parseInt(courseId);

    findClassroomInfosByCourseId(formattedCourseId)
      .then(({ data }) => setClassroomInfos(data))
      .catch((error) => console.error(error));
  }, [courseId]);

  useEffect(() => {
    const controller = new AbortController();

    fetchClassroomInfosByCourseId();

    return () => controller.abort();
  }, [fetchClassroomInfosByCourseId]);

  return {
    classroomInfos,
  };
}
