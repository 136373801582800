import {
  ICursoAulaPadraoCleanDTO,
  ICursoAulaPadraoDTO,
  ICursoAulaPadraoFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useCursoAulaPadraoService = () => {
  const resourceURL = 'curso-aula-padrao';

  return {
    ...baseCreate<
      ICursoAulaPadraoCleanDTO,
      ICursoAulaPadraoDTO,
      ICursoAulaPadraoFullDTO
    >(resourceURL),
    ...baseFind<
      ICursoAulaPadraoCleanDTO,
      ICursoAulaPadraoDTO,
      ICursoAulaPadraoFullDTO
    >(resourceURL),
    ...baseFindById<
      ICursoAulaPadraoCleanDTO,
      ICursoAulaPadraoDTO,
      ICursoAulaPadraoFullDTO
    >(resourceURL),
  };
};
