import { Link } from 'react-router-dom';
import { ITrilhaFullDTO } from '../../models/happy-code-api.model';
import { PresentImage } from '../ApresentarImagem';
import useTrilhaCard from './use-trilha-card.hook';
import './trilha-card.styles.scss';

interface TrilhaCardProps {
  trilha: ITrilhaFullDTO;
}

export const TrilhaCard = ({ trilha }: TrilhaCardProps) => {
  const { presentImageIcon, presentImageThumbnail } = useTrilhaCard({ trilha });

  const { nome1, nome2, descricao, corNome2 } = trilha;

  return (
    <div className="trilha-card-container">
      <div className="trilha-card-container__trilha-infos">
        <div className="trilha-infos__trilha-name-icon">
          <PresentImage size={32} link={presentImageIcon} />
          <h2 className="trilha-name-icon__trilha-name">
            {nome1} <span style={{ color: corNome2 }}>{nome2}</span>
          </h2>
        </div>

        <div className="trilha-infos__trilha-thumb">
          <PresentImage link={presentImageThumbnail} />
        </div>

        <p className="trilha-card-container__description">{descricao}</p>
      </div>

      <Link to={`/cursos/trilha/${trilha.id}`} className="button-primary">
        Acessar Cursos da Trilha
      </Link>
    </div>
  );
};
