/* eslint-disable react-hooks/rules-of-hooks */
import * as Unicons from '@iconscout/react-unicons';
import { useEffect, useState } from 'react';
import { Operators } from '../../../../../enum/operators.enum';
import { Predicate } from '../../../../../models/predicate.model';

interface PastaFiltrosProps {
  findMaterialApoio: () => void;
  predicate: Predicate;
}
export default function FiltrosArquivo({ findMaterialApoio, predicate }: PastaFiltrosProps) {
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
        predicate.removeAllOption();

        if (search) {
            predicate.addOption('arquivo.codigo', search, Operators.CONTAINS);
        }
      
        findMaterialApoio();
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [search]);
  return (
    <>
       <i className="search-bar__icon">
          <Unicons.UilSearch size={20} color="var(--dark)" />
        </i>
        <input
          className="search-bar__input"
          placeholder="Buscar arquivo pela descrição"
          onChange={(e) => setSearch(e.target.value)}
        />
    </>
  );
}
