import { create } from 'zustand';
import {
  TipoConteudoEnum
} from '../../../pages/LancamentoAula/enums/tipo-conteudo.enum';
import { IConteudoAulaDTO } from '../../../models/happy-code-api.model';

type Store = {
  conteudoMinistradoData: IConteudoAulaDTO;
  updateConteudoMinistradoData: (
    content: string | number | TipoConteudoEnum,
    key: keyof IConteudoAulaDTO
  ) => void;
  resetConteudoMinistradoData: () => void;
  setConteudoMinistradoData: (content: IConteudoAulaDTO) => void
};

const conteudoMinistradoDefaultValue: IConteudoAulaDTO = {
  tipo: TipoConteudoEnum.Aula,
  titulo: '',
  conteudo: null,
  conteudoDescricao: '',
  descricao: ''
};

export const conteudoMinistradoDataStore = create<Store>((set) => ({
  conteudoMinistradoData: conteudoMinistradoDefaultValue,
  updateConteudoMinistradoData: (data, key) =>
    set((previousData) => {
      const updatedData = {
        ...previousData.conteudoMinistradoData,
        [key]: data
      };

      if (key === 'tipo')
        updatedData.conteudo = null;

      return {
        conteudoMinistradoData: updatedData
      };
    }),
  resetConteudoMinistradoData: () =>
    set(() => ({
      conteudoMinistradoData: conteudoMinistradoDefaultValue
    })),
  setConteudoMinistradoData: (data) =>
    set(() => ({
        conteudoMinistradoData: data
      })
    )
}));
