import { useState } from "react";
import {
  IAvaliacaoTableDTO,
  IMatriculaProvaCleanDTO,
} from "../../../../models/happy-code-api.model";
import { PageableResponse } from "../../../../models/response.model";

interface AvaliacaoTableContent {
  id: number;
  nome: string;
  statusMatricula: string;
  matriculaProva: IMatriculaProvaCleanDTO;
  primeiraNota: number;
  segundaNota: number;
  finalNota: number;
}

interface useAvaliacoesTableProps {
  findAvaliacoes: () => Promise<void>;
  tableData: PageableResponse<IAvaliacaoTableDTO>;
}

export default function useAvaliacoesTable({
  findAvaliacoes,
  tableData,
}: useAvaliacoesTableProps) {
  const [lancamentoNota, setLancamentoNota] = useState<boolean>();
  const [provaSelected, setProvaSelected] = useState<IMatriculaProvaCleanDTO>();
  const [idMatriculaSelected, setIdMatriculaSelected] = useState<number>(0);

  const handleLancarNota = (
    matriculaProva: IMatriculaProvaCleanDTO,
    idMatricula: number,
  ) => {
    setLancamentoNota(true);
    setProvaSelected(matriculaProva);
    setIdMatriculaSelected(idMatricula);
  };

  const onCloseModal = async () => {
    setLancamentoNota(false);
    await findAvaliacoes();
  };

  const avaliacaoTableContent = (): Array<AvaliacaoTableContent> => {
    return tableData?.content?.map(
      ({ nomeAluno, matriculaProva, idMatricula, statusMatricula }) => ({
        id: idMatricula,
        nome: nomeAluno,
        statusMatricula,
        matriculaProva,
        primeiraNota: matriculaProva?.primeiraNota ?? 0,
        segundaNota: matriculaProva?.segundaNota ?? 0,
        finalNota: matriculaProva?.finalNota ?? 0,
      }),
    );
  };

  const handledListMatricula = {
    ...tableData,
    content: avaliacaoTableContent(),
  };

  return {
    lancamentoNota,
    provaSelected,
    idMatriculaSelected,
    handleLancarNota,
    onCloseModal,
    handledListMatricula,
  };
}
