import {
  DownOutlined,
  FullscreenOutlined,
  SearchOutlined,
  UpOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { ReactElement } from 'react';
import './_style.scss';
import { Tooltip } from 'antd';

const toolbar = () => {
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ZoomIn,
          ZoomOut,
          ShowSearchPopover,
          EnterFullScreen,
          ...rest
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div className="left-toolbar">
              <div className="container-toolbar-item">
                <ShowSearchPopover>
                  {(props) => (
                    <Tooltip title="Pesquisar" placement="top">
                      <button className="buttonToolbar" {...props}>
                        <SearchOutlined />
                      </button>
                    </Tooltip>
                  )}
                </ShowSearchPopover>
              </div>
              <div className="container-toolbar-item">
                <GoToPreviousPage>
                  {(props) => (
                    <Tooltip title="Página anterior (Alt + ↑)" placement="top">
                      <button
                        className="buttonToolbar"
                        {...props}
                        style={
                          props.isDisabled
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                      >
                        <UpOutlined style={{ padding: '0px 2px' }} />
                      </button>
                    </Tooltip>
                  )}
                </GoToPreviousPage>
              </div>
              <div
                className="container-toolbar-item"
                style={{ maxWidth: '40px' }}
              >
                <CurrentPageInput />
              </div>
              <div className="container-toolbar-item">/</div>
              <div className="container-toolbar-item">
                <NumberOfPages />
              </div>
              <div className="container-toolbar-item">
                <GoToNextPage>
                  {(props) => (
                    <Tooltip title="Próxima página (Alt + ↓)" placement="top">
                      <button
                        {...props}
                        className="buttonToolbar"
                        style={
                          props.isDisabled
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                      >
                        <DownOutlined style={{ padding: '0px 2px' }} />
                      </button>
                    </Tooltip>
                  )}
                </GoToNextPage>
              </div>
            </div>

            <div className="center-toolbar">
              <div className="container-toolbar-item">
                <ZoomOut>
                  {(props) => (
                    <Tooltip title="Diminuir zoom" placement="top">
                      <button className="buttonToolbar" {...props}>
                        <ZoomOutOutlined />
                      </button>
                    </Tooltip>
                  )}
                </ZoomOut>
              </div>
              <div className="container-toolbar-item">
                <CurrentScale>
                  {(props) => (
                    <span>{`${Math.round(props.scale * 100)}%`}</span>
                  )}
                </CurrentScale>
              </div>
              <div className="container-toolbar-item">
                <ZoomIn>
                  {(props) => (
                    <Tooltip title="Aumentar zoom" placement="top">
                      <button {...props} className="buttonToolbar">
                        <ZoomInOutlined color="black" />
                      </button>
                    </Tooltip>
                  )}
                </ZoomIn>
              </div>
            </div>
            <div className="right-toolbar">
              <div className="container-toolbar-item">
                <EnterFullScreen>
                  {(props) => (
                    <Tooltip title="Tela cheia" placement="top">
                      <button {...props} className="buttonToolbar">
                        <FullscreenOutlined />
                      </button>
                    </Tooltip>
                  )}
                </EnterFullScreen>
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  return { renderToolbar };
};

export default toolbar;
