import {
  IMaterialCleanDTO,
  IMaterialDTO,
  IMaterialFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useMaterialService = () => {
  const resourceURL = 'material';

  return {
    ...baseFindById<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(
      resourceURL,
    ),
    ...baseFindList<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(
      resourceURL,
    ),
    ...baseFind<IMaterialCleanDTO, IMaterialDTO, IMaterialFullDTO>(resourceURL),
  };
};
