import { Button } from 'antd';
import imgConfirmacao from '../../assets/img/confirmar/ant-design_question-circle-outlined.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import { IRequestDTO, IRespostaDTO } from '../../models/happy-code-api.model';
import { useAcaRespostaService } from '../../services/aca-resposta';
import { useNotificationService } from '../../services/notification.service';
import './style.css';
import { useUsuarioTrilhaService } from '../../services/usuario-trilha.service';

interface Props {
  open: boolean;
  onClose: () => void;
  alternativas: IRespostaDTO[];
  findResposta: () => void;
  idTrilha: string;
  usuario: any;
  nota: number;
  verificarSeAlunoPassou: () => void;
}

export const ModalConfirmacao = (props: Props) => {
  const notification = useNotificationService();
  const { setIsGlobalLoading } = useGlobalContext();
  const respostaService = useAcaRespostaService();
  const { open, onClose, alternativas, findResposta, idTrilha, usuario, nota, verificarSeAlunoPassou } = props;
  const usuarioTrilhaService = useUsuarioTrilhaService();


  const create = async () => {
    try {
      await respostaService.saveAll(alternativas);

      notification({
        description: `Avaliação salva com sucesso.`,
        type: 'success',
        message: 'Sucesso!',
      });

      findResposta();
    } catch (error) {
      notification({
        description: `Erro ao salvar avaliação: ${error.message}`,
        type: 'error',
        message: 'Erro!',
      });
    } finally {
      setIsGlobalLoading(false);
      onClose();
      verificarSeAlunoPassou();

    }
  };

  const findStatusAvaliacaoTrilha = async () => {
    setIsGlobalLoading(true)
    const body: IRequestDTO = {
      idTrilha: parseInt(idTrilha),
      idUsuario: usuario?.id,
      nota: nota,
    };
    await usuarioTrilhaService
      .finalizarProva(body)
      .finally(() => {
        create();
      });
  };

  if (!open) return null;

  return (
    <>
      <div className="d-flex-row j-center-a-center modal-container">
        <div className="d-flex-column j-start-a-center modal">
          <div
            className="d-flex-row j-center-a-center circle mb-10"
            style={{ backgroundColor: '#EBEFFC', padding: 16 }}
          >
            <img src={imgConfirmacao} alt="imgConfirmacao" />
          </div>
          <h1 className="mb-10">Deseja mesmo finalizar este questionário?</h1>
          <p>
            Verifique se todas as questões foram respondidas antes de finalizar
            o questionário.
          </p>
          <div className="line"></div>
          <div className="d-flex-row button-box w-100">
            <Button onClick={props.onClose} className="button-blue mr-5">
              Voltar e verificar
            </Button>
            <Button onClick={findStatusAvaliacaoTrilha} className="button-primary">
              Finalizar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
