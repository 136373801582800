import { useNavigate } from 'react-router-dom';
import {
  useAulaInformacaoBoxStore
} from '../store/use-aula-informacao-box.store';
import {
  conteudoMinistradoDataStore
} from '../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';

export default function useBackClassroomPage() {
  const navigate = useNavigate();

  const { aulaInformacaoBox } = useAulaInformacaoBoxStore();
  const { resetConteudoMinistradoData } = conteudoMinistradoDataStore();

  const backClassroomPage = () => {
    navigate(`/turmas/professor/detalhe/${aulaInformacaoBox?.turmaId}`);
    resetConteudoMinistradoData();
  };

  return { backClassroomPage };
}
