import { Form } from 'antd';

import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/sidebar/logo-grande.png';
import LogoGrande from '../../assets/img/login/logo.svg';
import People from '../../assets/img/login/img-people.png';

export const Email = () => {
  return (
    <>
      <div className="container-login">
        <img src={Logo} alt={'logo'} className="logo-login" />
        <img src={LogoGrande} alt={'logo'} className="img-layout" />
        <div className="formulario-box">
          <Form style={{ zIndex: 3 }}>
            <div className="formulario">
              <div className="arrow">
                <Link to="/login">
                  <Unicons.UilArrowLeft size="24" color="#6A7C96" />
                </Link>
                <span>Voltar</span>
              </div>
              <h3>E-mail enviado com sucesso!</h3>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant diam nibh id sollicitudin. Malesuada amet.
              </p> */}

              <span className="conta">Não recebeu o e-mail? </span>
              <span className="cadastro">Reenviar</span>
            </div>
          </Form>
        </div>
        <div className="container-blue"></div>
        <img src={People} alt="" className="img-people" />
      </div>
    </>
  );
};
