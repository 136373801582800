import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import http from '../../helpers/http-common';
import {
  IConteudoLearnCleanDTO,
  IConteudoLearnDTO,
  IConteudoLearnFullDTO,
  ICurriculoConteudoDTO,
} from '../../models/happy-code-api.model';
import { baseFindList } from '../base/base-find-list.service';

export const useLearnConteudoService = () => {
  const resourceUrl = '/learn/v1/tbconteudo';

  const fetchConteudoByIdCurriculo = useCallback(
    async (
      idCurriculo: number,
      idTipoConteudo: number,
    ): Promise<AxiosResponse<ICurriculoConteudoDTO[]>> =>
      await http.get<ICurriculoConteudoDTO[]>(
        `${resourceUrl}/conteudo-by-id-curriculo/${idCurriculo}/${idTipoConteudo}`,
      ),
    [resourceUrl],
  );

  return {
    ...baseFindList<
      IConteudoLearnCleanDTO,
      IConteudoLearnDTO,
      IConteudoLearnFullDTO
    >(resourceUrl),
    fetchConteudoByIdCurriculo,
  };
};
