import { useCallback } from 'react';
import { useAulaService } from '../../../../../services/aula.service';
import { useLocation, useParams } from 'react-router-dom';
import useEnumMapper from './use-convert-enum-to-number.hook';
import {
  listAccordionFormStore
} from '../../../store/list-accordion-form.store';
import {
  IRelatorioFrequenciaSkillDTO
} from '../../../../../models/happy-code-api.model';


export default function useFindRelatorioFrequenciaSkill() {
  const { id } = useParams();
  const { findRelatorioFrequenciaSkill } = useAulaService();
  const location = useLocation();
  const { relatorioFrequenciaSkillDtoToBaseFrequenciaSkillFormInterface } =
    useEnumMapper();

  const {
    setAccordionForm,
    setAccordionFormDisabled
  } = listAccordionFormStore();

  const handleRelatorioFrequenciaSkillData = useCallback(
    (data: IRelatorioFrequenciaSkillDTO, idMatricula: number) => {
      const convertedData =
        relatorioFrequenciaSkillDtoToBaseFrequenciaSkillFormInterface(data);

      setAccordionForm(idMatricula, convertedData);

      const hasCompetencias = convertedData.competencias.length > 0;
      const hasHabilidades = convertedData.habilidades.length > 0;
      const isEditPage = location.pathname.includes('edit');

      const isAccordionDisabled = !isEditPage && (hasCompetencias || hasHabilidades || convertedData.projetoLink);

      if (isAccordionDisabled)
        setAccordionFormDisabled(idMatricula);

    }, [
      location.pathname
    ]);

  const getRelatorioFrequenciaSkill = useCallback((idMatricula: number) => {
    if (!idMatricula) return;

    findRelatorioFrequenciaSkill(Number(id), idMatricula)
      .then(({ data }) =>
        handleRelatorioFrequenciaSkillData(data, idMatricula)
      );
  }, [findRelatorioFrequenciaSkill, id, handleRelatorioFrequenciaSkillData]);

  return {
    getRelatorioFrequenciaSkill
  };
}
