import { Select } from 'antd';
import './frequencia-section.styles.scss';
import useFrequenciaSection from './use-frequencia-section.hook';
import {
  turmaIsEnglishStore
} from '../../../Turmas/store/turma-is-english.store';
import {
  FrequenciaTipoEnum
} from '../../../../enum/lancamento-aula/frequencia-tipo.enum';
import {
  TarefaTipoEnum
} from '../../../../enum/lancamento-aula/tarefa-tipo.enum';
import { listAccordionFormStore } from '../../store/list-accordion-form.store';

interface FrequenciaSectionProps {
  idMatricula: number;
}

export default function FrequenciaSection({ idMatricula }: FrequenciaSectionProps) {
  const {
    frequenciaOption,
    tarefaOption,
    reforcoOption
  } = useFrequenciaSection();
  const {
    updateAccordionForm,
    findAccordionById
  } = listAccordionFormStore();
  const currentAccordion = findAccordionById(idMatricula);
  const { turmaIsEnglish } = turmaIsEnglishStore();

  return (
    <div className={'frequencia-section'}>
      <div className={'frequencia-section__select-input'}>
        <label className={'select-input__label'}>Presença</label>
        <Select
          onChange={(value: FrequenciaTipoEnum) =>
            updateAccordionForm(idMatricula, 'presenca', value)}
          className={'select-input__input'}
          options={frequenciaOption}
          disabled={currentAccordion?.disabled}
          value={currentAccordion?.form?.presenca}
          defaultValue={FrequenciaTipoEnum.PRESENTE}
        />
      </div>
      {turmaIsEnglish && (
        <>
          <div className={'frequencia-section__select-input'}>
            <label className={'select-input__label'}>Tarefa</label>
            <Select
              onChange={(value: TarefaTipoEnum) =>
                updateAccordionForm(idMatricula, 'frequenciaTarefa', value)}
              className={'select-input__input'}
              options={tarefaOption}
              disabled={currentAccordion?.disabled}
              value={currentAccordion?.form?.frequenciaTarefa}
            />
          </div>
          <div className={'frequencia-section__select-input'}>
            <label className={'select-input__label'}>Reforço</label>
            <Select
              onChange={(value: string) =>
                updateAccordionForm(idMatricula, 'reforco', value)}
              value={currentAccordion?.form?.reforco}
              className={'select-input__input'}
              options={reforcoOption}
              disabled={currentAccordion?.disabled}
            />
          </div>
        </>
      )}
    </div>
  );
}
