import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IAcaCursoCleanDTO,
  IAcaCursoDTO,
  IAcaCursoFullDTO,
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcaCursoService = () => {
  const resourceURL = 'aca-curso';

  const findCustom = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IAcaCursoDTO>>> =>
    http.get<PageableResponse<IAcaCursoDTO>>(
      resourceURL + `/curso-lista`,
      formatHttpParams(predicate),
    );

  return {
    ...baseFind<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
    ...baseFindById<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(
      resourceURL,
    ),
    ...baseFindList<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(
      resourceURL,
    ),
    ...baseDelete(resourceURL),
    findCustom,
  };
};
