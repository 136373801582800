import { Link } from "react-router-dom";
import logo from "../../assets/img/sidebar/logo-grande.png";
import { useGlobalContext } from "../../context/GlobalContext";
import { Avatar } from "../Avatar";
import "./style.css";

export default function Header() {
  const { usuario } = useGlobalContext();

  return (
    <header className="top-app-bar">
      <Link to={"/"}>
        <img src={logo} alt={"logo"} className="top-app-bar__logo" />
      </Link>

      <Link to="/usuarios/profile" className="top-app-bar__profile-box">
        <Avatar base64={usuario?.arquivo} size={30} />
        <div className="profile-box__name-type">
          <h6 className={"name-type__name"}>{usuario?.usuario}</h6>
          <span className={"name-type__type"}>{usuario?.perfil}</span>
        </div>
      </Link>
    </header>
  );
}
