import * as Unicons from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';
import './pastas-all.styles.scss';
import { IPastaDTO } from '../../../../../models/happy-code-api.model';

interface PastaProps {
  Pasta: IPastaDTO;
}

export const PastaContainer = ({ Pasta }: PastaProps) => {
  const navigate = useNavigate();
  const goTo = (url) => navigate(url);
  return (
    <div
      className="pasta-card-container"
      onClick={() => goTo(`/materiais-de-apoio/${Pasta?.id}/arquivos`)}
    >
      <div className="pasta-item">
        <Unicons.UilFolderOpen size={120}  />
        <h1>{Pasta?.nome}</h1>
      </div>
    </div>
  );
};
