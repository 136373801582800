import http from '../helpers/http-common';
import {
  IArquivoCleanDTO,
  IArquivoDTO,
  IArquivoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useArquivoService = () => {
  const resourceURL = 'arquivo';

  const download = (fileName: string, name: string) => {
    return http.get(`${resourceURL}/download/${fileName}?name=${name}`, {
      responseType: 'blob', // Define a resposta como um Blob (necessário para arquivos binários).
    });
  };

  return {
    ...baseFindList<IArquivoCleanDTO, IArquivoDTO, IArquivoFullDTO>(resourceURL),
    download,
  };
};
