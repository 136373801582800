import { useCallback } from 'react';
import {
  useLearnConteudoMinistradoService
} from '../../../services/learn/learn-conteudo-ministrado.service';
import { useParams } from 'react-router-dom';
import {
  conteudoMinistradoDataStore
} from '../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';

export const useFindConteudoAula = () => {
  const { findConteudoAula } = useLearnConteudoMinistradoService();
  const { id } = useParams();

  const {
    setConteudoMinistradoData
  } = conteudoMinistradoDataStore();

  const getConteudoAula = useCallback(async () => {
    if (!id) return;
    const idNumber = parseInt(id);
    await findConteudoAula(idNumber)
      .then(({ data }) =>
        setConteudoMinistradoData(data));
  }, [findConteudoAula, id]);

  return {
    getConteudoAula
  };
};
