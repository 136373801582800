import * as Unicons from '@iconscout/react-unicons';
import { Progress } from 'antd';
import Digital from '../../../../../assets/img/trilha/digital.svg';
import { PresentImage } from '../../../../../components/ApresentarImagem';
import { Avatar } from '../../../../../components/Avatar';
import {
  IAcaCursoFullDTO,
  ITrilhaFullDTO,
} from '../../../../../models/happy-code-api.model';
import useTrailCourseInfos from './use-trail-course-infos.hook';
import './trail-course-infos.styles.scss';

interface TrailCourseInfosProps {
  cursoData: IAcaCursoFullDTO;
  trilhaData: ITrilhaFullDTO;
  userProgress: number;
}

export default function TrailCourseInfos({
  cursoData,
  trilhaData,
  userProgress,
}: TrailCourseInfosProps) {
  const {
    classroomInfos: { quantidadeDeAulas, cargaHoraria },
  } = useTrailCourseInfos();

  const processEnvContainerAcademy =
    process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY;

  const thumbnailFromCourse = cursoData?.capaCurso
    ? cursoData?.capaCurso
    : processEnvContainerAcademy + cursoData?.arquivo?.url;

  const trilhaDataIcon = trilhaData?.icone
    ? trilhaData?.icone
    : processEnvContainerAcademy + trilhaData?.arquivoIcone?.url;

  return (
    <div className="trail-course-infos-wrapper">
      <PresentImage
        className="trail-course-infos-wrapper__course-image"
        link={thumbnailFromCourse}
      />

      <div className={'trail-course-infos-wrapper__course-infos'}>
        <div className={'course-infos__course-bio'}>
          <div className={'course-bio__trail-name'}>
            {!trilhaData?.arquivoIconeByte && !trilhaData?.icone && (
              <img src={Digital} alt="digital" />
            )}
            {trilhaData?.arquivoIconeByte && trilhaData?.icone && (
              <PresentImage className="foto-aula" link={trilhaDataIcon} />
            )}
            <h2 style={{ color: trilhaData.corNome1 }}>
              {trilhaData?.nome1}{' '}
              {trilhaData?.nome2 && (
                <span style={{ color: trilhaData.corNome2 }}>
                  {trilhaData?.nome2}
                </span>
              )}
            </h2>
            <hr className="trail-name__line-divisor" />
          </div>

          <h1 className="course-bio__trail-title">{cursoData?.nome}</h1>
          <p className="course-bio__trail-description">
            {cursoData?.descricao}
          </p>
          <div className="d-flex-row j-between-a-center w-60">
            <Progress
              percent={userProgress || 0}
              strokeColor="var(--primary-color)"
            />
            <div className="d-flex-row j-center-a-center progress-bar__icon">
              <Unicons.UilGraduationCap color="var(--white-color)" />
            </div>
          </div>
        </div>

        <div className={'course-infos__course-icon-infos'}>
          <div className={'course-icon-infos'}>
            <Unicons.UilClock color="var(--secondary-color)" />
            <div>
              <label className={'course-icon-infos__label'}>
                Carga horária
              </label>
              <p>
                {quantidadeDeAulas} aula(s) / {cargaHoraria}h
              </p>
            </div>
          </div>

          {/* <div
            className={'course-icon-infos'}
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
              paddingLeft: '32px',
              borderRight: '1px solid rgba(0, 0, 0, 0.06)',
              paddingRight: '32px',
            }}
          >
            <img src={Coins} alt="Happy Coins" className="coin-trilha" />
            <div>
              <label className={'course-icon-infos__label'}>Happy Coins</label>
              <p>{cursoData?.happyCoins}</p>
            </div>
          </div> */}

          <div className={'course-icon-infos'}>
            <Avatar arquivo={(cursoData?.usuarioInstrutor as any)?.arquivo} />
            <div>
              <label className={'course-icon-infos__label'}>
                Ministrado por
              </label>
              <p>{cursoData?.usuarioInstrutor?.pessoa?.nome}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
