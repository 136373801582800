import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IConectaCleanDTO,
  IConectaDTO,
  IConectaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcaConectaService = () => {
  const resourceURL = '/conecta';

  const editarArquivo = (
    conectaId: number,
    body: any,
  ): Promise<IAxiosResponseCustom<IConectaDTO>> =>
    http.patch(`${resourceURL}/${conectaId}`, body);

  const editarArquivoCustom = (
    conectaId: number,
    body: any,
  ): Promise<IAxiosResponseCustom<IConectaDTO>> =>
    http.patch(`${resourceURL}/custom/${conectaId}`, body);

  const findFullCustom = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IConectaFullDTO>>> =>
    http.get<PageableResponse<IConectaFullDTO>>(
      resourceURL + `/conecta-lista`,
      formatHttpParams(predicate),
    );

  return {
    ...baseFind<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
    ...baseFindList<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
    ...baseUpdate<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
    editarArquivo,
    editarArquivoCustom,
    findFullCustom,
  };
};
