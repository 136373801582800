import {
  IFeedbackWebinarCleanDTO,
  IFeedbackWebinarDTO,
  IFeedbackWebinarFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcaFeedbackWebinarService = () => {
  const resourceURL = 'aca-feedback-webinar';

  return {
    ...baseFindList<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
    ...baseFind<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
    ...baseFindList<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
    ...baseFindById<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
    ...baseCreate<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
    ...baseUpdate<
      IFeedbackWebinarCleanDTO,
      IFeedbackWebinarDTO,
      IFeedbackWebinarFullDTO
    >(resourceURL),
  };
};
