import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { PageableResponse } from '../../models/response.model';
import { IWebinarFullDTO } from '../../models/happy-code-api.model';
import { useAcaWebinarService } from '../../services/aca-webinar';
import { Predicate } from '../../models/predicate.model';
import { Operators } from '../../enum/operators.enum';

export default function useWebinarsAll() {
  const { setIsGlobalLoading, usuario } = useGlobalContext();

  const [pageNumber, setPageNumber] = useState<number | null>(null);

  const [search, setSearch] = useState('');

  const addPredicateOptions = useCallback(() => {
    const predicate = new Predicate();

    predicate.removeAllOption();
    predicate.size = 16;

    predicate.addOption('ativo', true);

    if (search) predicate.addOption('nome', search, Operators.CONTAINS);

    if (pageNumber) predicate.setPage(pageNumber);

    return predicate;
  }, [pageNumber, search]);

  const [webinarsPage, setWebinarsPage] =
    useState<PageableResponse<IWebinarFullDTO>>();

  const webinarsService = useAcaWebinarService();

  const findWebinar = useCallback(async () => {
    if (!usuario?.id) return;

    setIsGlobalLoading(true);

    const predicate = addPredicateOptions();

    const { acessoB2c, acessoB2b, acessoComercial } = usuario;

    webinarsService
      .findFullCustom(acessoB2c, acessoB2b, acessoComercial, predicate)
      .then(({ data }) => setWebinarsPage(data))
      .finally(() => setIsGlobalLoading(false));
  }, [addPredicateOptions, setIsGlobalLoading, usuario]);

  useEffect(() => {
    const controller = new AbortController();

    findWebinar();

    return () => controller.abort();
  }, [findWebinar]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    setPageNumber(page);

    findWebinar();
  };

  const webinarTitle = webinarsPage?.totalElements || 0;

  return {
    webinarsPage,
    search,
    setSearch,
    onPageChange,
    webinarTitle,
  };
}
