import { Sidebar } from '../../components/Sidebar';
import { Button, Input } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import { Tag } from '../../components/Tag';

export const MentoriasDetalhe = () => {
  const { TextArea } = Input;

  return (
    <>
      <div className="container">
        <Sidebar mentorias={true} />
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Link to="/mentorias/historico">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
          </div>
          <div className="d-flex-column j-center-a-center pb-50 w-100">
            <div id="box-chat" className="d-flex-column j-start-a-start w-80">
              <div className="d-flex-row j-start-a-center mb-10">
                <h1 className="mr-10">Mentoria</h1>
                <h1 className="mr-10">|</h1>
                <div className="d-flex-row j-start-a-center box-data mr-20">
                  <span>SEG</span>
                  <h6>8:00 - 12:00</h6>
                </div>
                <div className="box-tag">
                  <Tag name="Happy Speech" estilo="happy-speech" />
                </div>
              </div>
              <h2 className="mb-10">Mentor</h2>
              <div className="d-flex-row j-start-a-center w-100 box-mentor-chat mb-10">
                <img src="/assets/img/mentores/mentor1.png" alt="mentor" />
                <p>Maria Antônia Paz</p>
              </div>
              <h2 className="mb-10">Link da sala</h2>
              <div className="= w-100 box-mentor-chat mb-10">
                <h2>Skill</h2>
                <a href="/">http://www.saladamentoria.com.br</a>
              </div>
              <h2 className="mb-10">Chat com Mentor</h2>
              <div className="= w-100 box-mentor-chat mb-10">
                <div className="d-flex-row j-start-a-center w-100 box-mentor-message mb-10">
                  <img src="/assets/img/mentores/mentor1.png" alt="mentor" />
                  <div>
                    <div className="message">
                      Sagittis posuere justo eget nunc.
                    </div>
                    <p>11:43</p>
                  </div>
                </div>
                <div className="d-flex-row j-end-a-center w-100 box-mentor-message mb-20">
                  <div className="d-flex-column j-end-a-end w-50">
                    <div className="message-user">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Urna purus gravida et ac molestie amet, laoreet odio
                      ornare. Cras nibh convallis sagittis pulvinar a
                      ullamcorper.
                    </div>
                    <p>11:43</p>
                  </div>
                </div>
                <div className="w-100 box-mentor-chat">
                  <TextArea
                    bordered={false}
                    defaultValue="Digite sua mensagem"
                    rows={4}
                  />
                  <Unicons.UilPaperclip
                    className="clip"
                    color="var(--secondary-color"
                    size={20}
                  />
                </div>
                <div className="d-flex-row j-end-a-center mb-20">
                  <h6>0/230</h6>
                </div>
                <div className="d-flex-row j-end-a-center mb-20">
                  <Button className="button-blue mr-10">Limpar</Button>
                  <Button className="button-primary">Enviar</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
