import { Button } from 'antd';
import ConteudoMinistradoForm
  from '../../../create/step-1/components/conteudo-ministrado-form/conteudo-ministrado-form.component';
import '../../lancamento-aula-edit.styles.scss';
import useConteudoMinistradoWrapper
  from './use-conteudo-ministrado-wrapper.hook';

export default function ConteudoMinistradoWrapper() {
  const {
    formObject,
    toggleFormDisabled
  } = useConteudoMinistradoWrapper();

  return (
    <div className={'lancamento-aula-edit-wrapper'}>
      <div className={'lancamento-aula-edit-wrapper__title-button'}>
        <h2>Conteúdo Ministrado</h2>
        <Button className={'button-second'} onClick={toggleFormDisabled}>
          Editar
        </Button>
      </div>

      <ConteudoMinistradoForm {...formObject} />
    </div>
  );
}
