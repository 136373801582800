import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { IConectaFullDTO } from '../../models/happy-code-api.model';
import { PageableResponse } from '../../models/response.model';
import { useAcaConectaService } from '../../services/aca-conecta';
import { Predicate } from '../../models/predicate.model';
import { Operators } from '../../enum/operators.enum';

export default function useConectasAll() {
  const { setIsGlobalLoading } = useGlobalContext();

  const [search, setSearch] = useState('');

  const [page, setPage] = useState<number | null>(null);

  const addPredicatesOnQuery = useCallback(() => {
    const predicate = new Predicate();

    predicate.removeAllOption();
    predicate.size = 8;

    if (search) predicate.addOption('nome', search, Operators.CONTAINS);

    if (page) predicate.setPage(page);

    predicate.addOption('ativo', true);

    return predicate;
  }, [page, search]);

  const conectaService = useAcaConectaService();

  const [conectasPage, setConectasPage] =
    useState<PageableResponse<IConectaFullDTO>>();

  const findConecta = useCallback(async () => {
    setIsGlobalLoading(true);

    const predicate = addPredicatesOnQuery();

    conectaService
      .findFullCustom(predicate)
      .then(({ data }) => setConectasPage(data))
      .finally(() => setIsGlobalLoading(false));
  }, [addPredicatesOnQuery, setIsGlobalLoading]);

  useEffect(() => {
    const controller = new AbortController();

    findConecta();

    return () => controller.abort();
  }, [findConecta]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    setPage(page);

    findConecta();
  };

  const conectaTitle = `Conectas (${conectasPage?.totalElements || 0})`;

  return {
    conectasPage,
    search,
    setSearch,
    onPageChange,
    conectaTitle,
  };
}
