import {
  ICurriculoFullDTO,
  ICursoFullDTO,
  IProdutoEscolaFullDTO,
} from '../../../../models/happy-code-api.model';

interface FindOutWhatDataToUse {
  descricao: string;
  negocio: string;
  duracao: number;
  quantidadeMaterial: number;
}

export default function useProductTypeTable() {
  const findOutWhatDataToUse = (
    data?: ICursoFullDTO | ICurriculoFullDTO | IProdutoEscolaFullDTO,
  ): FindOutWhatDataToUse => {
    if (data && 'nivelAcesso' in data) {
      const curso = data as ICursoFullDTO;

      return {
        quantidadeMaterial: curso.cursoAulaPadraos.length,
        descricao: curso.descricao,
        negocio: curso.negocio?.descricao,
        duracao: curso.duracaoMinutos / 60,
      };
    }

    if (data && 'descricaoCurriculo' in data) {
      const curriculo = data as ICurriculoFullDTO;

      return {
        quantidadeMaterial: curriculo.cursoAulaPadraos.length,
        descricao: curriculo.descricaoCurriculo,
        negocio: curriculo.negocio?.descricao,
        duracao: curriculo.quantidadeAulas,
      };
    }

    if (data && 'faixaEtariaIni' in data) {
      const produto = data as IProdutoEscolaFullDTO;
      console.log(produto);

      return {
        quantidadeMaterial: produto.cursoAulaPadraos?.length,
        descricao: produto.descricao,
        negocio: produto.negocio?.descricao,
        duracao: produto.anoEscolar,
      };
    }
  };

  return {
    findOutWhatDataToUse,
  };
}
