import ContainerWrapper
  from '../../../../../components/ContainerWrapper/container-wrapper.component';
import Header from '../../../../../components/Header';
import { Button } from 'antd';
import Title from '../../../../../components/title/title.component';
import { CloseOutlined } from '@ant-design/icons';
import useLancamentoAulaStep2 from './use-lancamento-aula-step-2.hook';
import StepsBox from '../../../components/steps-box/steps-box.component';
import '../lancamento-aula.styles.scss';
import useBackClassroomPage from '../../../hooks/use-back-classroom-page.hook';
import AulaInformacaoBox
  from '../../../components/aula-informacao-box/aula-informacao-box.component';
import ConteudoAulaBox
  from './components/conteudo-aula-box/conteudo-aula-box.component';
import StudentAccordion
  from '../../../components/student-accordion/student-accordion.component';

export default function LancamentoAulaStep2() {
  const { backClassroomPage } = useBackClassroomPage();
  const {
    relatorioFrequencias,
    form
  } = useLancamentoAulaStep2();

  return (
    <>
      <Header />
      <ContainerWrapper hasHeader>
        <div className={'lancamento-aula__title-button'}>
          <Title title={'Frequências & Skills'} />
          <Button
            onClick={backClassroomPage}
            className="button-second"
            style={{
              width: '40px',
              height: '40px'
            }}
            icon={<CloseOutlined />}
          />
        </div>

        <div className={'lancamento-aula-wrapper'}>
          <div
            className={
              'lancamento-aula-wrapper__aula-information-steps-container'
            }
          >
            <AulaInformacaoBox />
            <ConteudoAulaBox />
            <StepsBox current={1} title={'Lançar Frequências e Skills'} />
          </div>

          <div
            className={
              'lancamento-aula-wrapper__title-conteudo-ministrado-form'
            }
          >
            {relatorioFrequencias.map((relatorioFrequencia, index) => (
              <StudentAccordion
                relatorio={relatorioFrequencia}
                key={index}
                form={form}
              />
            ))}
          </div>
        </div>
      </ContainerWrapper>
    </>
  );
}
