import { useMemo } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import dayjs from 'dayjs';

dayjs.locale('pt-br');

export default function useUserWelcomeCard() {
  const { usuario, happyCoins } = useGlobalContext();

  const saudacao = useMemo(
    () => dayjs().format('dddd, DD [de] MMMM [de] YYYY'),
    [],
  );

  const username = usuario?.usuario || 'Usuário';

  return {
    saudacao,
    username,
  };
}
