import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Predicate } from '../../../models/predicate.model';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useTrilhaService } from '../../../services/trilha.service';
import {
  ITrilhaCleanDTO,
  ITrilhaFullDTO,
  IUnidadeDTO,
} from '../../../models/happy-code-api.model';
import { PageableResponse } from '../../../models/response.model';
import { Operators } from '../../../enum/operators.enum';
import { useUnidadeService } from '../../../services/unidade.service';

export default function useAllCursos() {
  const { usuario, setIsGlobalLoading, unidade } = useGlobalContext();

  const [searchValue, setSearchValue] = useState('');

  const [trilhaFilter, setTrilhaFilter] = useState<ITrilhaCleanDTO>();

  const unidadeService = useUnidadeService();

  const addPredicateGenericOptions = useCallback(
    (page?: number): Predicate => {
      const predicate = new Predicate();

      predicate.addOption('ativo', true);
      predicate.addOption('cursos.ativo', true);

      if (page) predicate.setPage(page);

      if (searchValue)
        predicate.addOption('cursos.nome', searchValue, Operators.CONTAINS);

      return predicate;
    },
    [searchValue],
  );
  
  const findTipoUnidadeByUnidadeId = useCallback(async () => {
    setIsGlobalLoading(true);
    return  unidadeService.findById({ id: unidade?.id })
        .then(({ data }) => {
            setIsGlobalLoading(false);
        return data.tipoUnidade.id
        })
        .catch(() => {
            setIsGlobalLoading(false);
            return undefined;
        });
}, [unidade]);

  const updateTipoUnidadeId = useCallback(async () => {
   const tipoUnidadeId = await findTipoUnidadeByUnidadeId();
   const predicate = addPredicateGenericOptions();

    Promise.all([
      findTrilhasFilter(predicate, tipoUnidadeId),
      findPaginatedTrilhas(predicate, tipoUnidadeId),
    ]);
  },[searchValue, trilhaFilter])

  const trilhaService = useTrilhaService();

  const [trilhasData, setTrilhasData] = useState<ITrilhaCleanDTO[]>();
  const findTrilhasFilter = useCallback(
    async (predicate: Predicate, idUnidade:number) => {
      if (!usuario) return;

      const { acessoB2c, acessoB2b, acessoComercial } = usuario;

      if (trilhaFilter) predicate.addOption('id', trilhaFilter.id);

      await trilhaService
        .findTrilhaFilterCustom(
          acessoB2c,
          acessoB2b,
          acessoComercial,
          idUnidade,
          predicate
        )
        .then(({ data }) => setTrilhasData(data));
    },
    [setTrilhasData, trilhaFilter, usuario, setIsGlobalLoading],
  );

  const [paginatedTrilhas, setPaginatedTrilhas] =
    useState<PageableResponse<ITrilhaFullDTO>>();

  const findPaginatedTrilhas = useCallback(
    async (predicate: Predicate, tipoUnidadeid:number) => {
      if (!usuario) return;

      const { acessoB2c, acessoB2b, acessoComercial } = usuario;

      await trilhaService
        .findFullCustom(acessoB2c, acessoB2b, acessoComercial,tipoUnidadeid, predicate)
        .then(({ data }) => setPaginatedTrilhas(data));
    },
    [setPaginatedTrilhas, usuario, setIsGlobalLoading],
  );

  useEffect(() => {
    if (unidade?.id) {
      updateTipoUnidadeId();
    }
  }, [searchValue, unidade?.id, updateTipoUnidadeId]);

  const onPageChange = async (event: ChangeEvent<unknown>, page: number) => {
    const tipoUnidadeId = await findTipoUnidadeByUnidadeId();
    event.preventDefault();

    const predicate = addPredicateGenericOptions(page);

    findPaginatedTrilhas(predicate, tipoUnidadeId);
  };

  return {
    trilhasData,
    setSearchValue,
    searchValue,
    paginatedTrilhas,
    onPageChange,
    trilhaFilter,
    setTrilhaFilter,
  };
}
