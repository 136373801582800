import * as Unicons from '@iconscout/react-unicons';
import { Avatar } from '../Avatar';
import 'dayjs/locale/pt-br';
import './user-welcome-card.styles.scss';
import useUserWelcomeCard from './use-user-welcome-card.hook';

const UserWelcomeCard = () => {
  const { saudacao, username } = useUserWelcomeCard();

  return (
    <div className="user-welcome-card">
      <h6 className="user-welcome-card__saudation">{saudacao}</h6>
      <div className="user-welcome-card__hello-name">
        <h2 className="hello-name__hello">Olá, </h2>
        <h2>{username}</h2>
        {/* <span style={{ color: "var(--blue-gray)" }}>|</span> */}
      </div>
      {/* <Avatar base64={usuario?.arquivo} size={80} /> */}

      {/* <Unicons.UilBell color="var(--dark)" style={{ marginTop: 7 }} /> */}
      {/* <div className="d-flex-row j-start-a-center medal">
              <Unicons.UilMedal color="var(--dark)" />
              <h6>MEU NÍVEL: </h6>
              <div className="position">nv.I - Instrutor iniciante</div>
            </div> */}
      {/* <div className="coins">
        <h6>MEUS HAPPYCOINS</h6>
        <div className="d-flex-row j-start-a-center">
          <img src={Coin} alt="" />
          <h1>{happyCoins}</h1>
        </div>
        <p>Como obter mais <a href="/">happycoins?</a></p>
      </div> */}
    </div>
  );
};

export default UserWelcomeCard;
