import http from '../helpers/http-common';
import {
  ICurriculoCleanDTO,
  ICurriculoDTO,
  ICurriculoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCurriculoService = () => {
  const resourceURL = 'curriculo';

  return {
    ...baseFind<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(
      resourceURL,
    ),
    ...baseFindList<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(
      resourceURL,
    ),
    ...baseFindById<ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO>(
      resourceURL,
    ),
    createFull: (body): Promise<IAxiosResponseCustom<ICurriculoCleanDTO>> =>
      http.post(`${resourceURL}/full`, body),
    patch: (toUpdate: any): Promise<IAxiosResponseCustom<ICurriculoCleanDTO>> =>
      http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
    patchFull: (
      toUpdate: any,
    ): Promise<IAxiosResponseCustom<ICurriculoCleanDTO>> =>
      http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
  };
};
