import { FormEvent, useCallback, useEffect, useState } from 'react';
import { IMatriculaProvaCleanDTO } from '../../models/happy-code-api.model';
import { useGlobalContext } from '../../context/GlobalContext';
import { useMatriculaProvaService } from '../../services/matricula-prova.service';
import { useNotificationService } from '../../services/notification.service';

type NotasDasMatriculas = Omit<
  IMatriculaProvaCleanDTO,
  'id' | 'finalNota' | 'idMatricula' | 'ativo'
>;

interface useHandleModalProvaProps {
  open: boolean;
  onClose: () => void;
  prova: IMatriculaProvaCleanDTO;
  idMatricula: number;
}

export default function useHandleModalProva({
  open,
  onClose,
  prova,
  idMatricula,
}: useHandleModalProvaProps) {
  const { setIsGlobalLoading } = useGlobalContext();
  const matriculaProvaService = useMatriculaProvaService();
  const notification = useNotificationService();
  const [notas, setNotas] = useState<NotasDasMatriculas>();

  const handleInputChange = (value: number, key: keyof NotasDasMatriculas) =>
    setNotas({
      ...notas,
      [key]: value,
    });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSend();
  };

  const onSend = async () => {
    setIsGlobalLoading(true);

    await matriculaProvaService
      .enviarNota({
        id: prova?.id || null,
        idMatricula,
        ativo: true,
        ...notas,
        primeiraNota: mediaTerm1,
        segundaNota: mediaTerm2,
        finalNota: mediaFinal,
      })
      .then(() => {
        notification({
          description: 'Nota enviada!',
          message: 'Sucesso!',
        });
      })
      .catch(() =>
        notification({
          description: 'Erro ao enviar nota!',
          type: 'error',
          message: 'Erro!',
        }),
      )
      .finally(() => {
        setIsGlobalLoading(false);
        onClose();
      });
  };

  const calcularMediaTerm = (notas: number[]): number => {
    const notasValidas = notas.filter((nota) => nota !== undefined);
    const tamanhoDasNotas = notasValidas.length;

    if (tamanhoDasNotas === 0) return 0;

    const somaNotas = notasValidas.reduce((acc, nota) => acc + nota, 0);

    const media = ((somaNotas / tamanhoDasNotas) * 5).toFixed(2);

    return Number(media);
  };

  const mediaTerm1 = calcularMediaTerm([
    notas?.notaPrimeiraPC1S,
    notas?.notaPrimeiraPC1W,
    notas?.notaPrimeiraPE1,
  ]);

  const mediaTerm2 = calcularMediaTerm([
    notas?.notaSegundaPE2,
    notas?.notaSegundaPC2L,
    notas?.notaSegundaPC2Lang,
    notas?.notaSegundaPC2R,
    notas?.notaSegundaPC2S,
    notas?.notaSegundaPC2W,
  ]);

  const mediaFinal = parseInt((mediaTerm1 + mediaTerm2).toFixed(2));

  const handleOverflow = useCallback(() => {
    document.documentElement.style.overflow = open ? 'hidden' : 'auto';
    document.body.style.overflow = open ? 'hidden' : 'auto';
  }, [open]);

  const preencherValoresDasNotas = useCallback(() => {
    if (!prova) return;

    setNotas({
      ...prova,
    });
  }, [prova]);

  const resetarValoresDasNotas = () =>
    setNotas({
      primeiraNota: 0,
      segundaNota: 0,
      notaPrimeiraPE1: 0,
      notaPrimeiraPC1S: 0,
      notaPrimeiraPC1W: 0,
      notaSegundaPE2: 0,
      notaSegundaPC2S: 0,
      notaSegundaPC2L: 0,
      notaSegundaPC2R: 0,
      notaSegundaPC2Lang: 0,
      notaSegundaPC2W: 0,
    });

  useEffect(() => {
    resetarValoresDasNotas();
    handleOverflow();
    preencherValoresDasNotas();

    return () => {
      handleOverflow();
      preencherValoresDasNotas();
    };
  }, [handleOverflow, preencherValoresDasNotas]);

  return {
    handleInputChange,
    handleSubmit,
    mediaTerm1,
    mediaTerm2,
    mediaFinal,
  };
}
