import {
  SelectOptionInterface
} from '../../../../interfaces/lancamento-aula/step-2/select-option.interface';
import SkillAssessmentSelectOption
  from '../select-options/skill-assessment-select-option.component';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../../enum/lancamento-aula/habilidade-competencia-escala.enum';
import { listAccordionFormStore } from '../../store/list-accordion-form.store';

interface useSkillAssessmentSectionHookProps {
  isHabilidade?: boolean;
  idMatricula: number;
}

export const useSkillAssessmentSectionHook = ({
  isHabilidade,
  idMatricula
}: useSkillAssessmentSectionHookProps) => {
  const { updateAccordionForm, findAccordionById } = listAccordionFormStore();

  const SKILL_FIELD = 'habilidades';
  const COMPETENCE_FIELD = 'competencias';

  const habilidadeCompetenciaOption: SelectOptionInterface[] = [
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.PRECISA_MELHORAR} />,
      value: HabilidadeCompetenciaEscalaEnum.PRECISA_MELHORAR
    },
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.NEUTRO} />,
      value: HabilidadeCompetenciaEscalaEnum.NEUTRO
    },
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.OTIMO} />
      ,
      value: HabilidadeCompetenciaEscalaEnum.OTIMO

    }
  ];

  const handleOnChange = (id: number, nome: string, value: number) => {
    const valueToHandle = { id, nome, value };

    const fieldName = isHabilidade ? SKILL_FIELD : COMPETENCE_FIELD;

    updateAccordionForm(idMatricula, fieldName, [valueToHandle]);
  };

  const currentAccordion = findAccordionById(idMatricula);
  const isPresencaAusente = currentAccordion?.form?.presenca === 'AST';
  const disabled = !!(isPresencaAusente || currentAccordion?.disabled);

  const findValue = (id: number, nome: string) => {
    if (isPresencaAusente) return;

    const field = isHabilidade ? currentAccordion?.form?.habilidades : currentAccordion?.form?.competencias;

    return field?.find((item) =>
      item.id === id && item.nome === nome);
  };

  return {
    handleOnChange,
    habilidadeCompetenciaOption,
    disabled,
    findValue
  };
};
