import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress, Steps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TipoTopicoAula } from '../../enum/tipo-topico-aula.enum';
import {
  IAcaAulaDTO,
  ITopicoAulaDTO,
  IUsuarioCursoCleanDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAcaAulaService } from '../../services/aca-aula.service';
import { useNotificationService } from '../../services/notification.service';
import { useTopicoAulaService } from '../../services/topico-aula.service';
import { useUsuarioTopicoAulaService } from '../../services/usuario-topico-aula.service';
import { AulaAvaliacao } from './avaliacao';
import { AulaClassificacao } from './aula-classificacao';
import TopicoHtmlComponent from './components/topico-html/topico-html.component';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../components/title/title.component';

export const Aula = () => {
  const { Step } = Steps;
  const { id } = useParams();
  const [aula, setAula] = useState<IAcaAulaDTO>();
  const [proximaAula, setProximaAula] = useState<IAcaAulaDTO>();
  const [usuarioCurso, setUsuarioCurso] = useState<IUsuarioCursoCleanDTO>();
  const acaAulaService = useAcaAulaService();
  const topicoAulaService = useTopicoAulaService();
  const { confirmarVisualizacaoTopico } = useUsuarioTopicoAulaService();
  const [topicos, setTopicos] = useState<ITopicoAulaDTO[]>();
  const [topicoAtivo, setTopicoAtivo] = useState<ITopicoAulaDTO>();
  const navigate = useNavigate();
  const notification = useNotificationService();
  const [avaliacaoResposta, setAvaliacaoResposta] = useState<boolean>();
  const [feedbackRespondido, setFeedbackRespondido] = useState<boolean>();

  useEffect(() => {
    if (!aula?.curso) return;
    acaAulaService
      .findProximaAula(aula?.id, aula?.curso?.id)
      .then((response) => {
        setProximaAula(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aula]);

  useEffect(() => {
    const parseId = parseInt(id);

    if (id && !topicos && !aula) {
      acaAulaService.findById({ id: parseId }).then((response) => {
        setAula(response.data);
      });
      const predicate = new Predicate();
      predicate.sort = 'asc';
      predicate.addOption('aula.id', id);
      predicate.addSort('ordem');
      predicate.addOption('ativo', true);
      topicoAulaService.findList(predicate).then((response) => {
        const topicos = response.data?.sort((a, b) => {
          if (a.numeroTopicoAula < b.numeroTopicoAula) return -1;
          if (a.numeroTopicoAula > b.numeroTopicoAula) return 1;
          return 0;
        });
        setTopicos(topicos);
        setTopicoAtivo(topicos?.[0]);

        if (topicos?.length > 0)
          return confirmarVisualizacaoTopico(topicos?.[0]?.id).then(
            ({ data }) => setUsuarioCurso(data),
          );
        notification({
          message: 'Nenhum tópico encontrado',
          type: 'warning',
        });
        navigate(-1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const resetDisabled = () => {
    setFeedbackRespondido(false);
    setAvaliacaoResposta(false);
  };

  const renderConteudo = useMemo(() => {
    if (!topicoAtivo) return <></>;

    const tipo = TipoTopicoAula[topicoAtivo.tipoTopicoAula];

    if ([TipoTopicoAula.APRESENTACAO, TipoTopicoAula.CONTEUDO].includes(tipo)) {
      resetDisabled();
      return <TopicoHtmlComponent topico={topicoAtivo} />;
    }

    if ([TipoTopicoAula.AVALIACAO].includes(tipo)) {
      return (
        <AulaAvaliacao
          topico={topicoAtivo}
          setAvaliacaoResposta={setAvaliacaoResposta}
        />
      );
    }

    if ([TipoTopicoAula.FEEDBACK].includes(tipo)) {
      resetDisabled();
      return (
        <AulaClassificacao
          topico={topicoAtivo}
          proximaAula={proximaAula}
          aula={aula}
          setFeedbackRespondido={setFeedbackRespondido}
        />
      );
    }
  }, [topicoAtivo, proximaAula, aula, avaliacaoResposta, feedbackRespondido]);

  const isPossuiProximoTopico = useMemo(() => {
    try {
      const indexAtual = topicos.findIndex(
        (item) => item.id === topicoAtivo.id,
      );
      return topicos[indexAtual + 1];
    } catch (e) {
      return null;
    }
  }, [topicoAtivo, topicos]);

  const isPossuiTopicoAnterior = useMemo(() => {
    try {
      const indexAtual = topicos.findIndex(
        (item) => item.id === topicoAtivo.id,
      );
      return topicos[indexAtual - 1];
    } catch (e) {
      return null;
    }
  }, [topicoAtivo, topicos]);

  const onProximoTopicoClick = () => {
    const proximoTopico = isPossuiProximoTopico;

    if (!!proximoTopico) {
      setTopicoAtivo(proximoTopico);

      confirmarVisualizacaoTopico(proximoTopico.id).then(({ data }) =>
        setUsuarioCurso(data),
      );
    }
  };

  const onTopicoAnteriorClick = () => {
    const topicoAnterior = isPossuiTopicoAnterior;
    if (!!topicoAnterior) {
      setTopicoAtivo(topicoAnterior);
    }
  };

  const onVoltarTela = () => navigate(-1);

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Button className="button-second mr-15" onClick={onVoltarTela}>
              <Unicons.UilArrowLeft />
              Voltar
            </Button>

            <Title title={`${topicoAtivo?.descricao || 'Aula'}`} />
          </div>

          <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
            <div
              id="box-aula-white"
              style={{ width: '69%' }}
              className="box-white"
            >
              <div style={{ width: '100%', height: 'auto' }}>
                {renderConteudo}
              </div>
            </div>

            <div
              id="box-aula-white-right"
              className="box-white"
              style={{ width: '29%' }}
            >
              <div className="d-flex-row j-between-a-start mb-10">
                <h4>{aula?.descricao}</h4>
              </div>

              <h5 className="mb-10">Tópicos dessa aula</h5>
              <div className="steps mb-10">
                <Steps progressDot current={1} direction="vertical">
                  {topicos?.map?.((topico, index) => {
                    const isTopicoAtual = topicoAtivo?.id === topico.id;
                    const indexTopicoAtivo = topicos.indexOf(topicoAtivo);

                    return (
                      <Step
                        key={index}
                        className={`mb-10 ${
                          isTopicoAtual
                            ? 'box-step-ative'
                            : index < indexTopicoAtivo
                              ? 'box-step-concluido'
                              : 'box-step-disable'
                        }`}
                        style={{ cursor: 'pointer' }}
                        description={topico.descricao}
                      />
                    );
                  })}
                </Steps>
              </div>

              <Button
                style={{ width: '100%' }}
                className={`button-${
                  feedbackRespondido ||
                  avaliacaoResposta ||
                  isPossuiProximoTopico
                    ? 'primary'
                    : 'inative'
                } mb-10`}
                disabled={
                  feedbackRespondido ||
                  avaliacaoResposta ||
                  !isPossuiProximoTopico
                }
                onClick={onProximoTopicoClick}
              >
                Próximo tópico{' '}
                <Unicons.UilArrowRight color="var(--white-color)" size={20} />
              </Button>

              <Button
                style={{ width: '100%' }}
                className={`button-${
                  isPossuiTopicoAnterior ? 'primary' : 'inative'
                } mb-20`}
                disabled={!isPossuiTopicoAnterior}
                onClick={onTopicoAnteriorClick}
              >
                Voltar tópico
              </Button>
              <h5 className="mb-10">Progresso do Curso</h5>
              <div className="d-flex-row j-start-a-center">
                <Progress
                  strokeColor="var(--primary-color)"
                  percent={usuarioCurso?.percentualConclusao || 0}
                />
                <div className="d-flex-row j-center-a-center circle-gray">
                  <Unicons.UilGraduationCap color="var(--white-color)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
