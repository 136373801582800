import './product-type-table-header.styles.scss';

interface ProductTypeTableHeaderProps {
  tableHeaderText: string;
}

export default function ProductTypeTableHeader({
  tableHeaderText,
}: ProductTypeTableHeaderProps) {
  return (
    <ul className="product-type-table-header">
      <li style={{ width: '33%' }}>Nome do Curso</li>
      <li style={{ width: '15%' }}>Negócio</li>
      <li style={{ width: '15%' }}>{tableHeaderText}</li>
      <li style={{ width: '15%' }}>Capítulos Disponíveis</li>
    </ul>
  );
}
