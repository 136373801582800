import { useEffect } from 'react';
import { useTurmaContext } from '../../../../../context/TurmaContext';

export default function useRemoveTurmaOnAppear() {
  const { removeTurma } = useTurmaContext();

  useEffect(() => {
    removeTurma();
  }, []);
}
