import { Button, Divider } from "antd"
import { AnosFerramentas, Ferramenta, ferramentasB2B } from "./data/ferramentas-b2b"
import Style from './ferramentas-b2b.module.css'

export default function FerramentasB2B() {
  const data = ferramentasB2B

return(
    <div>
      {data && data.map((ferramentas:AnosFerramentas) => (
        <div key={ferramentas.ano} className={Style.container}>
        <Divider><h1>{ferramentas.ano}</h1></Divider>
          <ul>
            {ferramentas.ferramentas.map((ferramenta:Ferramenta) => (
             <div key={ferramenta.id} className={Style.itemContainer}>
              <hr/>
               <li className={Style.item}>
                <img onClick={() => window.open(ferramenta.link)} src={ferramenta.img} alt={ferramenta.img} />
                <p>{ferramenta.nome}</p>
                <Button onClick={() => window.open(ferramenta.link)}>Acessar</Button>
              </li>
             </div>
            ))}
          </ul>
        </div>
      ))}
    </div>
)
}