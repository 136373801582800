import { useParams } from 'react-router-dom';
import { useAulaService } from '../../../services/aula.service';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useNotificationService } from '../../../services/notification.service';
import useEnumMapper
  from '../components/student-accordion/hooks/use-convert-enum-to-number.hook';
import useFindRelatorioFrequenciaSkill
  from '../components/student-accordion/hooks/use-find-relatorio-frequencia-skill.hook';
import { listAccordionFormStore } from '../store/list-accordion-form.store';
import { FormEvent } from 'react';
import {
  ICreateFrequenciaSkillDTO
} from '../../../models/happy-code-api.model';
import {
  FrequenciaTipoEnum
} from '../../../enum/lancamento-aula/frequencia-tipo.enum';
import { AxiosResponse } from 'axios';
import {
  IListAccordionForm
} from '../interfaces/list-accordion-form.interface';

interface UseGenericFrequenciaSkillFormHookProps {
  isEdit?: boolean;
}

export const useGenericFrequenciaSkillFormHook = ({
                                                    isEdit
                                                  }: UseGenericFrequenciaSkillFormHookProps) => {
  const { id } = useParams();
  const aulaService = useAulaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const notificationService = useNotificationService();
  const { frequenciaTarefa, frequenciaTipo } = useEnumMapper();
  const { getRelatorioFrequenciaSkill } = useFindRelatorioFrequenciaSkill();

  const {
    setAccordionFormDisabled,
    openNextAccordion,
    resetAccordionForm
  } = listAccordionFormStore();

  const submitForm = (event: FormEvent<HTMLFormElement>, accordion: () => IListAccordionForm) => {
    event.preventDefault();
    setIsGlobalLoading(true);

    const currentAccordion = accordion();
    const idMatricula = currentAccordion.id;

    if (!currentAccordion?.form) return;

    const payload = buildPayload(currentAccordion, currentAccordion.id);

    const serviceMethod = isEdit
      ? aulaService.updateRelatorioFrequenciaSkill
      : aulaService.createRelatorioFrequenciaSkill;

    handleSubmit(serviceMethod, payload, idMatricula, isEdit);
  };

  const handleSubmit = (
    serviceMethod: (payload: ICreateFrequenciaSkillDTO) => Promise<AxiosResponse<any, any>>,
    payload: ICreateFrequenciaSkillDTO,
    idMatricula: number,
    isEdit: boolean
  ) => {
    const successMessage = isEdit
      ? 'Frequência atualizada com sucesso!'
      : 'Frequência lançada com sucesso!';

    const errorMessage = isEdit
      ? 'Erro ao atualizar frequência!'
      : 'Erro ao lançar frequência!';


    serviceMethod(payload)
      .then(() => processSuccess(successMessage, idMatricula))
      .catch(() => notificationService({
        type: 'error',
        message: errorMessage
      }))
      .finally(() => setIsGlobalLoading(false));
  };

  const processSuccess = (message: string, idMatricula: number) => {
    setAccordionFormDisabled(idMatricula);
    notificationService({ type: 'success', message });
    openNextAccordion(idMatricula);
    resetAccordionForm(idMatricula);
    getRelatorioFrequenciaSkill(idMatricula);
  };

  const buildPayload = (currentAccordion: IListAccordionForm, idMatricula: number): ICreateFrequenciaSkillDTO => ({
    idAula: parseInt(id),
    idMatricula,
    frequenciaDTO: buildFrequenciaDTO(currentAccordion),
    alunoHabilidadeDTO: mapSkillsToDto(currentAccordion),
    alunoCompetenciaDTO: mapCompetencesToDto(currentAccordion),
    projetoDTO: {
      link: currentAccordion.form.projetoLink,
      numeroNota: currentAccordion.form.projetoValue
    }
  });

  const buildFrequenciaDTO = (currentAccordion: IListAccordionForm) => ({
    idFrequenciaTipo: frequenciaTipo.toNumber(currentAccordion.form.presenca),
    idFrequenciaTarefa: frequenciaTarefa.toNumber(currentAccordion.form.frequenciaTarefa),
    frequencia: currentAccordion.form.presenca !== FrequenciaTipoEnum.AUSENTE,
    reforco: currentAccordion.form.reforco === 'TRUE'
  });

  const mapSkillsToDto = (currentAccordion: IListAccordionForm) =>
    currentAccordion?.form?.habilidades?.map(({ id, value }) => ({
      idHabilidade: id,
      numeroNota: value
    })) || [];

  const mapCompetencesToDto = (currentAccordion: IListAccordionForm) =>
    currentAccordion?.form?.competencias?.map(({ id, value }) => ({
      idCompetencia: id,
      numeroNota: value
    })) || [];

  return {
    submitForm
  };
};
