import { ChangeEvent, useCallback, useState } from "react";
import { PageableResponse } from "../../../models/response.model";
import { IAvaliacaoTableDTO } from "../../../models/happy-code-api.model";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useMatriculaProvaService } from "../../../services/matricula-prova.service";
import { useParams } from "react-router-dom";
import { Predicate } from "../../../models/predicate.model";
import { turmaIsEnglishStore } from "../store/turma-is-english.store";

export default function useFindAvaliacaoTable() {
  const { id } = useParams();
  const { setIsGlobalLoading } = useGlobalContext();
  const service = useMatriculaProvaService();

  const { turmaIsEnglish } = turmaIsEnglishStore();

  const [avaliacaoTableData, setAvaliacaoTableData] =
    useState<PageableResponse<IAvaliacaoTableDTO>>();

  const buildPredicate = useCallback((page?: number) => {
    const predicate = new Predicate();

    if (page) predicate.setPage(page);

    return predicate;
  }, []);

  const findAvaliacaoTable = useCallback(
    async (page?: number) => {
      if (!id || !turmaIsEnglish) return;

      const idNumber = parseInt(id);
      const predicate = buildPredicate(page);

      await service
        .getAvaliacaoTable(idNumber, predicate)
        .then(({ data }) => setAvaliacaoTableData(data));
    },
    [id, turmaIsEnglish, buildPredicate],
  );

  const onPageChangeMatricula = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    setIsGlobalLoading(true);
    findAvaliacaoTable(page).finally(() => setIsGlobalLoading(false));
  };

  return {
    avaliacao: {
      data: avaliacaoTableData,
      function: findAvaliacaoTable,
      onPageChange: onPageChangeMatricula,
    },
  };
}
