import "./status-turma.css";
import useStatusTurma from "./useStatusTurma";

interface StatusTurmaProps {
  dataInicio: Date;
  dataFim: Date;
}

export default function StatusTurma({ dataInicio, dataFim }: StatusTurmaProps) {
  const { dotColor, statusText } = useStatusTurma({ dataInicio, dataFim });

  return (
    <div className="status-turma">
      <div className={dotColor}></div>
      <p className={statusText === 'Em andamento' ? 'ativo' : 'inativo'}>
        {statusText}
      </p>
    </div>
  );
}
