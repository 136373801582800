import {
  ITipoSkillCleanDTO,
  ITipoSkillDTO,
  ITipoSkillFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
import http from './../helpers/http-common';
import { AxiosResponse } from 'axios';

export const useTipoSkillService = () => {
  const resourceURL = 'tipo-skill';

  const findTipoSkillNaoPreenchida = (
    idAluno: number,
  ): Promise<AxiosResponse<ITipoSkillDTO[]>> =>
    http.get<ITipoSkillDTO[]>(resourceURL + `/aluno/${idAluno}`);

  return {
    ...baseFind<ITipoSkillCleanDTO, ITipoSkillDTO, ITipoSkillFullDTO>(
      resourceURL,
    ),
    ...baseFindList<ITipoSkillCleanDTO, ITipoSkillDTO, ITipoSkillFullDTO>(
      resourceURL,
    ),
    ...baseCreate<ITipoSkillCleanDTO, ITipoSkillDTO, ITipoSkillFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<ITipoSkillCleanDTO, ITipoSkillDTO, ITipoSkillFullDTO>(
      resourceURL,
    ),
    ...baseDelete(resourceURL),
    findTipoSkillNaoPreenchida,
  };
};
