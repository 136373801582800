import { create } from 'zustand';
import { IUsuarioCursoKpiCustomDTO } from '../../models/happy-code-api.model';

export interface UserKpiStore {
  userKpi: IUsuarioCursoKpiCustomDTO;
  setUserKpi: (userKpi: IUsuarioCursoKpiCustomDTO) => void;
}

const kpiDefaultValue: IUsuarioCursoKpiCustomDTO = {
  quantidadeAulasAssistidas: 0,
  quantidadeCursosAndamento: 0,
  quantidadeCursosFinalizados: 0,
  quantidadeTrilhasConcluidas: 0,
};

export const useUserKpiStore = create<UserKpiStore>((set) => ({
  userKpi: kpiDefaultValue,
  setUserKpi: (userKpi) => set({ userKpi }),
}));
