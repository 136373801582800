import dayjs from 'dayjs';
import {
  boxTurmaInformacaoStore
} from '../../store/box-turma-informacao.store';

export default function useBoxDataTurmaDetalhe() {
  const {
    boxTurmaInformacao: {
      quantidadeAulasRealizadas,
      dataInicio,
      dataFim,
      duracaoCompletada,
      quantidadeAlunos,
      duracaoTotal,
      curriculo,
    },
  } = boxTurmaInformacaoStore();

  const totalAulasRealizadasLabel =
    quantidadeAulasRealizadas > 1 ? 'aulas realizadas' : 'aula realizada';

  const getDataInicio = dayjs(dataInicio).format('DD/MM/YYYY');
  const getDataFim = dayjs(dataFim).format('DD/MM/YYYY');
  const duracaoEmDias = dayjs(dataFim).diff(dataInicio, 'day');
  const labelDuracao = duracaoEmDias > 1 ? 'dias' : 'dia';

  const labelAlunos =
    quantidadeAlunos > 1 ? 'alunos matriculados' : 'aluno matriculado';

  const labelMinutos = duracaoTotal > 1 ? 'minutos' : 'minuto';
  const curriculoTurma = curriculo?.descricaoCurriculo;

  return {
    totalAulasRealizadas: quantidadeAulasRealizadas,
    totalAulasRealizadasLabel,
    getDataInicio,
    getDataFim,
    duracaoEmDias,
    labelDuracao,
    labelAlunos,
    duracaoCompletada,
    labelMinutos,
    curriculoTurma,
    curriculo,
  };
}
