import { useCallback, useEffect, useState } from 'react';
import { ICursoDTO } from '../../../../../models/happy-code-api.model';
import { useCursoService } from '../../../../../services/curso.service';

export default function useFindListOfCursos() {
  const { findList } = useCursoService();

  const [listOfCursos, setListOfCursos] = useState<ICursoDTO[]>(null);

  const findListOfCursos = useCallback(async (): Promise<void> => {
    await findList().then(({ data }) => setListOfCursos(data));
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    findListOfCursos();

    return () => controller.abort();
  }, [findListOfCursos]);

  return {
    listOfCursos,
  };
}
