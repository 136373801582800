import { useParams } from 'react-router-dom';
import useFetchClassroomInfosByCourseId from '../../../../../hooks/aula/use-fetch-classroom-infos-by-course-id.hook';

export default function useTrailCourseInfos() {
  const { idCurso } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const { classroomInfos } = useFetchClassroomInfosByCourseId({
    courseId: idCurso,
  });

  return {
    classroomInfos,
  };
}
