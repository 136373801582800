import { create } from "zustand";
import {
  IBoxTurmaInformacaoDTO,
  ICurriculoCleanDTO,
} from '../../../models/happy-code-api.model';

const defaultBoxTurmaInformacao: IBoxTurmaInformacaoDTO = {
  id: 0,
  dataFim: new Date(),
  dataInicio: new Date(),
  aulaTimeList: [],
  duracaoCompletada: 0,
  duracaoTotal: 0,
  nome: '',
  porcentagemAulasRealizadas: 0,
  quantidadeAlunos: 0,
  quantidadeAulas: 0,
  quantidadeAulasRealizadas: 0,
  curriculo: {} as ICurriculoCleanDTO,
};

type Store = {
  boxTurmaInformacao: IBoxTurmaInformacaoDTO;
  setBoxTurmaInformacao: (listTurma: IBoxTurmaInformacaoDTO) => void;
};

export const boxTurmaInformacaoStore = create<Store>((set) => ({
  boxTurmaInformacao: defaultBoxTurmaInformacao,
  setBoxTurmaInformacao: (boxTurmaInformacao) => set({ boxTurmaInformacao }),
}));
