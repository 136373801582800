import axios, { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import {
  IAlunoCleanDTO,
  IAlunoCustomDTO,
  IAlunoCustomIndexDTO,
  IAlunoDTO,
  IAlunoFullDTO,
  IAlunosTableDTO,
} from "../models/happy-code-api.model";
import { formatHttpParams, Predicate } from "../models/predicate.model";
import { PageableResponse } from "../models/response.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useAlunoService = () => {
  const resourceURL = "aluno";
  let ajaxRequest = null;

  const findCustomById = (
    id: number,
  ): Promise<AxiosResponse<IAlunoCustomDTO>> =>
    http.get<IAlunoCustomDTO>(resourceURL + `/custom/${id}`);

  const findCustom = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IAlunoCustomIndexDTO>>> => {
    // cancel  previous ajax if exists
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    ajaxRequest = axios.CancelToken.source();
    const { token: cancelToken } = ajaxRequest;
    return http.get<PageableResponse<IAlunoCustomIndexDTO>>(
      resourceURL + `/custom/`,
      { ...formatHttpParams(predicate), cancelToken },
    );
  };

  const findAlunosTable = (
    idTurma: number,
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IAlunosTableDTO>>> =>
    http.get(
      `${resourceURL}/alunos-table/${idTurma}`,
      formatHttpParams(predicate),
    );

  return {
    ...baseCreate<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
    ...baseFind<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
    ...baseFindById<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
    ...baseUpdate<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
    findCustomById,
    findCustom,
    findAlunosTable,
  };
};
