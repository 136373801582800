import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import {
  useNotificationService
} from '../../../../services/notification.service';
import { listAccordionFormStore } from '../../store/list-accordion-form.store';
import {
  SelectOptionInterface
} from '../../../../interfaces/lancamento-aula/step-2/select-option.interface';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../../enum/lancamento-aula/habilidade-competencia-escala.enum';
import SkillAssessmentSelectOption
  from '../select-options/skill-assessment-select-option.component';

interface UseUploadContainerProps {
  idMatricula: number;
}

export default function useUploadContainer({ idMatricula }: UseUploadContainerProps) {
  const notification = useNotificationService();
  const { updateAccordionForm, findAccordionById } = listAccordionFormStore();

  const onChange = ({ file }: UploadChangeParam) => {
    const { response: fileUrl, status } = file;

    if (status === 'done')
      return handleSuccess(fileUrl);

    if (status === 'removed')
      return updateAccordionForm(idMatricula, 'projetoLink', fileUrl);

    if (status === 'error')
      return handleError();
  };

  const handleSuccess = (fileUrl: string) => {
    notification({
      description: 'Arquivo carregado com sucesso.',
      type: 'success',
      message: 'Sucesso!'
    });
    updateAccordionForm(idMatricula, 'projetoLink', fileUrl);
  };

  const handleError = () => {
    notification({
      description: `Erro ao enviar arquivo`,
      type: 'error',
      message: 'Erro!'
    });
  };

  const beforeUpload = (file: UploadFile) => {
    const FIVE_MEGABYTES_IN_BYTES = 5 * 1024 * 1024;

    if (file.size > FIVE_MEGABYTES_IN_BYTES) {
      file.status = 'error';

      return notification({
        description: 'O tamanho do arquivo deve ser inferior a 5MB.',
        type: 'error',
        message: 'Erro!'
      });
    }

    if (file.status === 'error')
      return notification({
        description: `Erro ao enviar arquivo: ${file.error} `,
        type: 'error',
        message: 'Erro!'
      });

  };

  const projectScaleOptions: SelectOptionInterface[] = [
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.PRECISA_MELHORAR} />,
      value: HabilidadeCompetenciaEscalaEnum.PRECISA_MELHORAR
    },
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.NEUTRO} />,
      value: HabilidadeCompetenciaEscalaEnum.NEUTRO
    },
    {
      label: <SkillAssessmentSelectOption
        value={HabilidadeCompetenciaEscalaEnum.OTIMO} />,
      value: HabilidadeCompetenciaEscalaEnum.OTIMO
    }
  ];

  const selectValue = findAccordionById(idMatricula)?.form?.projetoValue;

  const projectLinkValue = (): UploadFile => {
    const projetoLink = findAccordionById(idMatricula)?.form?.projetoLink;

    if (!projetoLink)
      return null;

    const name = projetoLink.split('/').pop() || '';

    return {
      uid: '1',
      name,
      status: 'done',
      url: projetoLink
    };
  };

  const currentAccordion = findAccordionById(idMatricula);
  const isPresencaAusente = currentAccordion?.form?.presenca === 'AST';
  const disabled = !!(isPresencaAusente || currentAccordion?.disabled);

  return {
    upload: {
      before: beforeUpload,
      onChange
    },
    projectScaleOptions,
    form: {
      selectValue,
      projectLinkValue: projectLinkValue(),
      disabled: disabled
    }
  };
}
