import useFindRelatorioFrequencias
  from '../../../../hooks/use-find-relatorio-frequencias.hook';
import { useEffect } from 'react';
import {
  useGenericFrequenciaSkillFormHook
} from '../../../../hooks/use-generic-frequencia-skill-form.hook';

export default function useStudentSkillWrapper() {
  const { relatorioFrequencias, getRelatorioFrequencias } =
    useFindRelatorioFrequencias();
  const { submitForm } = useGenericFrequenciaSkillFormHook({
    isEdit: true
  });

  useEffect(() => {
    const controller = new AbortController();

    getRelatorioFrequencias();

    return () => controller.abort();
  }, [getRelatorioFrequencias]);

  return {
    relatorioFrequencias,
    form: {
      submit: submitForm
    }
  };
}
