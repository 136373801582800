import useFindTurmaData from './hooks/use-find-turma-data.hook';
import useFindTurmasKpi from './hooks/use-find-turmas-kpi.hook';
import useRemoveTurmaOnAppear from './hooks/use-remove-turma-on-appear.hook';
import useFindListOfCursos from './hooks/use-find-list-of-cursos.hook';
import useFindListOfModalidades from './hooks/use-find-list-of-modalidades.hook';
import useHandleInputsFilters from './hooks/use-handle-inputs-filters.hook';
import { ChangeEvent } from 'react';
import { useTurmasPredicateStore } from '../../../../store/turmas/turmas-predicate.store';
import useTableColumns from './hooks/use-table-columns.hook';
import { ITurmaDTO } from '../../../../models/happy-code-api.model';
import { useNavigate } from 'react-router-dom';

export default function useTurmas() {
  useRemoveTurmaOnAppear();

  const { turmasKpi } = useFindTurmasKpi();

  const { listOfCursos } = useFindListOfCursos();
  const { listOfModalidades } = useFindListOfModalidades();

  const { inputValuesObject, handleInputsFiltersObject } =
    useHandleInputsFilters();

  const { findTurmaData } = useFindTurmaData({
    inputValuesObject,
  });

  const { turmasPredicate } = useTurmasPredicateStore();
  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    turmasPredicate.setPage(page);
    findTurmaData();
  };

  const { tableColumns } = useTableColumns();

  const navigate = useNavigate();
  const goToDetalhe = (turma: ITurmaDTO) =>
    navigate('/turmas/professor/detalhe/' + turma?.id);

  return {
    listOfCursos,
    turmasKpi,
    listOfModalidades,
    inputValuesObject,
    handleInputsFiltersObject,
    tableColumns,
    goToDetalhe,
    onPageChange,
  };
}
