import { Checkbox } from 'antd';
import * as Unicons from '@iconscout/react-unicons';

interface Props {
  check: boolean;
  inicio: string;
  fim: string;
}

export const Horario = (props: Props) => {
  const { check, inicio, fim } = props;

  return (
    <>
      <div className="d-flex-row j-between-a-center horario">
        <div className={`d-flex-row j-center-a-center checkbox-${check}`}>
          <Checkbox checked={check} />
        </div>
        <div className="horario-box">
          <h4 className="mb-5">Horário de início</h4>
          <div className="d-flex-row">
            <Unicons.UilClock color="var(--dark-secondary)" />
            <h6 className="ml-10">{inicio}</h6>
          </div>
        </div>
        <span> - </span>
        <div className="horario-box">
          <h4 className="mb-5">Horário de fim</h4>
          <div className="d-flex-row">
            <Unicons.UilClock color="var(--dark-secondary)" />
            <h6 className="ml-10">{fim}</h6>
          </div>
        </div>
      </div>
    </>
  );
};
