import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../context/GlobalContext";
import { getLocalStorageItem, removeKey, setPrimitive } from "../../../../util/store";
import { useUsuarioTrilhaService } from "../../../../services/usuario-trilha.service";
import { useEffect } from "react";

export const useVerifyUserAvaliacao = () => {
  const { usuario } = useGlobalContext();
  const {idTrilha} = useParams<any>();
  const usuarioTrilhaService = useUsuarioTrilhaService();

  const updateLocalStorageStateAvaliacao =  async () => {
    const aprovado = getLocalStorageItem('aprovado');
    if(aprovado !== null) {
      removeKey('aprovado');
    }
    await usuarioTrilhaService.verificarUsuarioTrilha( parseInt(idTrilha), usuario?.id === null ? 0 : usuario.id ).then(({ data }) => {
      if (!data) return 
      if (data.aprovado) {
        setPrimitive('aprovado', 'true');
      }
      else{
        setPrimitive('aprovado', 'false');
      }
    })
  }


  useEffect(() => {
    updateLocalStorageStateAvaliacao();
  }, [usuario]);

 
}
