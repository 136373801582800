import {
  BaseFrequenciaSkillFormInterface
} from '../interfaces/base-frequencia-skill-form.interface';
import { create } from 'zustand';
import {
  IRelatorioFrequenciaSkillsAulaDTO
} from '../../../models/happy-code-api.model';
import {
  FrequenciaTipoEnum
} from '../../../enum/lancamento-aula/frequencia-tipo.enum';
import { TarefaTipoEnum } from '../../../enum/lancamento-aula/tarefa-tipo.enum';
import {
  HabilidadeCompetenciaOption
} from '../interfaces/habilidade-competencia-option.interface';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../enum/lancamento-aula/habilidade-competencia-escala.enum';
import {
  OnChangeFrequenciaSkillInterface
} from '../interfaces/on-change-frequencia-skill.interface';
import produce from 'immer';
import {
  IListAccordionForm
} from '../interfaces/list-accordion-form.interface';

interface ListAccordionFormState {
  listAccordionForm: IListAccordionForm[];
  initializeListAccordionForm: (relatorio: IRelatorioFrequenciaSkillsAulaDTO[]) => void;
  toggleAccordion: (id: number) => void;
  findAccordionById: (id: number) => IListAccordionForm | undefined;
  openNextAccordion: (id: number) => void;
  updateAccordionForm: OnChangeFrequenciaSkillInterface;
  setAccordionFormDisabled: (id: number) => void;
  resetAccordionForm: (id: number) => void;
  setAccordionForm: (id: number, form: BaseFrequenciaSkillFormInterface) => void;
}

export const listAccordionFormStore = create<ListAccordionFormState>((set, get) => ({
  listAccordionForm: null,
  initializeListAccordionForm: (relatorio) => {
    if (!relatorio) return;

    const listAccordionForm: IListAccordionForm[] = relatorio.map(({
                                                                     idMatricula,
                                                                     frequenciaTipo,
                                                                     frequenciaTarefa,
                                                                     hasReforco,
                                                                     habilidadesList,
                                                                     competenciasList
                                                                   }) => ({
      id: idMatricula,
      form: {
        presenca: frequenciaTipo as FrequenciaTipoEnum,
        frequenciaTarefa: frequenciaTarefa !== undefined
          ? frequenciaTarefa as TarefaTipoEnum
          : undefined,
        reforco: hasReforco !== undefined
          ? (hasReforco ? 'Sim' : 'Não')
          : undefined,
        habilidades: habilidadesList?.map((habilidade) => ({
          id: habilidade.id,
          nome: habilidade.nome,
          value: HabilidadeCompetenciaEscalaEnum.NEUTRO
        })) as HabilidadeCompetenciaOption[],
        competencias: competenciasList?.map((competencia) => ({
          id: competencia.id,
          nome: competencia.nome,
          value: HabilidadeCompetenciaEscalaEnum.NEUTRO
        })) as HabilidadeCompetenciaOption[]
      },
      isOpen: false,
      disabled: false
    }));

    set({ listAccordionForm });
  },
  toggleAccordion: (id) => {
    set((state) => ({
      listAccordionForm: state.listAccordionForm.map((accordion) =>
        accordion.id === id
          ? { ...accordion, isOpen: !accordion.isOpen }
          : accordion
      )
    }));
  },
  findAccordionById: (id) => {
    const { listAccordionForm } = get();
    return listAccordionForm?.find((accordion) => accordion.id === id);
  },
  updateAccordionForm: (id, field, value) => {
    set((state) => {
      const updateOptions = (
        prevOptions: HabilidadeCompetenciaOption[] | undefined,
        newOptions: HabilidadeCompetenciaOption[]
      ): HabilidadeCompetenciaOption[] => {
        const updatedOptions = prevOptions ? [...prevOptions] : [];

        newOptions.forEach((option) => {
          const existingIndex = updatedOptions.findIndex((opt) => opt.id === option.id);

          if (existingIndex > -1) {
            updatedOptions[existingIndex] = option;
          } else {
            updatedOptions.push(option);
          }
        });

        return updatedOptions;
      };

      const updatedAccordionList = state.listAccordionForm.map((accordion) => {
        if (accordion.id === id) {
          if (field === 'habilidades' || field === 'competencias') {
            const updatedField = updateOptions(
              accordion.form[field] as HabilidadeCompetenciaOption[],
              value as HabilidadeCompetenciaOption[]
            );

            return {
              ...accordion,
              form: {
                ...accordion.form,
                [field]: updatedField
              }
            };
          }

          return {
            ...accordion,
            form: {
              ...accordion.form,
              [field]: value
            }
          };
        }
        return accordion;
      });

      return { listAccordionForm: updatedAccordionList };
    });
  },
  openNextAccordion: (id) => {
    set(produce((state) => {
      const currentIndex = state.listAccordionForm.findIndex((accordion: IListAccordionForm) => accordion.id === id);
      if (currentIndex !== -1 && currentIndex < state.listAccordionForm.length - 1) {
        state.listAccordionForm[currentIndex].isOpen = false;
        state.listAccordionForm[currentIndex + 1].isOpen = true;
      }
    }));
  },
  setAccordionFormDisabled: (id) => {
    set(produce((state) => {
      const accordionIndex = state.listAccordionForm?.findIndex((accordion) => accordion.id === id);
      if (accordionIndex !== -1) {
        state.listAccordionForm[accordionIndex].disabled = true;
      }
    }));
  },
  resetAccordionForm: (id) => {
    set(produce((state) => {
      const accordionIndex = state.listAccordionForm?.findIndex((accordion: IListAccordionForm) => accordion.id === id);
      if (accordionIndex !== -1)
        state.listAccordionForm[accordionIndex].form = null;
    }));
  },
  setAccordionForm: (id, form) => {
    set(produce((state) => {
      const accordionIndex = state.listAccordionForm?.findIndex((accordion: IListAccordionForm) => accordion.id === id);

      if (accordionIndex !== -1)
        state.listAccordionForm[accordionIndex].form = form;
    }));
  }
}));
