import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useAcaFeedbackService } from '../../services/aca-feedback';
import { Predicate } from '../../models/predicate.model';
import {
  IAcaAulaDTO,
  IFeedbackCleanDTO,
  IFeedbackDTO,
  ITopicoAulaDTO,
} from '../../models/happy-code-api.model';
import { useNavigate } from 'react-router-dom';
import { PageableResponse } from '../../models/response.model';
import { Form } from 'antd';
import Emoji1 from '../../assets/img/classificacao/emoji1.svg';
import Emoji2 from '../../assets/img/classificacao/emoji2.svg';
import Emoji3 from '../../assets/img/classificacao/emoji3.svg';
import Emoji4 from '../../assets/img/classificacao/emoji4.svg';

type FeedbackRangeContent = {
  image: string;
  content: string;
};

interface useAulaClassificacoProps {
  topico: ITopicoAulaDTO;
  proximaAula: IAcaAulaDTO;
  aula: IAcaAulaDTO;
  setFeedbackRespondido: Dispatch<SetStateAction<boolean>>;
}

export default function useAulaClassificaco({
  topico,
  proximaAula,
  aula,
  setFeedbackRespondido,
}: useAulaClassificacoProps) {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const predicate = new Predicate();
  const feedbackService = useAcaFeedbackService();
  const [feedbackAvaliado, setFeedbackAvaliado] =
    useState<PageableResponse<IFeedbackCleanDTO>>();

  const findFeedbackAvaliado = useCallback(async (): Promise<void> => {
    if (!aula?.id || !usuario?.id) return;

    setIsGlobalLoading(true);

    predicate.addOption('aula.id', aula.id);
    predicate.addOption('usuario.id', usuario.id);

    await feedbackService
      .findClean(predicate)
      .then(({ data }) => {
        setFeedbackAvaliado(data);
        setFeedbackRespondido(data.content?.length > 0);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsGlobalLoading(false));
  }, [aula, setIsGlobalLoading, setFeedbackRespondido, usuario]);

  useEffect(() => {
    findFeedbackAvaliado();
  }, [findFeedbackAvaliado]);

  const navigate = useNavigate();

  const onProximaAulaClick = () => navigate(-1);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModalOpen = () => setIsOpen(!isOpen);

  const feedbackAvaliadoExist =
    feedbackAvaliado && feedbackAvaliado.content?.length > 0;

  const [feedback, setFeedback] = useState<IFeedbackDTO>();

  const submitAulaClassificacao = (_: any[], allFields: any[]) => {
    const [feedbackNumeroNps, feedbackTextArea] = allFields;

    setFeedback((prevFeedback: IFeedbackDTO) => ({
      ...prevFeedback,
      numeroNps: feedbackNumeroNps.value,
      descricaoFeedback: feedbackTextArea.value,
      aula: { ...prevFeedback?.aula, id: topico?.aula?.id },
      usuario: { ...prevFeedback?.usuario, id: usuario?.id },
    }));
  };

  const feedbackRangeContent: FeedbackRangeContent[] = [
    {
      image: Emoji1,
      content: 'Muito satisfeito',
    },
    {
      image: Emoji2,
      content: 'Satisfeito',
    },
    {
      image: Emoji3,
      content: 'Pouco satisfeito',
    },
    {
      image: Emoji4,
      content: 'Totalmente insatisfeito',
    },
  ];

  const feedbackCardIsActived = (index: number): string =>
    feedback && feedback.numeroNps === index && 'box-radio-select';

  const [aulaClassificacaoForm] = Form.useForm();

  return {
    feedback,
    feedbackRangeContent,
    isOpen,
    feedbackAvaliadoExist,
    onProximaAulaClick,
    toggleModalOpen,
    feedbackCardIsActived,
    aulaClassificacaoForm,
    submitAulaClassificacao,
  };
}
