import { useGlobalContext } from "../../../../../context/GlobalContext";
import { IMaterialApoioDTO } from "../../../../../models/happy-code-api.model";
import { useArquivoService } from "../../../../../services/arquivo-service";
import { mimeTypes } from "./filesType";


export default function UseArquivoMaterialApoioHook () {
  const arquivoService = useArquivoService();
  const {setIsGlobalLoading} = useGlobalContext()
  

  const handleDownload = (MaterialApoio: IMaterialApoioDTO) => {
    const fileExtension = MaterialApoio?.arquivo?.codigo.split('.').slice(-1)[0];
  
    const mimeType = mimeTypes[fileExtension.toLowerCase()] || 'application/octet-stream';
  
    setIsGlobalLoading(true);
  
    arquivoService
      .download(MaterialApoio?.arquivo?.url, MaterialApoio?.arquivo.codigo)
      .then((response) => {
        const data = new Blob([response.data], { type: mimeType });
        const fileURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', MaterialApoio?.descricao || 'arquivo');
        tempLink.click();
  
        window.URL.revokeObjectURL(fileURL);
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };

  
  
  return {handleDownload}
} 