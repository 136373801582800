import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import { useGlobalContext } from '../../context/GlobalContext';
import {
  IFeedbackWebinarCleanDTO,
  IWebinarCleanDTO,
} from '../../models/happy-code-api.model';
import { useAcaWebinarService } from '../../services/aca-webinar';
import { useAcaFeedbackWebinarService } from '../../services/aca-feedback-webinar';
import { Predicate } from '../../models/predicate.model';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

function WebinarApresentacao() {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const navigate = useNavigate();
  const webinarService = useAcaWebinarService();
  const feedbackWebinarService = useAcaFeedbackWebinarService();
  const [webinar, setWebinar] = useState<IWebinarCleanDTO>();
  const [feedback, setFeedback] = useState<IFeedbackWebinarCleanDTO[]>();
  const [predicate] = useState<Predicate>(new Predicate());
  const { idWebinar } = useParams<any>();

  const findWebinar = () => {
    setIsGlobalLoading(true);

    const parseIdWebinarToNumber = parseInt(idWebinar);

    webinarService
      .findCleanById({ id: parseIdWebinarToNumber })
      .then((response) => {
        setWebinar(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const findFeedbackWebinar = () => {
    setIsGlobalLoading(true);
    predicate.addOption('webinar.id', idWebinar);
    predicate.addOption('usuario.id', usuario?.id);
    feedbackWebinarService
      .findCleanList(predicate)
      .then((response) => {
        setFeedback(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onVoltarTela = () => navigate(`/webinars/all`);

  const goToAdd = () => navigate(`/webinars/feedback/${idWebinar}`);

  useEffect(() => {
    findWebinar();
    findFeedbackWebinar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarAndContainer>
      <div className="dashboard">
        <div className="d-flex-row j-start-a-center mb-20">
          <Button className="button-blue" onClick={onVoltarTela}>
            <Unicons.UilArrowLeft />
            Voltar
          </Button>
          <h1 className="fav-title">{webinar?.nome}</h1>
        </div>
        <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
          <div id="box-aula-white" className="box-white w-70">
            <p
              className="mb-20"
              style={{ overflowWrap: 'anywhere' }}
              dangerouslySetInnerHTML={{ __html: webinar?.conteudo }}
            ></p>

            {feedback?.length <= 0 && (
              <div className="d-flex-row j-end-a-center">
                <Button className="button-primary" onClick={goToAdd}>
                  Avaliar webinar
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidebarAndContainer>
  );
}

export default WebinarApresentacao;
