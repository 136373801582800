import { useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import useFindBoxTurmaInformacao from "./hooks/use-find-box-turma-informacao.hook";
import useFindAlunosTable from "./hooks/use-find-alunos-table.hook";
import useFindAulasByTurmaId from "./hooks/use-find-aulas-by-turma-id.hook";
import useFindAvaliacaoTable from "./hooks/use-find-avaliacao-table.hook";
import useCheckIfTurmaIsHappyEnglish from "./hooks/use-check-if-turma-is-happy-english.hook";

export default function useTurmaDetalhe() {
  const { setIsGlobalLoading } = useGlobalContext();

  const { findBoxTurmaInformacao } = useFindBoxTurmaInformacao();
  const { checkIfTurmaIsHappyEnglish } = useCheckIfTurmaIsHappyEnglish();
  const { alunos } = useFindAlunosTable();
  const { aulas } = useFindAulasByTurmaId();
  const { avaliacao } = useFindAvaliacaoTable();

  useEffect(() => {
    const controller = new AbortController();

    setIsGlobalLoading(true);

    checkIfTurmaIsHappyEnglish();

    Promise.all([
      findBoxTurmaInformacao(),
      alunos.function(),
      aulas.function(),
      avaliacao.function(),
    ]);

    setIsGlobalLoading(false);

    return () => controller.abort();
  }, [
    checkIfTurmaIsHappyEnglish,
    findBoxTurmaInformacao,
    aulas.function,
    alunos.function,
    avaliacao.function,
    setIsGlobalLoading,
  ]);

  return {
    avaliacao,
    alunos,
    aulas,
  };
}
