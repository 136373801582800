import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PessoaIdStore = {
  pessoaId: number | null;
  setPessoaId: (id: number) => void;
};

export const usePessoaIdStore = create(
  persist<PessoaIdStore>(
    (set) => ({
      pessoaId: null,
      setPessoaId: (id: number) => set({ pessoaId: id }),
    }),
    {
      name: 'pessoaIdStore',
      getStorage: () => localStorage,
    },
  ),
);
