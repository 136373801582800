import type { IndicatorCardType } from '../../../../types/indicator-card';
import useIndicatorCard from './use-indicator-card.hook';
import './indicator-card.styles.scss';

interface IndicatorCardProps {
  cardType: IndicatorCardType;
}

export default function IndicatorCard({ cardType }: IndicatorCardProps) {
  const {
    getCardType: { title, value, image, icon, color },
  } = useIndicatorCard({ cardType });

  return (
    <div style={{ backgroundColor: color }} className="indicator-card">
      {image && <img src={image} alt={title} />}
      {icon && icon}
      <div className="indicator-card__card-stats">
        <h1 className="card-stats__stats-value">{value}</h1>
        <p className="card-stats__stats-label">{title}</p>
      </div>
    </div>
  );
}
