import {
  conteudoMinistradoDataStore
} from '../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import { TipoConteudoEnum } from '../enums/tipo-conteudo.enum';

export const useShowTitleInput = (): boolean => {
  const { conteudoMinistradoData } = conteudoMinistradoDataStore();

  return conteudoMinistradoData.tipo !== TipoConteudoEnum.Aula;
};
