interface Props {
  file?: File;
  base64?: string;
  link?: string;
  size?: number | string;
  height?: number | string;
  width?: number | string;
  className?: string;
}

export const PresentImage = (props: Props) => {
  const { file, base64, link, size, height, width, className } = props;

  const validarTipoArquivo = () => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (base64) {
      return `data:image/png;base64,${base64}`;
    }
    if (link) {
      return link;
    }
  };

  return (
    <>
      {(file || base64 || link) && (
        <img
          src={validarTipoArquivo()}
          className={className}
          style={
            size
              ? { height: size, width: size }
              : { height: height, width: width }
          }
        />
      )}
    </>
  );
};
