import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import SidebarAndContainer from '../../../components/sidebar-and-container/sidebar-and-container.component';
import { ItemTurma } from '../../../components/Item/turma';
import useCursosAulas from './use-cursos-aulas.hook';
import './cursos-aulas.styles.scss';
import TrailCourseInfos from './components/trail-course-infos-box/trail-course-infos.component';

export const CursosAula = () => {
  const {
    trilhaData,
    cursoData,
    aulasData,
    setSearch,
    aulasPageChange,
    currentPage,
    countOfPages,
    userProgress,
    voltarAction,
  } = useCursosAulas();

  if (!trilhaData) return <></>;

  return (
    <SidebarAndContainer cursos={true}>
      <Button
        onClick={voltarAction}
        className="button-second"
        style={{ width: 'fit-content' }}
      >
        <Unicons.UilArrowLeft />
        Voltar
      </Button>
      <TrailCourseInfos
        cursoData={cursoData}
        trilhaData={trilhaData}
        userProgress={userProgress}
      />
      <div className={'cursos-aulas-wrapper__searchbar'}>
        <Unicons.UilSearch
          size="24"
          color="var(--dark-secondary)"
          className="searchbar__icon"
        />
        <input
          className={'searchbar__input'}
          placeholder="Buscar aulas por nome"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="w-100 mb-20">
        <ul className="d-flex-row j-center-a-center cursos-table__table-head w-100">
          <li style={{ width: '10%', marginLeft: 0 }}>Ordem</li>
          <li style={{ width: '50%', marginLeft: 0 }}>Aulas</li>
          <li style={{ width: '15%', marginLeft: 0 }}>Duração Aprox.</li>
          <li style={{ width: '15%', marginLeft: 0 }}>Progresso</li>
        </ul>
        <div className="w-100 j-center-a-center">
          {aulasData?.content?.map(
            ({ ordem, codigo, porcentagemConcluido, duracao, id }) => (
              <ItemTurma
                key={id}
                ordem={ordem.toString()}
                codigo={codigo}
                porcentagemConcluido={porcentagemConcluido}
                duracao={duracao}
                id={id}
              />
            ),
          )}
        </div>
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
          onChange={(event, page) => aulasPageChange(event, page - 1)}
          page={currentPage}
          count={countOfPages}
        />
      </div>
    </SidebarAndContainer>
  );
};
