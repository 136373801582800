import { useGlobalContext } from '../../../context/GlobalContext';
import { useUsuarioCursoService } from '../../../services/usuario-curso.service';
import { useCallback, useEffect } from 'react';
import { useUserKpiStore } from '../../../store/home/user-kpi.store';

export default function useFindUsuarioKpi() {
  const { unidade, setIsGlobalLoading } = useGlobalContext();

  const { getKpi } = useUsuarioCursoService();

  const { setUserKpi } = useUserKpiStore();

  const fetchKpi = useCallback(async () => {
    if (!unidade.id) return;

    setIsGlobalLoading(true);

    await getKpi()
      .then(({ data }) => setUserKpi(data))
      .catch((error) => console.error(error))
      .finally(() => setIsGlobalLoading(false));
  }, [unidade.id]);

  useEffect(() => {
    const controller = new AbortController();

    fetchKpi();

    return () => controller.abort();
  }, [fetchKpi]);
}
