import PropagateLoader from 'react-spinners/PropagateLoader';
import { useGlobalContext } from '../../context/GlobalContext';

const GlobalLoading = () => {
  const { isGlobalLoading } = useGlobalContext();

  return isGlobalLoading ? (
    <div className="global-spinner-overlay" id="spinner">
      <PropagateLoader color={'#0C306C'} loading={isGlobalLoading} size={15} />
    </div>
  ) : (
    <></>
  );
};

export default GlobalLoading;
