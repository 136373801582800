import PresencaSelectOption
  from '../select-options/presenca-select-option.component';
import TarefaSelectOption
  from '../select-options/tarefa-select-option.component';
import ReforcoSelectOption
  from '../select-options/reforco-select-option.component';
import {
  FrequenciaTipoEnum
} from '../../../../enum/lancamento-aula/frequencia-tipo.enum';
import {
  TarefaTipoEnum
} from '../../../../enum/lancamento-aula/tarefa-tipo.enum';
import {
  ReforcoTipoEnum
} from '../../../../enum/lancamento-aula/reforco-tipo.enum';
import {
  SelectOptionInterface
} from '../../../../interfaces/lancamento-aula/step-2/select-option.interface';

export default function useFrequenciaSection() {
  const frequenciaOption: SelectOptionInterface[] = [
    {
      label: <PresencaSelectOption value={FrequenciaTipoEnum.PRESENTE} />,
      value: FrequenciaTipoEnum.PRESENTE
    },
    {
      label: <PresencaSelectOption value={FrequenciaTipoEnum.ATRASADO} />,
      value: FrequenciaTipoEnum.ATRASADO
    },
    {
      label: <PresencaSelectOption value={FrequenciaTipoEnum.AUSENTE} />,
      value: FrequenciaTipoEnum.AUSENTE
    }
  ];

  const tarefaOption: SelectOptionInterface[] = [
    {
      label: <TarefaSelectOption value={TarefaTipoEnum.NAO_FEZ} />,
      value: TarefaTipoEnum.NAO_FEZ
    },
    {
      label: <TarefaSelectOption value={TarefaTipoEnum.FEZ} />,
      value: TarefaTipoEnum.FEZ
    },
    {
      label: <TarefaSelectOption value={TarefaTipoEnum.ATRASADO} />,
      value: TarefaTipoEnum.ATRASADO
    },
    {
      label: <TarefaSelectOption value={TarefaTipoEnum.NAO_APLICAVEL} />,
      value: TarefaTipoEnum.NAO_APLICAVEL
    }
  ];

  const reforcoOption: SelectOptionInterface[] = [
    {
      label: <ReforcoSelectOption value={ReforcoTipoEnum.TRUE} />,
      value: ReforcoTipoEnum.TRUE
    },
    {
      label: <ReforcoSelectOption value={ReforcoTipoEnum.FALSE} />,
      value: ReforcoTipoEnum.FALSE
    }
  ];

  return {
    frequenciaOption,
    tarefaOption,
    reforcoOption
  };
}
