import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IUsuarioCursoCleanDTO,
  IUsuarioCursoDTO,
  IUsuarioCursoFullDTO,
  IUsuarioCursoHappyCoinsDTO,
  IUsuarioCursoKpiCustomDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useUsuarioCursoService = () => {
  const resourceURL = 'usuario-curso';

  const favoritar = (
    flFavorito: boolean,
    idCurso: number,
  ): Promise<AxiosResponse<IUsuarioCursoDTO>> =>
    http.post(`${resourceURL}/favorito/${flFavorito}/curso/${idCurso}`);

  const getHappyCoins = (): Promise<
    AxiosResponse<IUsuarioCursoHappyCoinsDTO>
  > => http.get(`${resourceURL}/happycoins`);

  const getKpi = (): Promise<AxiosResponse<IUsuarioCursoKpiCustomDTO>> =>
    http.get(`${resourceURL}/kpi`);

  const getConclusao = (idCurso: number): Promise<AxiosResponse<number>> =>
    http.get(`${resourceURL}/conclusao/${idCurso}`);

  const getTrilhaConclusao = (idTrilha: number, idUsuario: number): Promise<AxiosResponse<boolean>> => {
    return http.get(`${resourceURL}/trilha/conclusao/${idTrilha}/${idUsuario}`);
  }

  return {
    ...baseFind<IUsuarioCursoCleanDTO, IUsuarioCursoDTO, IUsuarioCursoFullDTO>(
      resourceURL,
    ),
    ...baseFindById<
      IUsuarioCursoCleanDTO,
      IUsuarioCursoDTO,
      IUsuarioCursoFullDTO
    >(resourceURL),
    ...baseFindList<
      IUsuarioCursoCleanDTO,
      IUsuarioCursoDTO,
      IUsuarioCursoFullDTO
    >(resourceURL),
    ...baseCreate<
      IUsuarioCursoCleanDTO,
      IUsuarioCursoDTO,
      IUsuarioCursoFullDTO
    >(resourceURL),
    ...baseUpdate<
      IUsuarioCursoCleanDTO,
      IUsuarioCursoDTO,
      IUsuarioCursoFullDTO
    >(resourceURL),
    ...baseDelete(resourceURL),
    favoritar,
    getHappyCoins,
    getKpi,
    getConclusao,
    getTrilhaConclusao
  };
};
