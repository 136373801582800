import './student-accordion.styles.scss';
import IconButton
  from '../../../../components/icon-button/icon-button.component';
import StatusMatricula from '../../../Turmas/components/StatusMatricula';
import {
  IRelatorioFrequenciaSkillsAulaDTO
} from '../../../../models/happy-code-api.model';
import useStudentAccordion from './use-student-accordion.hook';
import FrequenciaSection
  from '../frequencia-section/frequencia-section.component';
import { Button } from 'antd';
import SkillAssessmentSection
  from '../skill-assessment-section/skill-assessment-section.component';
import { FormEvent } from 'react';
import UploadContainer from '../upload-container/upload-container.component';
import {
  IListAccordionForm
} from '../../interfaces/list-accordion-form.interface';

interface StudentAccordionProps {
  relatorio: IRelatorioFrequenciaSkillsAulaDTO;
  textButton?: string;
  form: {
    submit: (event: FormEvent<HTMLFormElement>, currentAccordion: () => IListAccordionForm) => void;
  };
}

export default function StudentAccordion({
  relatorio: {
    idMatricula,
    nomeAluno,
    statusMatricula,
    habilidadesList,
    competenciasList
  },
  textButton,
  form
}: StudentAccordionProps) {
  const {
    accordion,
    showUploadContainer
  } = useStudentAccordion({ idMatricula });

  return (
    <div className={'student-accordion'}>
      <div
        className={'student-accordion__top-content'}
        onClick={() => accordion.toggle(idMatricula)}
      >
        <IconButton icon={accordion.icon} type={'ghosted'} />
        <div className={'top-content__student-infos'}>
          <h6 className={'student-infos__student-name'}>{nomeAluno}</h6>
          <div className={'student-infos__matricula-status'}>
            <p className={'matricula-status__id-matricula'}>
              #{idMatricula.toString()}
            </p>
            <StatusMatricula codigoStatusMatricula={statusMatricula} />
          </div>
        </div>
      </div>
      {accordion.current()?.isOpen && (
        <form onSubmit={(event) => form.submit(event, accordion.current)}>
          <hr className={'student-accordion__divisor'} />
          <FrequenciaSection idMatricula={idMatricula} />
          {habilidadesList.length > 0 && (
            <>
              <hr className={'student-accordion__divisor'} />
              <SkillAssessmentSection
                habilidadeCompetenciaList={habilidadesList}
                isHabilidade
                idMatricula={idMatricula}
              />
            </>
          )}
          {competenciasList.length > 0 && (
            <>
              <hr className={'student-accordion__divisor'} />
              <SkillAssessmentSection
                habilidadeCompetenciaList={competenciasList}
                idMatricula={idMatricula}
              />
            </>
          )}
          {showUploadContainer && (
            <>
              <hr className={'student-accordion__divisor'} />
              <UploadContainer idMatricula={idMatricula} />
            </>
          )}
          <hr className={'student-accordion__divisor'} />
          <div className={'student-accordion__buttons'}>
            <Button
              htmlType={'submit'}
              className={'button-primary'}
              disabled={accordion.current().disabled}
            >
              {textButton || 'Registrar'}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
