import KeyboardArrowDownRoundedIcon
  from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon
  from '@mui/icons-material/KeyboardArrowRightRounded';
import useFindRelatorioFrequenciaSkill
  from './hooks/use-find-relatorio-frequencia-skill.hook';
import { useCallback, useEffect } from 'react';
import { listAccordionFormStore } from '../../store/list-accordion-form.store';
import { useShowTitleInput } from '../../hooks/use-show-title-input.hook';

interface UseStudentAccordionProps {
  idMatricula: number;
}

export default function useStudentAccordion({ idMatricula }: UseStudentAccordionProps) {
  const { getRelatorioFrequenciaSkill } = useFindRelatorioFrequenciaSkill();
  const showUploadContainer = useShowTitleInput();

  const { toggleAccordion, findAccordionById } = listAccordionFormStore();

  const foundAccordion = useCallback(() => findAccordionById(idMatricula), [findAccordionById, idMatricula]);

  const accordionIcon = foundAccordion()?.isOpen
    ? KeyboardArrowDownRoundedIcon
    : KeyboardArrowRightRoundedIcon;

  useEffect(() => {
    const controller = new AbortController();

    getRelatorioFrequenciaSkill(idMatricula);

    return () => controller.abort();
  }, [getRelatorioFrequenciaSkill, idMatricula]);

  return {
    accordion: {
      current: foundAccordion,
      icon: accordionIcon,
      toggle: toggleAccordion
    },
    showUploadContainer
  };
}
