import { useGlobalContext } from '../context/GlobalContext';
import { ChangeEvent } from 'react';

export const useHandlePageChangeHook = () => {
  const { setIsGlobalLoading } = useGlobalContext();

  return (findFunction: (page: number) => Promise<void>) =>
    (event: ChangeEvent<unknown>, page: number) => {
      event.preventDefault();

      setIsGlobalLoading(true);

      findFunction(page).finally(() => setIsGlobalLoading(false));
    };
};
