import { useParams } from "react-router-dom";
import { ChangeEvent, useCallback, useState } from "react";
import { PageableResponse } from "../../../models/response.model";
import { IAlunosTableDTO } from "../../../models/happy-code-api.model";
import { Predicate } from "../../../models/predicate.model";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useAlunoService } from "../../../services/aluno.service";

export default function useFindAlunosTable() {
  const { id } = useParams();
  const service = useAlunoService();
  const { setIsGlobalLoading } = useGlobalContext();

  const [alunosTableData, setAlunosTableData] =
    useState<PageableResponse<IAlunosTableDTO>>();

  const buildPredicate = useCallback((page?: number) => {
    const predicate = new Predicate();

    if (page) predicate.setPage(page);

    return predicate;
  }, []);

  const findAlunosTable = useCallback(
    async (page?: number) => {
      if (!id) return;

      const parseIdToInt = parseInt(id);

      const predicate = buildPredicate(page);

      await service
        .findAlunosTable(parseIdToInt, predicate)
        .then(({ data }) => setAlunosTableData(data))
        .catch((error) => console.error(error));
    },
    [id],
  );

  const onPageAlunosTableChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    event.preventDefault();

    setIsGlobalLoading(true);

    findAlunosTable(page).finally(() => setIsGlobalLoading(false));
  };

  return {
    alunos: {
      data: alunosTableData,
      onPageChange: onPageAlunosTableChange,
      function: findAlunosTable,
    },
  };
}
