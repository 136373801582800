import { ChangeEvent } from "react";
import { PageableResponse } from "../../../../models/response.model";
import { IAvaliacaoTableDTO } from "../../../../models/happy-code-api.model";
import ModalLancarNota from "../../../../components/ModalProva";
import useAvaliacoesTable from "./useAvaliacoesTable";
import GenericPageableTable from "../../../../components/GenericPageableTable/generic-pageable-table.component";
import StatusMatricula from "../StatusMatricula";

interface AvaliacoesTableProps {
  tableData: PageableResponse<IAvaliacaoTableDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
  findAvaliacoes: () => Promise<void>;
}

export default function AvaliacoesTable({
  tableData,
  onPageChange,
  findAvaliacoes,
}: AvaliacoesTableProps) {
  const {
    lancamentoNota,
    provaSelected,
    idMatriculaSelected,
    handleLancarNota,
    onCloseModal,
    handledListMatricula,
  } = useAvaliacoesTable({ findAvaliacoes, tableData });

  return (
    <>
      <GenericPageableTable
        data={handledListMatricula}
        columns={[
          {
            key: "nome",
            title: "NOME",
            render: ({ nome, statusMatricula }) => (
              <div className="aluno-table__aluno-cell">
                <p className="aluno-cell__aluno-nome">{nome}</p>
                <StatusMatricula codigoStatusMatricula={statusMatricula} />
              </div>
            ),
          },
          { key: "primeiraNota", title: "Média Term 1 (0-50)" },
          { key: "segundaNota", title: "Média Term 2 (0-50)" },
          { key: "finalNota", title: "Média Final (0-100)" },
        ]}
        rowActions={[
          {
            label: "Lançar nota",
            onClick: (item) => handleLancarNota(item?.matriculaProva, item?.id),
          },
        ]}
        onPageChange={onPageChange}
      />
      <ModalLancarNota
        open={lancamentoNota}
        onClose={onCloseModal}
        prova={provaSelected}
        idMatricula={idMatriculaSelected}
      />
    </>
  );
}
