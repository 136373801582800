import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import { formatHours } from '../../util/format';

interface Props {
  ordem: string;
  codigo: string;
  duracao: string;
  porcentagemConcluido: number;
  id: number;
}

export const ItemTurma = ({
  ordem,
  codigo,
  duracao,
  porcentagemConcluido,
  id,
}: Props) => {
  return (
    <Link
      to={`/aula/${id}`}
      className="d-flex-row j-center-a-center w-100 row-items"
      style={{ cursor: 'pointer', borderRadius: '0 0 8px 8px' }}
    >
      <div className="item" style={{ width: '10%' }}>
        {ordem}
      </div>
      <div className="item" style={{ width: '50%' }}>
        {codigo}
      </div>
      <div className="item" style={{ width: '15%' }}>
        {formatHours(duracao) + 'h'}
      </div>
      <div className="item" style={{ width: '15%' }}>
        <Progress
          strokeColor="var(--primary-color)"
          percent={porcentagemConcluido}
        />
      </div>
    </Link>
  );
};
