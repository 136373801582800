import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import { IAcaCursoDTO, ITrilhaDTO } from '../../models/happy-code-api.model';
import { PresentImage } from '../ApresentarImagem';
import { Avatar } from '../Avatar';
import useCurso from './use-curso.hook';
import './_curso.scss';

interface CursoCardProps {
  curso: IAcaCursoDTO;
  trilha: ITrilhaDTO;
  progress?: boolean;
}

export const CursoCard = ({ curso, trilha }: CursoCardProps) => {
  const { calculateWorkload, presentImageCourse } = useCurso({
    curso,
  });

  // const happyCoins = curso?.happyCoins.toLocaleString('de-DE') || 0;

  return (
    <div className="curso-card-container">
      <div className="curso-card-container__curso-informations">
        <div className="curso-informations__thumbnail-title">
          <PresentImage link={presentImageCourse} />
          <h1 className="thumbnail-title__course-title">{curso.nome}</h1>
        </div>

        <div className="curso-informations__teacher-schedule-reward">
          <div className="curso-informations__curso-info">
            <div className="curso-info__thumbnail">
              <Avatar arquivo={curso.usuarioInstrutor?.arquivo} size={32} />
            </div>

            <div className="curso-info__label-info">
              <label className="course-info__label">Ministrado por</label>
              <p className="course-info__name">
                {curso?.usuarioInstrutor?.pessoa?.nome}
              </p>
            </div>
          </div>

          <div className="curso-informations__schedule-coins">
            <div className="curso-informations__curso-info">
              <Unicons.UilClockThree color="var(--secondary-color)" size={24} />

              <div className="curso-info__label-info">
                <h3 className="course-info__label">Carga horária</h3>
                <p>{calculateWorkload()}</p>
              </div>
            </div>

            {/* <div className="curso-informations__curso-info">
              <Unicons.UilGift size={24} color="var(--secondary-color)" />

              <div className="curso-info__label-info">
                <h3 className="course-info__label">Recompensa</h3>
                <div className="curso-info__coins">
                  <h5 className="course-info__info">h¢ {happyCoins}</h5>
                  <img src={Coin} alt="Happy Coins" className="coin-trilha" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Link
        className="button-primary"
        to={`/cursos/${curso?.id}/trilha/${trilha?.id}`}
      >
        Ver curso
      </Link>
    </div>
  );
};
