import { Button } from 'antd';
import { useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ModalMentoria } from './mentoria';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalMentor = (props: Props) => {
  const { open, onClose } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onChange = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  if (!open) return null;

  return (
    <>
      <div className="d-flex-row j-center-a-center modal-container">
        <div className="d-flex-column j-start-a-center modal">
          <div className="d-flex-row j-center-a-center mb-10">
            <Unicons.UilGraduationCap
              color="var(--secondary-color)"
              size={120}
            />
          </div>
          <h1 className="mb-10">Confirme os dados de sua mentoria</h1>
          <p>Confirmar sua mentoria para dia $data no horário $horário1.</p>
          <div className="line"></div>
          <div className="d-flex-row button-box w-100">
            <Button className="button-blue mr-10" onClick={onClose}>
              Cancelar
            </Button>
            <Button className="button-primary" onClick={onChange}>
              Confirmar
            </Button>
          </div>
        </div>
      </div>
      <ModalMentoria open={isOpen} onClose={onClose} />
    </>
  );
};
