import { AxiosResponse } from 'axios';
import {
  IAulaCleanDTO,
  IAulaDTO,
  IAulaFullDTO,
  IAulaInformacaoBoxDTO,
  ICreateFrequenciaSkillDTO,
  ILancamentoAulasTableDTO,
  IRelatorioFrequenciaSkillDTO,
  IRelatorioFrequenciaSkillsAulaDTO
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import http from './../helpers/http-common';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { PageableResponse } from '../models/response.model';
import { useCallback } from 'react';

export const useAulaService = () => {
  const resourceURL = 'aula';

  const findByUnidadeId = (
    id: number,
    predicate: Predicate = new Predicate()
  ): Promise<AxiosResponse<IAulaDTO[]>> =>
    http.get<IAulaDTO[]>(
      resourceURL + `/unidade/${id}`,
      formatHttpParams(predicate)
    );

  const findByTurmaUnidadeIdAndPessoaId = (
    idUnidade: number,
    idPessoa: number,
    predicate: Predicate = new Predicate()
  ): Promise<AxiosResponse<IAulaDTO[]>> =>
    http.get<IAulaDTO[]>(
      resourceURL + `/unidade/${idUnidade}/pessoa/${idPessoa}`,
      formatHttpParams(predicate)
    );

  const findCustom = (predicate: Predicate = new Predicate()): Promise<any> => {
    return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
  };

  const findAulaInformacaoBox = (
    id: number
  ): Promise<AxiosResponse<IAulaInformacaoBoxDTO>> =>
    http.get<IAulaInformacaoBoxDTO>(`${resourceURL}/informacao-box/${id}`);

  const findAulasFromTurmaId = (
    id: number,
    predicate: Predicate = new Predicate()
  ): Promise<AxiosResponse<PageableResponse<ILancamentoAulasTableDTO>>> =>
    http.get<PageableResponse<ILancamentoAulasTableDTO>>(
      `${resourceURL}/turma/${id}`,
      formatHttpParams(predicate)
    );

  const findRelatorioFrequencias = useCallback((
    id: number
  ): Promise<AxiosResponse<IRelatorioFrequenciaSkillsAulaDTO[]>> =>
    http.get<IRelatorioFrequenciaSkillsAulaDTO[]>(
      `${resourceURL}/relatorio-frequencia/${id}`
    ), []);

  const createRelatorioFrequenciaSkill = async (body: ICreateFrequenciaSkillDTO) =>
    await http.post(`${resourceURL}/relatorio-frequencia-skill`, body);

  const findRelatorioFrequenciaSkill = useCallback(
    async (idAula: number, idMatricula: number): Promise<AxiosResponse<IRelatorioFrequenciaSkillDTO>> =>
      await http.get(`${resourceURL}/relatorio-frequencia-skill/${idAula}/${idMatricula}`), []);

  const updateRelatorioFrequenciaSkill = (body: ICreateFrequenciaSkillDTO) =>
    http.patch(`${resourceURL}/update-relatorio-frequencia-skill`, body);

  return {
    ...baseCreate<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
    ...baseFind<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
    ...baseFindById<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
    findCustom,
    findByUnidadeId,
    findByTurmaUnidadeIdAndPessoaId,
    findAulaInformacaoBox,
    findAulasFromTurmaId,
    findRelatorioFrequencias,
    createRelatorioFrequenciaSkill,
    findRelatorioFrequenciaSkill,
    updateRelatorioFrequenciaSkill
  };
};
