import { Button } from 'antd';
import './icon-button.styles.scss';
import { ElementType } from 'react';

type IconType = 'primay' | 'second' | 'ghosted';

type FindOutWhatClassToUse = {
  [key in IconType]:
  'icon-button__primary'
  | 'icon-button__second'
  | 'icon-button__ghosted';
}

interface IconButtonProps {
  onClick?: () => void;
  icon: ElementType;
  type?: IconType;
}

export default function IconButton({
                                     onClick,
                                     icon: Icon,
                                     type = 'second'
                                   }: IconButtonProps) {

  const findOutWhatClassToUse: FindOutWhatClassToUse = {
    primay: 'icon-button__primary',
    second: 'icon-button__second',
    ghosted: 'icon-button__ghosted'
  };

  const getClass = findOutWhatClassToUse[type];

  return (
    <Button onClick={onClick} className={`icon-button ${getClass}`}>
      <Icon size="24" color={'var(--primary-color)'} />
    </Button>
  );
}
