import { Button } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  image: string;
  name: string;
  description: string;
}

export const Mentor = (props: Props) => {
  const { image, name, description } = props;

  return (
    <>
      <div className="box-mentor d-flex-row j-start-a-center">
        <img src={image} alt="avatar" />
        <div className="info-mentor">
          <h3 className="mb-10">Prof. {name}</h3>
          <p className="mb-10">{description}</p>
          <Link to="/mentorias/mentores/perfil">
            <Button className="button-primary">Ver detalhes</Button>
          </Link>
        </div>
      </div>
    </>
  );
};
