import {
  conteudoMinistradoDataStore
} from '../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import {
  useLearnConteudoMinistradoService
} from '../../../../services/learn/learn-conteudo-ministrado.service';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IConteudoAulaDTO } from '../../../../models/happy-code-api.model';

export default function useLancamentoAulaEdit() {
  const { id } = useParams();
  const { findConteudoAula } = useLearnConteudoMinistradoService();

  const { setConteudoMinistradoData } = conteudoMinistradoDataStore();

  const handleConteudoMinistradoData = useCallback(
    (conteudo: IConteudoAulaDTO): IConteudoAulaDTO => {
      return {
        tipo: conteudo.tipo,
        titulo: conteudo.titulo,
        conteudo: conteudo.conteudo,
        conteudoDescricao: conteudo.conteudoDescricao,
        descricao: conteudo.descricao
      };
    },
    []
  );

  const findConteudoMinistrado = useCallback(async () => {
    if (!id) return;

    await findConteudoAula(Number(id))
      .then(({ data }) => {
        const handledData = handleConteudoMinistradoData(data);
        setConteudoMinistradoData(handledData);
      });
  }, [findConteudoAula, handleConteudoMinistradoData, id]);

  useEffect(() => {
    findConteudoMinistrado();
  }, [findConteudoMinistrado]);
}
