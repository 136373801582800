import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./select-options.styles.scss";

interface TarefaSelectOptionProps {
  value: string;
}

export default function TarefaSelectOption({ value }: TarefaSelectOptionProps) {
  const getTarefaOption = {
    NFZ: {
      icon: <CloseCircleOutlined style={{ color: "var(--red-color)" }} />,
      text: "Não fez",
      style: { color: "var(--red-color)" },
    },
    FEZ: {
      icon: <CheckCircleOutlined style={{ color: "var(--green-color)" }} />,
      text: "Fez",
      style: { color: "var(--green-color)" },
    },
    ATR: {
      icon: <ClockCircleOutlined style={{ color: "var(--orange-color)" }} />,
      text: "Atrasado",
      style: { color: "var(--orange-color)" },
    },
    NAP: {
      icon: <CheckCircleOutlined style={{ color: "var(--dark)" }} />,
      text: "Não aplicável",
      style: { color: "var(--dark)" },
    },
  };

  const tarefaOption = getTarefaOption[value];

  return (
    <div className={"select-options-wrapper"}>
      {tarefaOption.icon}
      <p style={tarefaOption.style}>{tarefaOption.text}</p>
    </div>
  );
}
