import { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ProductType } from '../../enum/materiais/product-type.enum';
import useFindCursos from './hooks/use-find-cursos.hook';
import useFindCurriculo from './hooks/use-find-curriculo.hook';
import useFindProductStemplay from './hooks/use-find-product-stemplay.hook';
import useSelectTypeMaterial from './hooks/use-select-type-material.hook';
import { useSearchParams } from 'react-router-dom';

const useMateriais = () => {
  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const { cursos } = useFindCursos();
  const { curriculos } = useFindCurriculo();
  const { productStemplay } = useFindProductStemplay();
  const { selectTypeMaterial } = useSelectTypeMaterial();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialProductType =
    ProductType[searchParams.get('tipo')] || ProductType.ProdutoEscola;

  const [productType, setProductType] = useState<ProductType | string>(
    initialProductType,
  );

  const handleProductTypeChange = (productType: ProductType | string) => {
    setProductType(productType);
    setSearchParams({ tipo: ProductType[productType].toString() });
  };

  const fetchAllMaterialData = useCallback((): void => {
    if (!unidade.id) return;

    setIsGlobalLoading(true);

    Promise.all([
      productStemplay.getData(),
      cursos.getData(),
      curriculos.getData(),
    ]).finally(() => setIsGlobalLoading(false));
  }, [
    curriculos.getData,
    cursos.getData,
    productStemplay.getData,
    setIsGlobalLoading,
    unidade.id,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    fetchAllMaterialData();

    return () => controller.abort();
  }, [fetchAllMaterialData]);

  const materiaisTitleBasedOnProductType = (): string => {
    if (productType === ProductType.Curso)
      return `Meus cursos (${cursos.data?.totalElements || 0})`;

    if (productType === ProductType.Curriculo)
      return `Meus currículos (${curriculos.data?.totalElements || 0})`;

    if (productType === ProductType.ProdutoEscola)
      return `Meus produtos (${productStemplay?.data?.totalElements || 0})`;

    return 'Meus materiais';
  };

  const tableHeaderText =
    productType !== ProductType.ProdutoEscola ? 'Capítulos ' : 'Ano escolar';

  return {
    cursos,
    curriculos,
    productStemplay,
    productType,
    handleProductTypeChange,
    materiaisTitleBasedOnProductType,
    initialProductType,
    tableHeaderText,
    selectTypeMaterial,
  };
};

export default useMateriais;
