import { ITopicoAulaDTO } from '../../../../models/happy-code-api.model';
import './styles.scss';

const TopicoHtmlComponent = ({ topico }: { topico: ITopicoAulaDTO }) => {
  return (
    <>
      <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
        <div id="box-aula-white" className="box-white w-100">
          <p
            className="mb-20"
            style={{ overflowWrap: 'anywhere' }}
            dangerouslySetInnerHTML={{ __html: topico.conteudo }}
          ></p>
        </div>
      </div>
    </>
  );
};

export default TopicoHtmlComponent;
