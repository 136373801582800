import './box-data-turma-detalhe.css';
import {
  ScheduleOutlined,
  SchoolOutlined,
  SentimentSatisfiedAltOutlined
} from '@mui/icons-material';
import useBoxDataTurmaDetalhe from './use-box-data-turma.detalhe';
import {
  boxTurmaInformacaoStore
} from '../../store/box-turma-informacao.store';
import StatusTurma from '../StatusTurma';

export default function BoxDataTurmaDetalhe() {
  const {
    totalAulasRealizadas,
    totalAulasRealizadasLabel,
    getDataInicio,
    getDataFim,
    duracaoEmDias,
    labelDuracao,
    labelAlunos,
    duracaoCompletada,
    labelMinutos,
    curriculoTurma,
  } = useBoxDataTurmaDetalhe();

  const {
    boxTurmaInformacao: {
      quantidadeAlunos,
      aulaTimeList,
      duracaoTotal,
      nome,
      quantidadeAulas,
      dataInicio,
      dataFim,
    },
  } = boxTurmaInformacaoStore();

  return (
    <div className="box-data-turma">
      <div className="box-data-turma__turma-descricao">
        <h2 className="turma-descricao__titulo">{nome}</h2>
        <h3>{curriculoTurma}</h3>

        <div className={'turma-descricao__status-dias'}>
          <StatusTurma dataInicio={dataInicio} dataFim={dataFim} />
          <p className="status-dias__data">
            {getDataInicio} - {getDataFim} ({duracaoEmDias} {labelDuracao})
          </p>
        </div>
      </div>
      <div className="box-data-turma__info-icones">
        <div className="info-icones">
          <SentimentSatisfiedAltOutlined />
          <p>
            {quantidadeAlunos} {labelAlunos}
          </p>
        </div>
        <div className="info-icones">
          <SchoolOutlined />
          <p>
            {totalAulasRealizadas}/{quantidadeAulas} {totalAulasRealizadasLabel}
          </p>
        </div>
        <div className="info-icones">
          <ScheduleOutlined />
          <p>
            {duracaoCompletada}/{duracaoTotal} {labelMinutos}
          </p>
        </div>
      </div>
      <div className="box-data-turma__datas-horarios">
        {aulaTimeList &&
          aulaTimeList.map(({ diaSemana, horaInicio, horaFim }, index) => (
            <div key={index} className="datas-horarios">
              <h6 className="datas-horarios__dia-semana">{diaSemana}</h6>
              <p className="datas-horarios__horario">
                {horaInicio?.toLocaleString().slice(0, 5)} -{' '}
                {horaFim?.toLocaleString().slice(0, 5)}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
