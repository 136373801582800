import useConteudoMinistradoForm
  from './hooks/use-conteudo-ministrado-form.hook';
import { useEffect } from 'react';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import useConteudoAlreadyRegistered
  from './hooks/use-conteudo-already-registered.hook';

export default function useLancamentoAulaStep1() {
  const { setIsGlobalLoading } = useGlobalContext();
  const { formObject: { submitForm } } = useConteudoMinistradoForm();
  const {
    checkIfConteudoExists
  } = useConteudoAlreadyRegistered();


  useEffect(() => {
    const controller = new AbortController();

    setIsGlobalLoading(true);

    Promise.all([
      checkIfConteudoExists()
    ]).finally(() => setIsGlobalLoading(false));

    return () => controller.abort();
  }, [checkIfConteudoExists, setIsGlobalLoading]);

  return {
    formObject: {
      submitForm,
      buttonLabel: 'Registrar Conteúdo'
    }
  };
}
