import { Select } from 'antd';
import {
  useSkillAssessmentSectionHook
} from './use-skill-assessment-section.hook';
import './skill-assessment-section.styles.scss';
import {
  ICompetenciaConteudoMinistradoAulaDTO,
  IHabilidadeConteudoMinistradoAulaDTO
} from '../../../../models/happy-code-api.model';
import {
  HabilidadeCompetenciaEscalaEnum
} from '../../../../enum/lancamento-aula/habilidade-competencia-escala.enum';


interface SkillAssessmentSectionProps {
  habilidadeCompetenciaList:
  IHabilidadeConteudoMinistradoAulaDTO[] |
  ICompetenciaConteudoMinistradoAulaDTO[];
  isHabilidade?: boolean;
  idMatricula: number;
}

export default function SkillAssessmentSection({
  habilidadeCompetenciaList,
  isHabilidade = false,
  idMatricula
}: SkillAssessmentSectionProps) {
  const {
    habilidadeCompetenciaOption,
    handleOnChange,
    disabled,
    findValue
  } = useSkillAssessmentSectionHook({
    isHabilidade,
    idMatricula
  });

  return (
    <div className={'skill-assessment-section'}>
      {
        habilidadeCompetenciaList.map(({ id, nome }, index) => (
          <div
            className={'skill-assessment-section__select-input'}
            key={index}>
            <label className={'select-input__label'}>{nome}</label>
            <Select
              onChange={(value: number) => handleOnChange(id, nome, value)}
              disabled={disabled}
              className={'select-input__input'}
              options={habilidadeCompetenciaOption}
              value={findValue(id, nome)?.value}
              defaultValue={HabilidadeCompetenciaEscalaEnum.NEUTRO}
            />
          </div>
        ))
      }
    </div>
  );
}
