import {
  convertToTitleCase
} from '../../../../../../../util/format-to-title-case.util';

export function useConteudoAulaLabelParagrafo() {
  const convertParagrafoToTitleCase = (
    paragrafo: string,
    label: 'Tipo' | string
  ): string => {
    if (!paragrafo || !paragrafo.length) return '-';
    if (label !== 'Tipo') return paragrafo;

    return convertToTitleCase(paragrafo);
  };

  return { convertParagrafoToTitleCase };
}
