import { ChevronRightOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { useState } from 'react';

export default function useAccordion() {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);

  const abreOuFechaAccordion = () => setAccordionIsOpen(!accordionIsOpen);

  const defineIconDoAccordion = () =>
    accordionIsOpen ? <ExpandMoreOutlined /> : <ChevronRightOutlined />;

  return {
    accordionIsOpen,
    abreOuFechaAccordion,
    defineIconDoAccordion,
  };
}
