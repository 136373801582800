import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import useConectasAll from './use-conectas-all.hook';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../components/title/title.component';
import './conectas-all.styles.scss';
import { ConectaCard } from '../../components/ConectaCard/conecta-card.component';

export const ConectasAll = () => {
  const { conectasPage, search, setSearch, onPageChange, conectaTitle } =
    useConectasAll();

  return (
    <SidebarAndContainer conectas={true}>
      <div className="conectas-all-container__title-button-back">
        <Link to="/instrutor" className="button-second">
          <Unicons.UilArrowLeft />
          Voltar
        </Link>
        <Title title={conectaTitle} />
      </div>

      <div className="webinars-all-container__search-bar">
        <i className="search-bar__icon">
          <Unicons.UilSearch size={20} color="var(--dark)" />
        </i>
        <input
          className="search-bar__input"
          placeholder="Buscar webinar"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="webinars-all-container__cards-container">
        {conectasPage &&
          conectasPage.content
            .filter(({ nome }) => nome.includes(search))
            .map((conecta) => <ConectaCard conecta={conecta} />)}
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={conectasPage ? conectasPage.pageable.pageNumber + 1 : 0}
          count={conectasPage ? conectasPage.totalPages : 0}
        />
      </div>
    </SidebarAndContainer>
  );
};
