import { Button, Input, Select } from 'antd';
import './conteudo-ministrado-form.styles.scss';
import useConteudoMinistradoForm from './use-conteudo-ministrado-form.hook';
import {
  conteudoMinistradoDataStore
} from '../../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import { FormEvent } from 'react';

interface ConteudoMinistradoFormProps {
  submitForm: (event: FormEvent<HTMLFormElement>) => void;
  buttonLabel: string;
  formIsDisabled?: boolean;
}

export default function ConteudoMinistradoForm({
  submitForm,
  buttonLabel,
  formIsDisabled
}: ConteudoMinistradoFormProps) {
  const {
    showTitleInput,
    submitButtonDisabled,
    tipoConteudoMinistrado,
    conteudo
  } = useConteudoMinistradoForm();

  const { conteudoMinistradoData, updateConteudoMinistradoData } =
    conteudoMinistradoDataStore();

  return (
    <form
      onSubmit={(event) => submitForm(event)}
      className={'conteudo-ministrado-form-wrapper'}
    >
      <div className={'conteudo-ministrado-form-wrapper__text-field-container'}>
        <label className={'text-field-container__label'}>
          Tipo do Conteúdo Ministrado
        </label>
        <Select
          disabled={formIsDisabled}
          onChange={(event) => updateConteudoMinistradoData(event, 'tipo')}
          rootClassName={'text-field-container__input'}
          defaultValue={conteudoMinistradoData.tipo}
          value={conteudoMinistradoData.tipo}
          size={'large'}
          options={tipoConteudoMinistrado}
        />
      </div>

      {showTitleInput && (
        <div
          className={'conteudo-ministrado-form-wrapper__text-field-container'}>
          <label className={'text-field-container__label'}>Título</label>
          <Input
            disabled={formIsDisabled}
            onChange={(event) =>
              updateConteudoMinistradoData(event.target.value, 'titulo')
            }
            rootClassName={'text-field-container__input'}
            size={'large'}
            defaultValue={conteudoMinistradoData.titulo}
            value={conteudoMinistradoData.titulo}
          />
        </div>
      )}
      <div className={'conteudo-ministrado-form-wrapper__text-field-container'}>
        <label className={'text-field-container__label'}>Conteúdo</label>
        <Select
          disabled={formIsDisabled}
          onChange={(event) =>
            updateConteudoMinistradoData(event, 'conteudo')
          }
          rootClassName={'text-field-container__input'}
          size={'large'}
          defaultValue={conteudoMinistradoData.conteudo}
          value={conteudoMinistradoData.conteudo}
          options={conteudo}
        />
      </div>
      <div className={'conteudo-ministrado-form-wrapper__text-field-container'}>
        <label className={'text-field-container__label'}>Descrição</label>
        <Input.TextArea
          disabled={formIsDisabled}
          onChange={(e) =>
            updateConteudoMinistradoData(e.target.value, 'descricao')
          }
          defaultValue={conteudoMinistradoData.descricao}
          value={conteudoMinistradoData.descricao}
          size={'large'}
          rootClassName={'text-field-container__input'}
        />
      </div>

      <div className={'conteudo-ministrado-wrapper__buttons'}>
        <Button
          disabled={submitButtonDisabled || formIsDisabled}
          htmlType={'submit'}
          className={'button-primary'}
        >
          {buttonLabel}
        </Button>
      </div>
    </form>
  );
}
