import { useCallback, useEffect, useState } from 'react';
import { ITurmaKpiDTO } from '../../../../../models/happy-code-api.model';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { usePessoaIdStore } from '../../../../../store/global/pessoa-id.store';
import { useTurmaService } from '../../../../../services/turma.service';
import { useTurmasPredicateStore } from '../../../../../store/turmas/turmas-predicate.store';

export default function useFindTurmasKpi() {
  const { unidade } = useGlobalContext();

  const { pessoaId } = usePessoaIdStore();

  const { turmasPredicate } = useTurmasPredicateStore();

  const addPredicateOnTurmasKpiRoute = useCallback((): void => {
    if (!unidade.id) return;

    turmasPredicate.addOption('unidadePessoa.pessoa.id', pessoaId);
    turmasPredicate.addOption('unidade.id', unidade.id);
  }, [pessoaId, unidade.id]);

  const { getKpi } = useTurmaService();

  const [turmasKpi, setTurmasKpi] = useState<ITurmaKpiDTO>(null);

  const findTurmasKpi = useCallback(async () => {
    addPredicateOnTurmasKpiRoute();

    if (turmasPredicate._arrCustomOptions.length === 0) return;

    await getKpi(turmasPredicate).then((response) =>
      setTurmasKpi(response.data),
    );
  }, [addPredicateOnTurmasKpiRoute]);

  useEffect(() => {
    const controller = new AbortController();

    findTurmasKpi();

    return () => controller.abort();
  }, [findTurmasKpi]);

  return {
    turmasKpi,
  };
}
