import { useCallback, useState } from 'react';
import { useLearnConteudoService } from '../../../../../../../../services/learn/learn-conteudo.service';
import { conteudoMinistradoDataStore } from '../../../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import useBoxDataTurmaDetalhe from '../../../../../../../Turmas/components/BoxDataTurmaDetalhe/use-box-data-turma.detalhe';
import { ICurriculoConteudoDTO } from '../../../../../../../../models/happy-code-api.model';
import { useGlobalContext } from '../../../../../../../../context/GlobalContext';

export const useFindConteudo = () => {
  const { fetchConteudoByIdCurriculo } = useLearnConteudoService();
  const { conteudoMinistradoData } = conteudoMinistradoDataStore();
  const { setIsGlobalLoading } = useGlobalContext();

  const { curriculo } = useBoxDataTurmaDetalhe();
  const [conteudo, setConteudo] = useState<ICurriculoConteudoDTO[]>([]);

  const id = curriculo?.id;

  const findConteudo = useCallback(async () => {
    setIsGlobalLoading(true);
    if (!id || !conteudoMinistradoData?.tipo) return;

    await fetchConteudoByIdCurriculo(id, conteudoMinistradoData?.tipo)
      .then(({ data }) => {
        setConteudo(data);
      })
      .finally(() => setIsGlobalLoading(false));
  }, [fetchConteudoByIdCurriculo, id, conteudoMinistradoData?.tipo]);

  return {
    conteudo,
    findConteudo,
  };
};
