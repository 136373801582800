import {
  isAfterDate,
  isBeforeDate,
  isDateBetween,
} from "../../../../util/format";

interface useStatusTurmaProps {
  dataInicio: Date;
  dataFim: Date;
}

export default function useStatusTurma({
  dataInicio,
  dataFim,
}: useStatusTurmaProps) {
  let dotColor = "";
  let statusText = "";

  if (isBeforeDate(dataInicio)) {
    dotColor = "dot-orange";
    statusText = "Não Iniciado";
  }
  if (isDateBetween(dataInicio, dataFim)) {
    dotColor = "dot-green";
    statusText = "Em andamento";
  }
  if (isAfterDate(dataFim)) {
    dotColor = "dot-orange";
    statusText = "Finalizado";
  }

  return {
    dotColor,
    statusText,
  };
}
