import { useTurmaService } from "../../../services/turma.service";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { turmaIsEnglishStore } from "../store/turma-is-english.store";

export default function useCheckIfTurmaIsHappyEnglish() {
  const service = useTurmaService();
  const { id } = useParams();

  const { setTurmaIsEnglish } = turmaIsEnglishStore();

  const checkIfTurmaIsHappyEnglish = useCallback(async () => {
    if (!id) return;

    const parseIdInt = parseInt(id);

    await service
      .checkIfTurmaIsHappyEnglish(parseIdInt)
      .then(({ data }) => setTurmaIsEnglish(data));
  }, [id, setTurmaIsEnglish]);

  return {
    checkIfTurmaIsHappyEnglish,
  };
}
