import http from '../helpers/http-common';
import {
  IMatriculaSkillCleanDTO,
  IMatriculaSkillDTO,
  IMatriculaSkillFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useMatriculaSkillService = () => {
  const resourceURL = 'matricula-skill';

  const saveSkills = (idMatricula, body) => {
    return http.post(`${resourceURL}/${idMatricula}`, body);
  };

  return {
    ...baseFind<
      IMatriculaSkillCleanDTO,
      IMatriculaSkillDTO,
      IMatriculaSkillFullDTO
    >(resourceURL),
    ...baseFindList<
      IMatriculaSkillCleanDTO,
      IMatriculaSkillDTO,
      IMatriculaSkillFullDTO
    >(resourceURL),
    ...baseCreate<
      IMatriculaSkillCleanDTO,
      IMatriculaSkillDTO,
      IMatriculaSkillFullDTO
    >(resourceURL),
    ...baseUpdate<
      IMatriculaSkillCleanDTO,
      IMatriculaSkillDTO,
      IMatriculaSkillFullDTO
    >(resourceURL),
    ...baseDelete(resourceURL),
    saveSkills,
  };
};
