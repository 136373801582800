/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalDownload } from '../../components/Modal/download';
import { useGlobalContext } from '../../context/GlobalContext';
import { useAcaArquivoService } from '../../services/aca-arquivo.service';
import { formatBytes } from '../../util/format';
import { useMaterialService } from '../../services/material.service';
import { IMaterialDTO } from '../../models/happy-code-api.model';

interface Props {
  width: string[];
  nameArquivo: string;
  name: string;
  tamanho: string;
  url: string;
  material: boolean;
  tipo: any;
  id: number;
}

export const ItemArquivo = (props: Props) => {
  const arquivoService = useAcaArquivoService();
  const materialService = useMaterialService();
  const [file, setFile] = useState<IMaterialDTO>();
  const { setIsGlobalLoading } = useGlobalContext();

  const { nameArquivo, name, tamanho, width, material, url, tipo, id } = props;
  let botao, text;

  const [isOpen, setIsOpen] = useState(false);

  if (material === true) {
    botao = 'button-primary';
    text = 'Baixar arquivo';
  } else {
    botao = 'button-disable';
    text = 'Baixar arquivo';
  }

  const onChange = () => {
    // if (isOpen === true) {
    //   setIsOpen(false)
    // } else {
    //   setIsOpen(true)
    // } // data.enderecoArquivo será o arquivo que vai ser apresentado na tela

    setIsGlobalLoading(true);
    arquivoService
      .download(url, nameArquivo)
      .then((response) => {
        var data = new Blob([response.data], { type: 'text/csv' });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', nameArquivo);
        tempLink.click();
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const openRenderPdf = () => navigate(`/render-pdf/${id}`);

  const apresentarBotao = () => {
    if (tipo?.codigo === 'link') {
      return (
        <a href={nameArquivo} target="_blank">
          <Button className={botao}>
            {text} <Unicons.UilImport size={15} style={{ marginLeft: 10 }} />
          </Button>
        </a>
      );
    }
    if (tipo?.codigo === 'oficial') {
      return (
        <Button onClick={openRenderPdf} className={botao}>
          Visualizar
        </Button>
      );
    }
    return (
      <Button onClick={onChange} className={botao}>
        {text} <Unicons.UilImport size={15} style={{ marginLeft: 10 }} />
      </Button>
    );
  };

  return (
    <>
      <div className="d-flex-row j-start-a-center w-100 row-items">
        <div
          className="item"
          style={{ width: width[0], display: 'flex', justifyContent: 'center' }}
        >
          <img
            alt="tipo do icone"
            src={process.env.REACT_APP_HAPPY_CODE_LINK_IMAGEM + tipo?.icone}
          />
        </div>
        <div className="item" style={{ width: width[1] }}>{`${id}`}</div>
        <div className="item" style={{ width: width[2] }}>{`${name}`}</div>
        <div className="item" style={{ width: width[4] }}>
          {tipo?.codigo === 'link' ? 'Link' : formatBytes(parseInt(tamanho))}
        </div>
        <div className="item" style={{ width: width[5] }}>
          {apresentarBotao()}
        </div>
      </div>
      <ModalDownload open={isOpen} onClose={onClose} />
    </>
  );
};
