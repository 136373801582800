import { AxiosResponse } from 'axios';
import { formatHttpParamsList, Predicate } from '../../models/predicate.model';
import http from './../../helpers/http-common';

export const baseFindList = <C, D, F>(resourceURL: string) => {
  return {
    findCleanList: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<C[]>> =>
      http.get(`${resourceURL}/list/clean`, formatHttpParamsList(predicate)),
    findList: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<D[]>> =>
      http.get(`${resourceURL}/list`, formatHttpParamsList(predicate)),
    findFullList: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<F[]>> =>
      http.get(`${resourceURL}/list/full`, formatHttpParamsList(predicate)),
  };
};
