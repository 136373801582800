import {
  conteudoMinistradoDataStore
} from '../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import { FormEvent } from 'react';
import {
  ICreateConteudoMinistradoDTO
} from '../../../../../../models/happy-code-api.model';
import {
  useNotificationService
} from '../../../../../../services/notification.service';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useLearnConteudoMinistradoService
} from '../../../../../../services/learn/learn-conteudo-ministrado.service';
import { useGlobalContext } from '../../../../../../context/GlobalContext';

export default function useConteudoMinistradoForm() {
  const { setIsGlobalLoading } = useGlobalContext();
  const service = useLearnConteudoMinistradoService();
  const notificationService = useNotificationService();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    conteudoMinistradoData,
    resetConteudoMinistradoData
  } = conteudoMinistradoDataStore();

  const submitForm = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setIsGlobalLoading(true);

    const formObject = buildFormObject();

    await service
      .createConteudoMinistrado(formObject)
      .then(() => handleSuccess())
      .catch(() => handleError())
      .finally(() => setIsGlobalLoading(false));
  };

  const buildFormObject = (): ICreateConteudoMinistradoDTO => ({
    idTipoConteudo: conteudoMinistradoData.tipo,
    titulo: conteudoMinistradoData.titulo,
    idConteudo: conteudoMinistradoData.conteudo,
    descricao: conteudoMinistradoData.descricao,
    idAula: Number(id)
  });

  const handleSuccess = () => {
    notificationService({
      message: 'Conteúdo Ministrado',
      description: 'Conteúdo Ministrado cadastrado com sucesso',
      type: 'success'
    });

    navigate(`/turmas/professor/lancamento-aula/create/step-2/${id}`);

    resetConteudoMinistradoData();
  };

  const handleError = () => {
    notificationService({
      message: 'Conteúdo Ministrado',
      description: 'Erro ao editar conteúdo ministrado',
      type: 'error'
    });
  };

  return {
    formObject: {
      submitForm
    }
  };
}
