import UserWelcomeCard from '../../components/user-welcome-card/user-welcome-card.component';
import { TrilhaCard } from '../../components/TrilhaCard/trilha-card.component';
import useCursos from './use-cursos.hook';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../components/title/title.component';
import './cursos.styles.scss';
import { Link } from 'react-router-dom';

export const HomeCursos = () => {
  const { destaques } = useCursos();

  return (
    <>
      <SidebarAndContainer cursos={true}>
        <UserWelcomeCard />

        <div className="cursos-wrapper__cursos-title-button">
          <Title title="Trilhas" />

          <Link to="/cursos/all" className="button-line">
            Ver todos os cursos
          </Link>
        </div>

        <div className="cursos-wrapper__cursos-cards-wrapper ">
          {destaques &&
            destaques.map((trilha) => (
              <TrilhaCard key={trilha.id} trilha={trilha} />
            ))}
        </div>
      </SidebarAndContainer>
    </>
  );
};
