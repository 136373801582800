import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { Auth } from '../models/auth.model';

export const useAuthService = () => {
  const resourceURL = 'oauth';

  const login = async (
    login: any,
    noMessage: boolean,
  ): Promise<AxiosResponse<Auth>> => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('username', login.user);
    bodyFormData.append('password', login.password);
    bodyFormData.append('grant_type', 'password');
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_PASSWORD_API}`,
        MensagemPersonalizada: noMessage && 'Validando usuário no Stemplay!',
      },
    };
    return await http.post<Auth>(resourceURL + '/token', bodyFormData, config);
  };

  const basic = async (): Promise<AxiosResponse<Auth>> => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('grant_type', 'client_credentials');

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASIC_API}`,
      },
    };
    return await http.post<Auth>(resourceURL + '/token', bodyFormData, config);
  };

  return { login, basic };
};
