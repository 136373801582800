import { Link } from 'react-router-dom';
import { IConectaDTO } from '../../models/happy-code-api.model';
import { PresentImage } from '../ApresentarImagem';
import './conecta-card.styles.scss';

interface ConectaCardProps {
  conecta: IConectaDTO;
}

export const ConectaCard = ({ conecta }: ConectaCardProps) => {
  const conectaThumbnail = conecta?.capa
    ? conecta?.capa
    : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY +
      conecta?.arquivo?.url;

  return (
    <div className="conecta-card">
      <div className="conecta-card__conecta-image-title">
        <PresentImage link={conectaThumbnail} />
        <h1 className="conecta-image-title__title">{conecta?.nome}</h1>
      </div>

      <Link
        to={`/conecta-apresentacao/${conecta.id}`}
        className="button-primary"
      >
        Acessar conecta
      </Link>
    </div>
  );
};
