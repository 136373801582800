/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { getLocalStorageItem, removeKey, setObject } from '../util/store';
import { ITurmaFullDTO } from './../models/happy-code-api.model';

//Tipando as Props do contexto
interface PropsTurmaContext {
  turma: ITurmaFullDTO;
  revisao: boolean;
  setTurma: React.Dispatch<React.SetStateAction<ITurmaFullDTO>>;
  setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
  removeTurma: React.DispatchWithoutAction;
}

//Valor default do contexto
const DEFAULT_VALUE = {
  turma: {
    id: null,
    codigo: '',
    descricao: '',
    dataInicio: null,
    dataFim: null,
    cursoModalidadeDescricao: null,
    curso: {
      id: null,
      codigo: '',
      descricao: '',
      duracao: null,
      duracaoMinutos: null,
      numeroMaxParcelas: null,
      numeroMinParcelas: null,
      valorDescontoMaximo: null,
      valorDescontoMinimo: null,
      valorMensalidadeMinimo: null,
      nivelAcesso: null,
      ativo: true,
    } as any,
    unidadePessoa: {
      id: null,
      dataAdmissao: null,
      unidadeId: null,
      statusUnidadePessoaId: null,
      pessoaId: null,
      pessoaNome: '',
      tipoUnidadePessoaId: null,
      tipoUnidadePessoaCodigo: null,
      tipoUnidadePessoaDescricao: '',
      ativo: true,
    },
    unidade: {
      id: null,
      codigo: null,
      descricao: null,
      cnpj: null,
      inscricaoEstadual: null,
      codigoCluster: null,
      dataAbertura: null,
      dataContrato: null,
      dataRenovacaoContrato: null,
      dataTerminoContrato: null,
      pendencia: null,
      identificadorPagarMe: null,
      identificadorEnotas: null,
      ativo: true,
    },
    frequencias: [],
    matriculas: [],
    horarios: [],
    aulas: [],
    ativo: true,
  },
  revisao: false,
  setTurma: () => {}, //função de inicialização
  setRevisao: () => {}, //função de inicialização
  removeTurma: () => {}, //função de inicialização
} as any;

//criando nosso contexto UserContext
const TurmaContext = createContext<PropsTurmaContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCodeAcademy/turma';
const nameStorageRevisao = '@HappyCodeAcademy/turma/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const TurmaContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [turma, setTurmaGlobal] = useState<ITurmaFullDTO>(DEFAULT_VALUE.turma);
  const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

  const setTurma = (turma: ITurmaFullDTO) => {
    setTurmaGlobal(turma);
    setObject(nameStorage, turma);
  };

  const setRevisao = (revisao: boolean) => {
    setRevisaoGlobal(revisao);
    setObject(nameStorageRevisao, revisao);
  };

  const removeTurma = () => {
    setRevisao(false);
    setTurmaGlobal(DEFAULT_VALUE.turma);
    removeKey(nameStorage);
  };

  useEffect(() => {
    const turma = getLocalStorageItem(nameStorage);
    if (turma) {
      setTurmaGlobal(turma);
    }
    const revisao = getLocalStorageItem(nameStorageRevisao);
    if (revisao) {
      setRevisaoGlobal(revisao);
    }
  }, []);

  return (
    <TurmaContext.Provider
      value={{
        turma,
        revisao,
        setTurma,
        setRevisao,
        removeTurma,
      }}
    >
      {children}
    </TurmaContext.Provider>
  );
};

export const useTurmaContext = (): PropsTurmaContext => {
  const context = useContext(TurmaContext);
  if (!context)
    throw new Error(
      'useTurmaContext must be used within a TurmaContextProvider',
    );
  return context;
};
