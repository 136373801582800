import http from '../helpers/http-common';
import {
  IAcaAulaCleanDTO,
  IAcaAulaCustomDTO,
  IAcaAulaDTO,
  IAcaAulaFullDTO,
  IAcaAulaInfoDTO,
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
import { AxiosResponse } from 'axios';
import { PageableResponse } from '../models/response.model';

export const useAcaAulaService = () => {
  const resourceURL = 'aca-aula';

  const findByCursoId = (idCurso: number) => {
    return http.get(`${resourceURL}/curso/${idCurso}`);
  };

  const findProximaAula = (idAula: number, idCurso: number) => {
    return http.get(`${resourceURL}/${idAula}/curso/${idCurso}/proxima`);
  };

  const findCustomAula = (
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IAcaAulaCustomDTO>>> => {
    return http.get(`${resourceURL}/conclusao`, formatHttpParams(predicate));
  };

  const findClassroomInfosByCourseId = (
    cursoId: number,
  ): Promise<AxiosResponse<IAcaAulaInfoDTO>> =>
    http.get(`${resourceURL}/info/${cursoId}`);

  return {
    ...baseFind<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
    ...baseFindById<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(
      resourceURL,
    ),
    ...baseFindList<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
    ...baseUpdate<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
    ...baseDelete(resourceURL),
    findByCursoId,
    findProximaAula,
    findCustomAula,
    findClassroomInfosByCourseId,
  };
};
