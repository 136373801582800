import { StatusMatriculaEnum } from "../../../../enum/status-matricula.enum";
import { IStatusMatriculaDTO } from "../../../../models/happy-code-api.model";
import "./status-matricula.css";

interface StatusMatriculaProps {
  codigoStatusMatricula: IStatusMatriculaDTO["codigo"];
}

export default function StatusMatricula({
  codigoStatusMatricula,
}: StatusMatriculaProps) {
  const statusColors = {
    [StatusMatriculaEnum.Prematriculado]: {
      class: "status-matricula__dot-orange",
      description: "Pré-matriculado",
    },
    [StatusMatriculaEnum.Matriculado]: {
      class: "status-matricula__dot-green",
      description: "Matriculado",
    },
    [StatusMatriculaEnum.Cancelado]: {
      class: "status-matricula__dot-orange",
      description: "Cancelado",
    },
    [StatusMatriculaEnum.Concluido]: {
      class: "status-matricula__dot-green",
      description: "Concluido",
    },
  };

  const dot =
    statusColors[codigoStatusMatricula] ||
    statusColors[StatusMatriculaEnum.Cancelado];

  return (
    <>
      <div className="status-matricula">
        <div className={dot.class}></div>
        <p>{dot.description}</p>
      </div>
    </>
  );
}