import { IAcaCursoFullDTO } from '../../../../models/happy-code-api.model';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAcaCursoService } from '../../../../services/aca-curso.service';

export default function useFindCursoById() {
  const { idCurso } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const { findFullById } = useAcaCursoService();

  const [cursoData, setCursoData] = useState<IAcaCursoFullDTO>();

  const findCursoById = useCallback(async () => {
    if (!idCurso) return;

    await findFullById({ id: parseInt(idCurso) }).then(({ data }) =>
      setCursoData(data),
    );
  }, [idCurso]);

  return {
    cursoData,
    findCursoById,
  };
}
