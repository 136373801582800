import { ReactNode } from "react";
import "./container-wrapper.styles.scss";

interface ContainerWrapperProps {
  children: ReactNode;
  hasHeader?: boolean;
}

export default function ContainerWrapper({
  children,
  hasHeader,
}: ContainerWrapperProps) {
  const className: string = hasHeader
    ? "container-wrapper increase-top-padding"
    : "container-wrapper";

  return <div className={className}>{children}</div>;
}
