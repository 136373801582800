import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./select-options.styles.scss";
import { FrequenciaTipoEnum } from "../../../../enum/lancamento-aula/frequencia-tipo.enum";

interface PresencaSelectOptionProps {
  value: FrequenciaTipoEnum;
}

export default function PresencaSelectOption({
  value,
}: PresencaSelectOptionProps) {
  const getPresencaOption = {
    PRE: {
      icon: <CheckCircleOutlined style={{ color: "var(--green-color)" }} />,
      text: "Presente",
      style: { color: "var(--green-color)" },
    },
    ATR: {
      icon: <ClockCircleOutlined style={{ color: "var(--orange-color)" }} />,
      text: "Atrasado",
      style: { color: "var(--orange-color)" },
    },
    AST: {
      icon: <CloseCircleOutlined style={{ color: "var(--red-color)" }} />,
      text: "Ausente",
      style: { color: "var(--red-color)" },
    },
  };

  const presencaOption = getPresencaOption[value];

  return (
    <div className={"select-options-wrapper"}>
      {presencaOption.icon}
      <p style={presencaOption.style}>{presencaOption.text}</p>
    </div>
  );
}
