import * as Unicons from '@iconscout/react-unicons';
import './tags.css';

interface Tag {
  tipo: string;
}

type TagsMaps = {
  [key: string]: {
    className: string;
    icon: React.ReactNode;
    color: string;
    label: string;
  };
};

export const Tags: React.FunctionComponent<Tag> = ({ tipo }: Tag) => {
  const tagMap: TagsMaps = {
    sede: {
      className: 'tag-container-sede',
      icon: <Unicons.UilGlobe size="14" color="#478953" />,
      color: '#478953',
      label: 'Sede',
    },
    admin: {
      className: 'tag-container-admin',
      icon: <Unicons.UilDollarSignAlt size="14" color="#306F75" />,
      color: '#306F75',
      label: 'Administrativo',
    },
    colaborador: {
      className: 'tag-container-colaborador',
      icon: <Unicons.UilWrench size="14" color="#CF6D34" />,
      color: '#CF6D34',
      label: 'Colaborador',
    },
    franquia: {
      className: 'tag-container-franquia',
      icon: <Unicons.UilGlobe size="14" color="#AA5556" />,
      color: '#AA5556',
      label: 'Franquia',
    },
    instrutor: {
      className: 'tag-container-instrutor',
      icon: <Unicons.UilGraduationCap size="14" color="#3771A8" />,
      color: '#3771A8',
      label: 'Instrutor',
    },
    professor: {
      className: 'tag-container-instrutor',
      icon: <Unicons.UilGraduationCap size="14" color="#3771A8" />,
      color: '#3771A8',
      label: 'Professor',
    },
    gestor: {
      className: 'tag-container-gestor',
      icon: <Unicons.UilSetting size="14" color="#895CF2" />,
      color: '#895CF2',
      label: 'Gestor',
    },
    gerencia: {
      className: 'tag-container-gestor',
      icon: <Unicons.UilSetting size="14" color="#895CF2" />,
      color: '#895CF2',
      label: 'Gestor',
    },
    externo: {
      className: 'tag-container-sede',
      icon: <Unicons.UilTrees size="14" color="#478953" />,
      color: '#478953',
      label: 'Externo',
    },
    presencial: {
      className: 'tag-container-colaborador',
      icon: <Unicons.UilUsersAlt size="14" color="#CF6D34" />,
      color: '#CF6D34',
      label: 'Presencial',
    },
    online: {
      className: 'tag-container-gestor',
      icon: <Unicons.UilDesktop size="14" color="#895CF2" />,
      color: '#895CF2',
      label: 'Online',
    },
    híbrido: {
      className: 'tag-container-franquia',
      icon: <Unicons.UilDirection size="14" color="#AA5556" />,
      color: '#AA5556',
      label: 'Híbrido',
    },
    comercial: {
      className: 'tag-container-comercial',
      icon: <Unicons.UilDollarSignAlt size="14" color="#CF6B6C" />,
      color: '#CF6B6C',
      label: 'Comercial',
    },
    games: { className: 'tag-games', icon: null, color: null, label: 'Games' },
    vencido: {
      className: 'tag-container-comercial',
      icon: <Unicons.UilExclamationTriangle size="14" color="#CF6B6C" />,
      color: '#CF6B6C',
      label: 'Vencido',
    },
    pago: {
      className: 'tag-container-sede',
      icon: <Unicons.UilCheck size="14" color="#478953" />,
      color: '#478953',
      label: 'Pago',
    },
    aberto: {
      className: 'tag-container-colaborador',
      icon: <Unicons.UilClockNine size="14" color="#CF6D34" />,
      color: '#CF6D34',
      label: 'Em aberto',
    },
    responsavel: {
      className: 'tag-container-gestor',
      icon: null,
      color: null,
      label: 'Responsável Financeiro',
    },
    youtube: {
      className: 'tag-youtube',
      icon: null,
      color: null,
      label: 'Youtube',
    },
    lancado: {
      className: 'tag-container-sede',
      icon: <Unicons.UilCheck size="14" color="#478953" />,
      color: '#478953',
      label: 'Lançado',
    },
    pendente: {
      className: 'tag-container-comercial',
      icon: <Unicons.UilExclamationTriangle size="14" color="#CF6B6C" />,
      color: '#CF6B6C',
      label: 'Pendente',
    },
    acontecer: {
      className: 'tag-container-colaborador',
      icon: <Unicons.UilClockNine size="14" color="#CF6D34" />,
      color: '#CF6D34',
      label: 'Acontecer',
    },
  };

  const tag = tagMap[tipo?.toLowerCase()];

  if (!tag) {
    return (
      <div className="tag-wrapper tag-container-gestor ">
        <Unicons.UilDesktop size="14" color="#895CF2" />
        <span>{tipo}</span>
      </div>
    );
  }

  const { className, icon, color, label } = tag;

  return (
    <div className={`tag-wrapper ${className}`}>
      {icon && icon}
      <span style={{ color }}>{label}</span>
    </div>
  );
};
