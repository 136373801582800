import { Link } from 'react-router-dom';

interface HamburguerProps {
  isOpen: boolean;
  onClick: () => void;
  menuHamburguerClass: string;
  isPermissionamentoConecta: boolean;
  isPermissionamentoMaterial: boolean;
  isPermissionamentoGestaoSala: boolean;
}

export const Hamburger = ({
  isOpen,
  onClick,
  menuHamburguerClass,
  isPermissionamentoConecta,
  isPermissionamentoMaterial,
  isPermissionamentoGestaoSala,
}: HamburguerProps) => {
  return (
    <>
      <div className="hamburger" onClick={onClick}>
        <div className="burger burger1"></div>
        <div className="burger burger2"></div>
        <div className="burger burger3"></div>
      </div>
      <div className={menuHamburguerClass}>
        <ul>
          <Link to="/instrutor">Instrutor</Link>
          <Link to="/cursos">Cursos</Link>
          <Link to="/webinars/all">Webinars</Link>
          {isPermissionamentoConecta && (
            <Link to="/conectas/all">Conectas</Link>
          )}
          {isPermissionamentoMaterial && (
            <Link to="/materiais">Materiais de apoio</Link>
          )}
          {isPermissionamentoGestaoSala && (
            <Link to="/turmas">Gestão de sala de aula</Link>
          )}
        </ul>
      </div>

      <style>
        {`
        .burger1{
          transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'}
        }

        .burger2{
          transform: ${isOpen ? 'translatex(100%)' : 'translatex(0)'};
          opacity: ${isOpen ? '0' : '1'}
        }

        .burger3{
          transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'}
        }
      `}
      </style>
    </>
  );
};
