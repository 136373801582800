import useFindConteudoMinistrado
  from '../../../../hooks/use-find-conteudo-ministrado.hook';
import { FormEvent, useEffect, useState } from 'react';
import {
  useNotificationService
} from '../../../../../../services/notification.service';
import {
  useLearnConteudoMinistradoService
} from '../../../../../../services/learn/learn-conteudo-ministrado.service';
import {
  conteudoMinistradoDataStore
} from '../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import {
  IUpdateConteudoMinistradoDTO
} from '../../../../../../models/happy-code-api.model';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../../context/GlobalContext';

export default function useConteudoMinistradoWrapper() {
  const { id } = useParams();
  const notification = useNotificationService();
  const { update } = useLearnConteudoMinistradoService();
  const { setIsGlobalLoading } = useGlobalContext();
  useFindConteudoMinistrado();

  const { conteudoMinistradoData } = conteudoMinistradoDataStore();

  const [formIsDisabled, setFormIsDisabled] = useState(true);

  const toggleFormDisabled = () => setFormIsDisabled(!formIsDisabled);
  

  const submitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    
  
    const payload = buildPayload();
    setIsGlobalLoading(true);

    update(payload)
      .then(() => {
        toggleFormDisabled();

        notification({
          message: 'Conteúdo Ministrado',
          description: 'Conteúdo Ministrado editado com sucesso',
          type: 'success'
        });
      })
      .finally(() =>{
        setIsGlobalLoading(false)        
      });
  };

  const buildPayload = (): IUpdateConteudoMinistradoDTO => ({
    titulo: conteudoMinistradoData?.titulo,
    idAula: Number(id),
    idConteudo: conteudoMinistradoData?.conteudo,
    descricao: conteudoMinistradoData?.descricao
  });

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false); 
    };
  }, []);
  return {
    formObject: {
      submitForm,
      buttonLabel: 'Editar conteúdo',
      formIsDisabled
    },
    toggleFormDisabled
  };
}
