import {
  IAlunoObservacaoCleanDTO,
  IAlunoObservacaoDTO,
  IAlunoObservacaoFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAlunoObservacaoService = () => {
  const resourceURL = 'aluno-observacao';

  return {
    ...baseFind<
      IAlunoObservacaoCleanDTO,
      IAlunoObservacaoDTO,
      IAlunoObservacaoFullDTO
    >(resourceURL),
    ...baseCreate<
      IAlunoObservacaoCleanDTO,
      IAlunoObservacaoDTO,
      IAlunoObservacaoFullDTO
    >(resourceURL),
    ...baseUpdate<
      IAlunoObservacaoCleanDTO,
      IAlunoObservacaoDTO,
      IAlunoObservacaoFullDTO
    >(resourceURL),
    ...baseDelete(resourceURL),
  };
};
