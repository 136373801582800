import * as Unicons from '@iconscout/react-unicons';
import { Button, Form, Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemAula } from '../../components/Item/aula';
import { Pagination } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import {
  ICursoAulaPadraoDTO,
  ICursoDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoAulaPadraoService } from '../../services/curso-aula-padrao.service';
import { useCursoService } from '../../services/curso.service';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const MateriaisAula = () => {
  const { setIsGlobalLoading } = useGlobalContext();
  const cursoAulaPadraoService = useCursoAulaPadraoService();
  const cursoService = useCursoService();
  const predicate = new Predicate();
  const { idCurso } = useParams<any>();
  const [aulas, setAulas] = useState<ICursoAulaPadraoDTO[]>();
  const [curso, setCurso] = useState<ICursoDTO>();
  const [response, setResponse] =
    useState<PageableResponse<ICursoAulaPadraoDTO>>();

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    predicate.removeAllOption();

    const typingTimeout = setTimeout(() => {
      if (search) {
        predicate.addOption('descricao', search, Operators.CONTAINS);
      }

      fetch();
    }, 1200);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetch();
  }, [idCurso]);

  const fetch = () => {
    if (!idCurso) return;

    const parseIdCursoToNumber = parseInt(idCurso);

    predicate.addOption('curso.id', idCurso);
    setIsGlobalLoading(true);

    cursoAulaPadraoService
      .find(predicate)
      .then((response) => {
        setAulas(response?.data?.content);
        setResponse(response.data);
      })
      .then(() => cursoService.findById({ id: parseIdCursoToNumber }))
      .then((response) => {
        setCurso(response.data);
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    fetch();
  };

  const navigate = useNavigate();

  return (
    <>
      <SidebarAndContainer materiais={true}>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Button className="button-blue" onClick={() => navigate(-1)}>
              <Unicons.UilArrowLeft />
              Voltar
            </Button>
          </div>
          <div className="d-flex-row j-start-a-center mb-20">
            {/* <img src={curso?.capaCurso} alt="curso" className="imageCurso" /> */}
            <div>
              <h1>
                {`${curso?.descricao || ''}`} {aulas?.length || 0} aula(s)
              </h1>
              {/* <h5>5 turmas</h5> */}
              {/* <h5>1020 alunos novos</h5> */}
            </div>
          </div>
          {/* <p className="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper integer ut amet purus. Convallis risus urna, arcu adipiscing tincidunt. Diam leo arcu phasellus consequat orbi risus ultrices consequat eu. Ac venenatis tempor justo, morbi turpis nulla pretium. Tortor semper sit egestas tortor sem. </p> */}
          <Form className="d-flex-row j-start-a-center w-100 mb-10">
            <div className="d-flex-row j-start-a-center input-box w-100">
              <Unicons.UilSearch
                size="20"
                color="var(--dark-secondary)"
                className="iconForm"
              />
              <Form.Item name="search" noStyle={true}>
                <Input
                  placeholder="Buscar por aula"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Form.Item>
            </div>
          </Form>
          <div className="w-100 mb-20 table">
            <ul className="d-flex-row j-start-a-center table-head w-100">
              <li style={{ width: '10%' }}>
                <span>ID</span>
                <Unicons.UilDirection size={20} />
              </li>
              <li style={{ width: '90%' }}>
                <span>Aula</span>
                <Unicons.UilDirection size={20} />
              </li>
            </ul>
            <div className="w-100">
              {aulas?.map((item) => {
                return (
                  <ItemAula
                    material={true}
                    width={['12%', '90%']}
                    id={item?.id?.toString()}
                    aula={item?.descricao}
                    url={`/materiais/aula/${item?.id}/arquivos`}
                  />
                );
              })}
              {/* <ItemAula material={false} width={['10%', '90%']} id="#0002" aula="Introdução aos tópicos" url="/materiais/aula/arquivos" /> */}
              {/* <ItemAula material={true} width={['10%', '90%']} id="#0003" aula="Introdução aos tópicos" url="/materiais/aula/arquivos" /> */}
              {/* <ItemAula material={true} width={['10%', '90%']} id="#0004" aula="Revisão do capítulo 1" url="/materiais/aula/arquivos" /> */}
              {/* <ItemAula material={false} width={['10%', '90%']} id="#0005" aula="Introdução aos tópicos" url="/materiais/aula/arquivos" /> */}
            </div>
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination
              onChange={(event, page) => onPageChange(event, page - 1)}
              page={response ? response.pageable.pageNumber + 1 : 0}
              count={response ? response.totalPages : 0}
            />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
