import {
  IFrequenciaCleanDTO,
  IFrequenciaDTO,
  IFrequenciaFullDTO,
} from '../models/happy-code-api.model';
import { baseCount } from './base/base-count.service';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useFrequenciaService = () => {
  const resourceURL = 'frequencia';

  return {
    ...baseFind<IFrequenciaCleanDTO, IFrequenciaDTO, IFrequenciaFullDTO>(
      resourceURL,
    ),
    ...baseFindList<IFrequenciaCleanDTO, IFrequenciaDTO, IFrequenciaFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IFrequenciaCleanDTO, IFrequenciaDTO, IFrequenciaFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IFrequenciaCleanDTO, IFrequenciaDTO, IFrequenciaFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<IFrequenciaCleanDTO, IFrequenciaDTO, IFrequenciaFullDTO>(
      resourceURL,
    ),
    ...baseCount(resourceURL),
  };
};
