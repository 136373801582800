import * as Unicons from '@iconscout/react-unicons';
import avatarImg from '../../assets/img/sidebar/avatar.png';

interface Props {
  tipo?: string;
  size?: number;
  base64?: string;
  arquivo?: any;
  flHeader?: boolean;
}

export const Avatar = (props: Props) => {
  const { tipo, size, base64, arquivo } = props;

  const getUrl = () => {
    if (base64) {
      return `data:image/png;base64,${base64}`;
    }
    return `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${arquivo.url}?name=${arquivo.codigo}`;
  };

  if (base64 || arquivo) {
    return (
      <div>
        {arquivo || base64 ? (
          <img
            style={{
              borderRadius: '50%',
              height: size ?? 40,
              width: size ?? 40,
            }}
            src={getUrl()}
            alt={'avatar'}
            className="avatar"
          />
        ) : (
          <img src={avatarImg} alt={'avatar'} className="avatar" />
        )}
      </div>
    );
  }

  if (tipo === 'user') {
    return (
      <div>
        <img
          src={avatarImg}
          style={{ borderRadius: '50%', height: size ?? 40, width: size ?? 40 }}
          alt={'avatar'}
          className="avatar"
        />
      </div>
    );
  }

  return (
    <div className="d-flex-row j-center-a-center user-default">
      <Unicons.UilUser color="var(--secondary-color)" size={24} />
    </div>
  );
};
