import { create } from 'zustand';
import { IAulaInformacaoBoxDTO } from '../../../models/happy-code-api.model';

type Store = {
  aulaInformacaoBox: IAulaInformacaoBoxDTO;
  setAulaInformacaoBox: (
    data: IAulaInformacaoBoxDTO
  ) => void;
};

export const useAulaInformacaoBoxStore = create<Store>((set) => ({
  aulaInformacaoBox: null,
  setAulaInformacaoBox: (data) =>
    (set({ aulaInformacaoBox: data }))
}));
