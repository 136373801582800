import { Button, Position, Tooltip } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {
  HighlightArea,
  MessageIcon,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
} from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import React, { useState } from 'react';
import './_style.scss';
interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
}
const Anottations = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [message, setMessage] = useState('');
  let noteId = notes.length;

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
    const { selectionRegion } = props;
    if (
      !selectionRegion ||
      selectionRegion.left < 0 ||
      selectionRegion.top < 0
    ) {
      return null;
    }
    setTimeout(() => {
      if (
        !selectionRegion ||
        selectionRegion.left < 0 ||
        selectionRegion.top < 0
      ) {
        return;
      }
    }, 1000);
    return (
      <div
        className="selection-region"
        style={{
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={props.toggle}>
              <MessageIcon />
            </Button>
          }
          content={() => (
            <div style={{ width: '100px' }}>Adicionar anotação</div>
          )}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    const addNote = () => {
      if (message.trim() !== '') {
        const note: Note = {
          id: ++noteId,
          content: message,
          highlightAreas: props.highlightAreas,
          quote: props.selectedText,
        };
        setNotes((prevNotes) => [...prevNotes, note]);
        props.cancel();
      }
    };

    return (
      <div
        style={{
          background: '#fff',
          border: '1px solid rgba(0, 0, 0, .3)',
          borderRadius: '2px',
          padding: '8px',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          zIndex: 1,
        }}
      >
        <div>
          <textarea
            rows={3}
            style={{
              border: '1px solid rgba(0, 0, 0, .3)',
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '8px',
          }}
        >
          <div style={{ marginRight: '8px' }}>
            <Button onClick={addNote}>Adicionar anotação</Button>
          </div>
          <Button onClick={props.cancel}>Cancelar</Button>
        </div>
      </div>
    );
  };
  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {notes?.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            // Filter all highlights on the current page
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: 'yellow',
                    opacity: 0.4,
                  },
                  props.getCssProperties(area, props.rotation),
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const sidebarNotes = (
    <div className="container-notes">
      {notes?.length === 0 && <div>Nenhuma anotação adicionada</div>}
      {notes?.map((note) => {
        return (
          <div className="note" key={note.id}>
            <div className="note-header">
              <h3>
                Texto selecionado:{' '}
                {note.quote.length > 100
                  ? note.quote.substring(0, 100) + '...'
                  : note.quote}
              </h3>
            </div>
            <div className="note-content">Anotação: {note.content}</div>
          </div>
        );
      })}
    </div>
  );

  return {
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0],
      {
        content: sidebarNotes,
        icon: <MessageIcon />,
        title: 'Anotações',
      },
    ],
  };
};
export default Anottations;
