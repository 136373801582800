import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import { Tag } from '../../../components/Tag';
import SidebarAndContainer from '../../../components/sidebar-and-container/sidebar-and-container.component';
import useAllCursos from './use-all-cursos.hook';
import TrilhaAccordion from './components/trilha-accordion/trilha-accordion.component';
import Title from '../../../components/title/title.component';
import './all-cursos.styles.scss';

export const CursosAll = () => {
  const {
    trilhasData,
    setSearchValue,
    searchValue,
    paginatedTrilhas,
    onPageChange,
    trilhaFilter,
    setTrilhaFilter,
  } = useAllCursos();

  return (
    <SidebarAndContainer cursos={true}>
      <div className="all-cursos-container__all-cursos-title-back-button">
        <Link className="button-second" to="/cursos">
          <Unicons.UilArrowLeft />
          Voltar
        </Link>

        <Title title={`Trilhas (${trilhasData?.length || 0})`} />
      </div>

      <div className="all-cursos-container__curso-search-bar">
        <i className={'curso-search-bar__icon'}>
          <Unicons.UilSearch size="24" color="var(--dark-secondary)" />
        </i>
        <input
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Buscar trilha pelo nome do curso"
          className="curso-search-bar__search-bar-input"
        />
      </div>

      <div className="all-cursos-container__cursos-tag-filter">
        <label>Buscar por tags</label>
        <div className="cursos-tag-filter__tags-container">
          <Tag
            onClick={() => setTrilhaFilter(null)}
            cor2="#808080"
            name="todas"
            ativo={!trilhaFilter}
          />
          {trilhasData &&
            trilhasData.map((trilha) => {
              const { nome1, nome2, corNome1, corNome2, id } = trilha;

              const nome = `${nome1} ${nome2}`.toLocaleLowerCase();

              return (
                <Tag
                  key={trilha.id}
                  cor1={corNome1}
                  cor2={corNome2}
                  name={nome}
                  ativo={trilha && trilhaFilter && id === trilhaFilter.id}
                  onClick={() => setTrilhaFilter(trilha)}
                />
              );
            })}
        </div>
      </div>

      <div className="all-cursos-container__trilha-container">
        {paginatedTrilhas &&
          paginatedTrilhas.content.map((trilha) => (
            <TrilhaAccordion
              trilha={trilha}
              searchValue={searchValue}
              key={trilha.id}
            />
          ))}
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={paginatedTrilhas ? paginatedTrilhas.pageable.pageNumber + 1 : 0}
          count={paginatedTrilhas ? paginatedTrilhas.totalPages : 0}
        />
      </div>
    </SidebarAndContainer>
  );
};
