import { Steps } from "antd";
import "./steps-box.styles.scss";

interface StepsBoxProps {
  title: string;
  current: number;
}

export default function StepsBox({ title, current }: StepsBoxProps) {
  const { Step } = Steps;

  return (
    <div className={"steps-box__wrapper"} style={{ width: "100%" }}>
      <h5>{title}</h5>
      <Steps direction={"vertical"} current={current}>
        <Step title={"Conteúdo Ministrado"} />
        <Step title={"Frequências & Skills"} />
      </Steps>
    </div>
  );
}
