import { useNavigate } from 'react-router-dom';
import './sidebar-item.scss';

interface SidebarItemProps {
  menuIsOpen: boolean;
  link?: string;
  icon: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  label: string;
}
export default function SidebarItem({
  menuIsOpen,
  link,
  icon,
  active,
  onClick,
  label,
}: SidebarItemProps) {
  const navigate = useNavigate();

  const sidebarItemClick = () => (onClick ? onClick() : navigate(link));

  return (
    <button
      onClick={sidebarItemClick}
      className={`${
        active && 'sidebar-closed-menu-item-actived'
      } sidebar-closed-menu-item`}
    >
      <i className="sidebar-item__icon">{icon}</i>
      {menuIsOpen && <span className="menu-item__label">{label}</span>}
    </button>
  );
}
