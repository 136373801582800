import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IWebinarCleanDTO,
  IWebinarDTO,
  IWebinarFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
import { PageableResponse } from '../models/response.model';

export const useAcaWebinarService = () => {
  const resourceURL = '/webinar';

  const editarArquivo = (
    webinarId: number,
    body: any,
  ): Promise<IAxiosResponseCustom<IWebinarDTO>> =>
    http.patch(`${resourceURL}/${webinarId}`, body);

  const editarArquivoCustom = (
    webinarId: number,
    body: any,
  ): Promise<IAxiosResponseCustom<IWebinarDTO>> =>
    http.patch(`${resourceURL}/custom/${webinarId}`, body);

  const findFullCustom = (
    acessoB2c: boolean,
    acessoB2b: boolean,
    acessoComercial: boolean,
    predicate: Predicate = new Predicate(),
  ): Promise<AxiosResponse<PageableResponse<IWebinarFullDTO>>> =>
    http.get<PageableResponse<IWebinarFullDTO>>(
      resourceURL +
        `/webinar-lista/${acessoB2c}/${acessoB2b}/${acessoComercial}`,
      formatHttpParams(predicate),
    );

  return {
    ...baseFind<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
    ...baseFindList<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
    ...baseUpdate<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
    editarArquivo,
    editarArquivoCustom,
    findFullCustom,
  };
};
