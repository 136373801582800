import { ChangeEvent } from "react";
import { PageableResponse } from "../../../../models/response.model";
import { IAlunosTableDTO } from "../../../../models/happy-code-api.model";
import "./aluno-table.css";
import useAlunoTable from "./use-aluno-table.hook";
import GenericPageableTable from "../../../../components/GenericPageableTable/generic-pageable-table.component";
import { AlunoTableColumns } from "./aluno-table.columns";

interface AlunoTableProps {
  alunosTableData: PageableResponse<IAlunosTableDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
}

export default function AlunoTable({
  alunosTableData,
  onPageChange,
}: AlunoTableProps) {
  const { handledAlunoTableData } = useAlunoTable({ alunosTableData });

  return (
    <>
      <GenericPageableTable
        data={handledAlunoTableData}
        columns={AlunoTableColumns}
        onPageChange={onPageChange}
      />
    </>
  );
}
