export default function useTableColumns() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const tableColumns = [
    {
      field: 'nmTurma',
      headerName: 'Nome da Turma',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.2,
    },
    {
      field: 'nmCurso',
      headerName: 'Curso',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.2,
    },
    {
      field: 'dsModalidade',
      headerName: 'Modalidade',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.1,
    },
    {
      field: 'nmInstrutor',
      headerName: 'Instrutor',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.1,
    },
    {
      field: 'dtInicio',
      headerName: 'Início',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.1,
    },
    {
      field: 'dtTermino',
      headerName: 'Término',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.1,
    },
    {
      field: 'botoes',
      headerName: '',
      width:
        getWindowDimensions().width < 800
          ? 450
          : getWindowDimensions().width * 0.1,
    },
  ];

  return {
    tableColumns,
  };
}
