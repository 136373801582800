import { useGlobalContext } from "../../../../context/GlobalContext";
import { useCallback, useEffect } from "react";
import { useAulaService } from "../../../../services/aula.service";
import { useParams } from "react-router-dom";
import { useAulaInformacaoBoxStore } from "../../store/use-aula-informacao-box.store";

export default function useAulaInformacaoBox() {
  const { id } = useParams();
  const { setIsGlobalLoading } = useGlobalContext();
  const { findAulaInformacaoBox } = useAulaService();
  const { aulaInformacaoBox, setAulaInformacaoBox } =
    useAulaInformacaoBoxStore();

  const getAulaInformacaoBox = useCallback(async () => {
    if (!id) return;

    await findAulaInformacaoBox(parseInt(id)).then(({ data }) =>
      setAulaInformacaoBox(data),
    );
  }, [id]);

  useEffect(() => {
    const controller = new AbortController();

    setIsGlobalLoading(true);

    getAulaInformacaoBox().finally(() => setIsGlobalLoading(false));

    return () => controller.abort();
  }, [setIsGlobalLoading, getAulaInformacaoBox]);

  const alunoLabel = {
    0: "Nenhum aluno ativo",
    1: `${aulaInformacaoBox?.quantidadeAlunos} aluno ativo`,
  };

  const getQuantidadeAlunosLabel =
    alunoLabel[aulaInformacaoBox?.quantidadeAlunos] ||
    `${aulaInformacaoBox?.quantidadeAlunos} alunos matriculados`;


  return {
    getQuantidadeAlunosLabel,
    aulaInformacaoBox,
  };
}
