import useFindAulasCustom from './hooks/use-find-aulas-custom.hook';
import useFindTrilhaById from './hooks/use-find-trilha-by-id.hook';
import useFindCursoById from './hooks/use-find-curso-by-id.hook';
import useAulasPagination from './hooks/use-aulas-pagination.hook';
import useFindUserProgress from './hooks/use-find-user-progress.hook';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function useCursosAulas() {
  const { setIsGlobalLoading } = useGlobalContext();

  const { trilhaData, findTrilhaById } = useFindTrilhaById();
  const { cursoData, findCursoById } = useFindCursoById();
  const { aulasData, setSearch, findAulasCustom } = useFindAulasCustom();
  const { userProgress, findUserProgress } = useFindUserProgress();

  const { aulasPageChange, currentPage, countOfPages } = useAulasPagination({
    findAulasCustom,
    aulasData,
  });

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      findTrilhaById(),
      findCursoById(),
      findAulasCustom(),
      findUserProgress(),
    ]).finally(() => setIsGlobalLoading(false));

    return () => controller.abort();
  }, [
    findTrilhaById,
    findCursoById,
    findAulasCustom,
    findUserProgress,
    setIsGlobalLoading,
  ]);

  const navigate = useNavigate();
  const voltarAction = () => navigate(`/cursos/trilha/${trilhaData?.id}`);

  return {
    trilhaData,
    cursoData,
    aulasData,
    userProgress,
    findAulasCustom,
    setSearch,
    aulasPageChange,
    currentPage,
    countOfPages,
    voltarAction,
  };
}
