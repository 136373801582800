import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../context/GlobalContext";
import { useUsuarioCursoService } from "../../../../services/usuario-curso.service";
import { IUserAuthDTO } from "../../../../models/happy-code-api.model";

export default function useFindStatusTrilhaById() {
  const {idTrilha} =  useParams<any>();
  const usuarioCursoService = useUsuarioCursoService();
  const {setIsGlobalLoading} = useGlobalContext();

  const findTrilhaProgress = async (usuario: IUserAuthDTO) => {
    setIsGlobalLoading(true);
    try {
      const { data } = await usuarioCursoService.getTrilhaConclusao(parseInt(idTrilha), usuario.id);
      return data; 
    } catch (error) {
      console.error(error);
      return null; 
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return {
    findTrilhaProgress,
  };
}
