import SidebarAndContainer from "../../components/sidebar-and-container/sidebar-and-container.component";
import { useGlobalContext } from "../../context/GlobalContext";
import FerramentasB2C from "./ferramentasb2c/ferramentas-b2c";
import FerramentasB2B from "./ferramentasb2b/ferramentas-b2b";

export default function Ferramentasb2() {
  const {usuario} = useGlobalContext();

  const renderFerramentas = () => {
    if (usuario.acessoB2b && !usuario.acessoB2c) {
      return <FerramentasB2B />;
    }

    if (!usuario.acessoB2b && usuario.acessoB2c) {
      return <FerramentasB2C />;
    }
      return (
        <>
          <FerramentasB2B />
          <FerramentasB2C />
        </>
      );
    }

return(
  <SidebarAndContainer ferramentas={true}>
    {renderFerramentas()}
  </SidebarAndContainer>
)
}