import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
  IUnidadeCleanDTO,
  IUserAuthDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useAuthService } from '../../../services/auth.service';
import { useNotificationService } from '../../../services/notification.service';
import { useStemplayService } from '../../../services/stemplay-login';
import { useUsuarioUnidadeService } from '../../../services/usuario-unidade.service';
import { useUsuarioService } from '../../../services/usuario.service';
import { clear } from '../../../util/store';
import { usePessoaIdStore } from '../../../store/global/pessoa-id.store';
import { getLocalStorageItem } from '../../../util/store';
import { AuthEnum } from '../../../enum/auth.enum';


const useLoginComponentState = () => {
  const [state, setState] = useState({
    flRealizandoLogin: false,
    open: false,
  });
  const { setUsuario, setToken, setIsGlobalLoading, setUnidade } =
    useGlobalContext();
  const authService = useAuthService();
  const userService = useUsuarioService();
  const navigate = useNavigate();
  const notification = useNotificationService();
  const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);
  const usuarioUnidadeService = useUsuarioUnidadeService();
  const [flLembreme, setFlLembreme] = useState(false);
  const stemplayService = useStemplayService();

  const onRealizarLogin = async (login) => {
    const bodyLogin = {
      username: login.user,
      password: login.password,
    };
    setState((state) => ({ ...state, flRealizandoLogin: true }));
    clear();
    if (flLembreme) {
      localStorage.username = login.username;
      localStorage.password = login.password;
    }

    try {
      const { data } = await authService.login(login, true);

      setToken(data);
      const tokenStorage = getLocalStorageItem(AuthEnum.TOKEN_KEY);
      
      if (tokenStorage) {
        const { data: usuario } = await userService.getPrincipal();
        fetchNomeUsuario(usuario);
      }
    } catch {
      const { data: token } = await authService.basic();
      setToken(token);

      await stemplayService.stemplayLogin(bodyLogin);

      const { data } = await authService.login(login, false);
      setToken(data);
      const tokenStorage = getLocalStorageItem(AuthEnum.TOKEN_KEY);

      if (tokenStorage) {
        const { data: usuario } = await userService.getPrincipal();
        fetchNomeUsuario(usuario);
      }
    } finally {
      setState((state) => ({ ...state, flRealizandoLogin: false }));
    }
  };

  const usuarioFranquia = async (data: IUserAuthDTO) => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    predicate.addOption('usuario.id', data.id);

    await usuarioUnidadeService.findList(predicate).then(({ data }) => {
      if (data.length === 1) {
        setUnidade(data[0].unidade);
        navigate('/instrutor');
      } else if (data.length > 1) {
        onToggle();
        const unidades = data.map((usuarioUnidade) => usuarioUnidade.unidade);
        setUnidades(unidades);
      } else {
        notification({
          type: 'error',
          message: 'Falha!',
          description: 'Usuário não possui acesso a Franquias',
        });
      }
    });
  };

  const { setPessoaId } = usePessoaIdStore();

  const fetchNomeUsuario = async (userAuth: IUserAuthDTO) => {
    userService
      .findById({ id: userAuth.id })
      .then(async ({ data }) => {
        // if (userAuth.funcao !== "IST") {
        //   notification({ type: 'error', message: "Falha!", description: 'Acesso permitido apenas para instrutores' });
        //   return;
        // }

        userAuth.usuario = data.pessoa.nome;
        setUsuario(userAuth);
        setPessoaId(data.pessoa.id);

        if (userAuth.perfil === 'SEDE') {
          navigate('/instrutor');
          setIsGlobalLoading(false);
        } else await usuarioFranquia(userAuth);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onClickUnidade = (unidade: IUnidadeCleanDTO) => {
    setUnidade(unidade);
    navigate('/instrutor');
  };

  const onToggle = () => setState((state) => ({ ...state, open: !state.open }));

  const optionsUnidade = unidades.sort(compare);

  function compare(a: IUnidadeCleanDTO, b: IUnidadeCleanDTO) {
    if (a.descricao < b.descricao) {
      return -1;
    }
    if (a.descricao > b.descricao) {
      return 1;
    }
    return 0;
  }

  return {
    state,
    onRealizarLogin,
    unidades: optionsUnidade,
    onClickUnidade,
    onToggle,
    flLembreme,
    setFlLembreme,
  };
};

export default useLoginComponentState;