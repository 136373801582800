import { ArrowLeftOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../../../components/Sidebar';
import '../../../../global.css';
import useSkillComponentState from './selectors/skills.component.state';
import './styles/style.css';
import { Avatar } from '../../../../components/Avatar';

export const TurmasSkills: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    aluno,
    tipoSkillAtual,
    skills,
    onChangeSkill,
    isChecked,
    onSalvarEirParaProximo,
    flModalAberto,
    fecharModal,
    onAbrirModal,
    flAlgumNaoSelecionado,
    proximoTipoSkill,
    flSalvando,
    flFetching,
    paginaAtualSkill,
    indexAtual,
    pessoa,
  } = useSkillComponentState();

  const renderDialog = () => (
    <Dialog
      open={flModalAberto}
      onClose={fecharModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Atenção'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ao clicar em aceitar, as Skills serão salvas e não poderão ser
          alteradas. Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={fecharModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSalvarEirParaProximo} color="primary" autoFocus>
          Sim, continuar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderConteudo = () => {
    const isConcluido = !flFetching && !proximoTipoSkill && !(indexAtual >= 0);

    return (
      <>
        <div id="content-skills">
          <div id="container-header-skill">
            {!isConcluido && (
              <div id="content-skills-questionario-informacao">
                <span className="mensagem-sobre-skill">
                  Preencha os Skills do aluno referentes à:{' '}
                </span>
                <span className="mensagem-sobre-skill texto-destacado">
                  {tipoSkillAtual?.descricao}
                </span>
              </div>
            )}

            {isConcluido && (
              <div id="content-skills-questionario-informacao">
                <span className="mensagem-sobre-skill">
                  Tudo certo! As Skills foram{' '}
                  <span className="texto-destacado">preenchidas</span>!{' '}
                </span>
              </div>
            )}

            {flFetching && (
              <div id="content-skills-questionario-informacao">
                <span className="mensagem-sobre-skill">
                  Carregando dados...{' '}
                </span>
              </div>
            )}

            <div id="content-skills-dados-aluno">
              <div className="separator" />

              <div className="profile">
                <Avatar arquivo={pessoa?.foto} size={115} />
                {/* <Unicons.UilAngleDown size="20" color="#92A9CB" className="angle-down" /> */}
              </div>

              <div id="container-dados-aluno">
                <h1>{aluno?.pessoa?.nome}</h1>
                <h6 style={{ textAlign: 'start' }}>{aluno?.pessoa?.email}</h6>
              </div>
            </div>
          </div>
          <div className="separator-horizontal"></div>

          {!isConcluido && (
            <div id="content-skills-questionario-conteudo">
              {skills?.map((skill) => {
                return (
                  <div className="container-item-skill" key={skill.id}>
                    <p className="skill-descricao">{skill?.descricao}</p>
                    <div className="container-alternativas">
                      <Checkbox
                        id={skill?.id?.toString?.()}
                        value={0}
                        onChange={(e) =>
                          onChangeSkill(e.target.checked, 'Nunca', skill)
                        }
                        disabled={false}
                        checked={isChecked('Nunca', skill)}
                      >
                        Nunca
                      </Checkbox>

                      <Checkbox
                        id={skill?.id?.toString?.()}
                        value={1}
                        onChange={(e) =>
                          onChangeSkill(e.target.checked, 'Raramente', skill)
                        }
                        checked={isChecked('Raramente', skill)}
                      >
                        Raramente
                      </Checkbox>

                      <Checkbox
                        id={skill?.id?.toString?.()}
                        value={2}
                        onChange={(e) =>
                          onChangeSkill(e.target.checked, 'Às vezes', skill)
                        }
                        checked={isChecked('Às vezes', skill)}
                      >
                        Às vezes
                      </Checkbox>
                      <Checkbox
                        id={skill?.id?.toString?.()}
                        value={3}
                        onChange={(e) =>
                          onChangeSkill(
                            e.target.checked,
                            'Frequentemente',
                            skill,
                          )
                        }
                        checked={isChecked('Frequentemente', skill)}
                      >
                        Frequentemente
                      </Checkbox>

                      <Checkbox
                        id={skill?.id?.toString?.()}
                        value={4}
                        onChange={(e) =>
                          onChangeSkill(e.target.checked, 'Quase sempre', skill)
                        }
                        checked={isChecked('Quase sempre', skill)}
                      >
                        Quase sempre
                      </Checkbox>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {!isConcluido && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <span>Página {paginaAtualSkill}</span>
            </div>
          )}
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ display: 'flex' }}>
            {!!tipoSkillAtual && (
              <Button
                className={
                  flAlgumNaoSelecionado ? 'button-inative' : 'button-primary'
                }
                onClick={() => !flSalvando && onAbrirModal()}
                disabled={flAlgumNaoSelecionado}
              >
                {flSalvando ? 'Salvando...' : 'Próximo'}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderWrapper = () => {
    return renderConteudo();
  };

  return (
    <div id="skills" className="container">
      <Sidebar instrutor={true} />
      <div
        className="franquia"
        id="aluno-detalhe-row"
        style={{ backgroundColor: '#F5F5F9' }}
      >
        <div
          className="franquia-row"
          id="row-aluno-box"
          style={{ marginTop: 70 }}
        >
          <div className="head-botoes" id="detalhe-turma">
            <Button
              onClick={() => navigate(-1)}
              className="button-second"
              icon={<ArrowLeftOutlined />}
            >
              Voltar
            </Button>
            <h5 style={{ marginLeft: 15 }}>Skill(s) de aluno</h5>
          </div>

          {renderDialog()}

          {renderWrapper()}
        </div>
      </div>
    </div>
  );
};
