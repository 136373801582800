/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ITrilhaFullDTO, IUnidadeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useTrilhaService } from '../../services/trilha.service';
import { useUnidadeService } from '../../services/unidade.service';

const useCursos = () => {
  const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();

  const predicate = new Predicate();

  const trilhaService = useTrilhaService();
  const unidadeService = useUnidadeService();

  const [destaques, setDestaques] = useState<ITrilhaFullDTO[]>();


  const findUnidade = useCallback(async () => {
    setIsGlobalLoading(true);
    if (unidade?.id) {
      await unidadeService
        .findById({ id: unidade.id })
        .then(({ data }) => {
          findCursos(data);
        })
        .catch(() => setIsGlobalLoading(false));
    } else {
      findCursos(null);
    }
  }, [unidade]);
  const findCursos = useCallback(async (UnidadeDTO: IUnidadeDTO) => {    
    predicate.addOption('ativo', true);
    
    await trilhaService
      .findDestaques(
        usuario?.acessoB2c,
        usuario?.acessoB2b,
        usuario?.acessoComercial,
        predicate,
        UnidadeDTO?.tipoUnidade?.id ?? 0
      )
      .then(({ data }) => setDestaques(data))
      .finally(() => setIsGlobalLoading(false));
  }, [
    setIsGlobalLoading,
    usuario?.acessoB2b,
    usuario?.acessoB2c,
    usuario?.acessoComercial,
  ]);

  useEffect(() => {
      findUnidade();
  }, [findUnidade]);

  return {
    destaques,
  };
};

export default useCursos;
