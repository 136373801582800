import { useAulaService } from '../../../services/aula.service';
import {
  IRelatorioFrequenciaSkillsAulaDTO
} from '../../../models/happy-code-api.model';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { listAccordionFormStore } from '../store/list-accordion-form.store';

export default function useFindRelatorioFrequencias() {
  const { id } = useParams();
  const { findRelatorioFrequencias } = useAulaService();

  const { initializeListAccordionForm } = listAccordionFormStore();

  const [relatorioFrequencias, setRelatorioFrequencias] = useState<
    IRelatorioFrequenciaSkillsAulaDTO[]
  >([]);

  const getRelatorioFrequencias = useCallback(async () => {
    const parsedId = Number.parseInt(id);

    await findRelatorioFrequencias(parsedId)
      .then(({ data }) => {
        setRelatorioFrequencias(data);
        initializeListAccordionForm(data);
      });

  }, [id, findRelatorioFrequencias]);

  return {
    relatorioFrequencias,
    getRelatorioFrequencias
  };
}
