import { useCallback } from "react";
import { ITurmaDTO } from "../../../../../models/happy-code-api.model";
import { ITurmaDataTable } from "../../../../../interfaces/turmas/turma-data-table.interface";
import {
  formattedDate,
  isBeforeDate,
  isDateBetween,
} from "../../../../../util/format";
import { TurmaStatus } from "../../../../../types/turmas/turma-status.type";

export default function useHandleListTurmaData() {
  const generateTurmaData = useCallback(
    (dataInicio: Date, dataFim: Date): TurmaStatus => {
      if (!dataInicio || isBeforeDate(dataInicio)) return "Não iniciado";

      if (!dataFim || isDateBetween(dataInicio, dataFim)) return "Em andamento";

      return "Finalizado";
    },
    [],
  );

  const processTurmaData = useCallback(
    (listTurmaData: ITurmaDTO[]): ITurmaDataTable[] => {
      const processTurma = (turma: ITurmaDTO): ITurmaDataTable => {
        const {
          ativo,
          codigo,
          unidadePessoa: { pessoaNome },
          dataInicio,
          dataFim,
          curso,
          id,
        } = turma;

        const getCursoDescricao = curso?.descricao ? curso.descricao : "";
        const cursoModalidadeDescricao = turma?.cursoModalidadeDescricao
          ? turma?.cursoModalidadeDescricao
          : "";

        return {
          id,
          ativo,
          codigo,
          curso: getCursoDescricao,
          cursoModalidadeDescricao,
          pessoaNome,
          dtInicio: formattedDate(dataInicio),
          dtTermino: formattedDate(dataFim),
          status: generateTurmaData(dataInicio, dataFim),
        };
      };

      return listTurmaData.map(processTurma);
    },
    [generateTurmaData],
  );

  const handleTurmaData = useCallback(
    (listTurmaData: ITurmaDTO[]) => {
      return processTurmaData(listTurmaData);
    },
    [processTurmaData],
  );

  return {
    handleTurmaData,
  };
}
