import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNotificationService } from '../../services/notification.service';
import { useAcaFeedbackService } from '../../services/aca-feedback';
import {
  IAcaAulaDTO,
  IFeedbackCleanDTO,
} from '../../models/happy-code-api.model';

interface useModalClassificacaoProps {
  feedback: IFeedbackCleanDTO;
  proximaAula: IAcaAulaDTO;
  onClose: () => void;
}

export default function useModalClassificacao({
  feedback,
  proximaAula,
  onClose,
}: useModalClassificacaoProps) {
  const { setIsGlobalLoading } = useGlobalContext();
  const notification = useNotificationService();
  const navigate = useNavigate();
  const feedbackService = useAcaFeedbackService();

  const saveFeedback = () => {
    setIsGlobalLoading(true);

    feedbackService
      .create(feedback)
      .then(() => {
        navigate(-1);

        notification({
          description: `Feedback salvo com sucesso.`,
          type: 'success',
          message: 'Sucesso!',
        });
      })
      .catch(() =>
        notification({
          description: `Erro ao salvar feedback.`,
          type: 'error',
          message: 'Erro!',
        }),
      )
      .finally(() => setIsGlobalLoading(false));

    onClose();
  };

  return {
    saveFeedback,
  };
}
