import { useListTurmaDataStore } from '../../../../../store/turmas/list-turma-data.store';
import { useCallback, useEffect } from 'react';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { useTurmaService } from '../../../../../services/turma.service';
import useHandleListTurmaData from './use-handle-list-turma-data.hook';
import { useTurmasPredicateStore } from '../../../../../store/turmas/turmas-predicate.store';
import useAddPredicateOnFindTurmas from './use-add-predicate-on-find-turmas.hook';
import { TurmasInputsValuesObject } from '../../../../../types/turmas/turmas-inputs-values-object.type';

interface UseFindTurmaDataProps {
  inputValuesObject: TurmasInputsValuesObject;
}

export default function useFindTurmaData({
  inputValuesObject,
}: UseFindTurmaDataProps) {
  const { setIsGlobalLoading, unidade } = useGlobalContext();

  const { turmasPredicate } = useTurmasPredicateStore();

  const { addAllPredicates } = useAddPredicateOnFindTurmas({
    inputValuesObject,
  });

  const { find } = useTurmaService();

  const { handleTurmaData } = useHandleListTurmaData();

  const { setListTurmaData, setPageableTurmaData } = useListTurmaDataStore();

  const findTurmaData = useCallback(async (): Promise<void> => {
    if (!unidade.id) return;
    setIsGlobalLoading(true);

    addAllPredicates();

    if (turmasPredicate._arrCustomOptions.length === 0)
      return setIsGlobalLoading(false);

    await find(turmasPredicate)
      .then((response) => {
        const handledTurmaData = handleTurmaData(response.data.content);

        setListTurmaData(handledTurmaData);
        setPageableTurmaData({ ...response.data, content: handledTurmaData });
      })
      .finally(() => setIsGlobalLoading(false));
  }, [
    addAllPredicates,
    handleTurmaData,
    setIsGlobalLoading,
    setListTurmaData,
    setPageableTurmaData,
    turmasPredicate,
    unidade.id,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    findTurmaData();

    return () => controller.abort();
  }, [findTurmaData]);

  return {
    findTurmaData,
  };
}
