import { Select } from 'antd';
import { useGlobalContext } from '../../../context/GlobalContext';
import { ProductType } from '../../../enum/materiais/product-type.enum';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export default function useSelectTypeMaterial() {
  const { usuario } = useGlobalContext();

  const userHasAcessoB2CAndB2B = usuario?.acessoB2c && usuario?.acessoB2b;
  const userHasOnlyAcessoB2B = usuario?.acessoB2b && !usuario?.acessoB2c;
  const userHasOnlyAcessoB2C = usuario?.acessoB2c && !usuario?.acessoB2b;

  const selectTypeMaterial = (): JSX.Element => {
    if (userHasAcessoB2CAndB2B || userHasOnlyAcessoB2C)
      return (
        <>
          <Select.Option value={ProductType.Curso}>Curso</Select.Option>
          <Select.Option value={ProductType.Curriculo}>Currículo</Select.Option>
          <Select.Option value={ProductType.ProdutoEscola}>
            Produto Escola
          </Select.Option>
        </>
      );

    if (userHasOnlyAcessoB2B)
      return (
        <Select.Option value={ProductType.ProdutoEscola}>
          Produto Escola
        </Select.Option>
      );
  };

  return {
    selectTypeMaterial: selectTypeMaterial(),
  };
}
