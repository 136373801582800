import { create } from 'zustand';
import { PageableResponse, Sort } from '../../models/response.model';
import { ITurmaDataTable } from '../../interfaces/turmas/turma-data-table.interface';

type TurmaDate = {
  listTurmaData: ITurmaDataTable[];
  setListTurmaData: (listTurmaData: ITurmaDataTable[]) => void;
  pageableTurmaData: PageableResponse<ITurmaDataTable>;
  setPageableTurmaData: (
    pageableTurmaData: PageableResponse<ITurmaDataTable>,
  ) => void;
};

const sortDefaultValue: Sort = {
  empty: false,
  sorted: false,
  unsorted: false,
};

const pageableTurmaDefaultValue: PageableResponse<ITurmaDataTable> = {
  content: [],
  empty: false,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: false,
    sort: sortDefaultValue,
    unpaged: false,
  },
  size: 0,
  sort: sortDefaultValue,
  totalElements: 0,
  totalPages: 0,
};

export const useListTurmaDataStore = create<TurmaDate>((set) => ({
  listTurmaData: [],
  setListTurmaData: (listTurmaData) => set({ listTurmaData }),
  pageableTurmaData: pageableTurmaDefaultValue,
  setPageableTurmaData: (pageableTurmaData) => set({ pageableTurmaData }),
}));
