import { ColumnInterface } from "../../../../interfaces/table/column.interface";
import { IAlunoTable } from "../../interfaces/aluno-table.interface";
import StatusMatricula from "../StatusMatricula";

export const AlunoTableColumns: ColumnInterface<IAlunoTable>[] = [
  {
    key: "nome",
    title: "NOME",
    render: ({ nome, statusMatricula }) => (
      <div className="aluno-table__aluno-cell">
        <p className="aluno-cell__aluno-nome">{nome}</p>
        <StatusMatricula codigoStatusMatricula={statusMatricula} />
      </div>
    ),
  },
  {
    key: "codigo",
    title: "CÓDIGO",
  },
  {
    key: "matricula",
    title: "MATRICULA",
  },
  {
    key: "frequencias",
    title: "FALTAS",
    render: (item) => item?.quantidadeDeFaltas,
  },
];
