import { AuthEnum } from '../enum/auth.enum';
import { Auth } from '../models/auth.model';

export const setObject = (key, value): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key) => {
  try {
    const getLocalforageData = localStorage.getItem(key);
    return getLocalforageData ? JSON.parse(getLocalforageData) : null;
  } catch (e) {
    return null;
  }
};

export const setPrimitive = (key, value): void => {
  localStorage.setItem(key, value.toString());
};

export const getPrimitive = (key): string => {
  return localStorage.getItem(key);
};

export const removeKey = (key): void => localStorage.removeItem(key);

export const clear = (): void => {
  localStorage.clear();
};

export const token = (): string => {
  const auth = getLocalStorageItem(AuthEnum.TOKEN_KEY) as Auth;
  return auth?.access_token;
};

// export const setAsyncObject = (key, value) => {
//   try {
//     Localforage.setItem(key, JSON.stringify(value));
//   } catch (error) {
//     console.warn(error);
//   }
// };

// export const getAsyncObject = (key) => {
//   try {
//     const getLocalforageData = Localforage.getItem(key);
//     const getLocalforageParsed = getLocalforageData
//       ? JSON.parse(getLocalforageData)
//       : null;
//     return getLocalforageParsed;
//   } catch (error) {
//     console.warn(error);
//   }
// };

// export const setAsyncPrimitive = (key, value) => {
//   try {
//     Localforage.setItem(key, value.toString());
//   } catch (error) {
//     console.warn(error);
//   }
// };

// export const getAsyncPrimitive = (key) => {
//   try {
//     return Localforage.getItem(key);
//   } catch (error) {
//     console.warn(error);
//   }
// };

// export const removeAsyncLocalforage = (key) => {
//   try {
//     Localforage.removeItem(key);
//   } catch (error) {
//     console.warn(error);
//   }
// };

// export const asyncClear = () => {
//   try {
//     Localforage.clear();
//   } catch (error) {
//     console.warn(error);
//   }
// };
