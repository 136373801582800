import { Button, Select, Form, Input, Pagination } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Digital from '../../assets/img/trilha/digital.svg';
import Analitica from '../../assets/img/trilha/analitica.svg';
import Socio from '../../assets/img/trilha/socio.svg';
import Metodologica from '../../assets/img/trilha/metodologica.svg';
import Coin from '../../assets/img/dashboard/coin.svg';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const MeusCursos = () => {
  const { Option } = Select;

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Link to="/">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
            <h1 className="fav-title">Meus cursos (4)</h1>
          </div>
          <Form
            id="box-form"
            className="d-flex-row j-start-a-center w-100 mb-20"
          >
            <div className="d-flex-row j-start-a-center input-box w-100">
              <Unicons.UilSearch
                size="20"
                color="var(--dark-secondary)"
                className="iconForm"
              />
              <Form.Item name="search" noStyle={true}>
                <Input placeholder="Buscar trilha por curso ou status" />
              </Form.Item>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Curso
              <Select
                defaultValue="Todos"
                style={{ width: 85 }}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Curso
              <Select
                defaultValue="Status"
                style={{ width: 85 }}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
          </Form>
          <ul className="d-flex-row j-start-a-center w-100 menu">
            <li className="d-flex-row j-center-a-center item active">
              Meus cursos
            </li>
            <Link to="/favoritos">
              <li className="d-flex-row j-center-a-center item">Favoritos</li>
            </Link>
          </ul>
          <div className="line-wm"></div>
          <div className="d-flex-column block-digital block-m mb-20">
            <div
              id="trilhas-column"
              className="d-flex-row j-between-a-center"
              style={{ marginTop: 15 }}
            >
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginLeft: 15 }}
              >
                <img src={Digital} alt="" />
                <h2>
                  trilhas<span>digitais</span>
                </h2>
              </div>
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginRight: 15 }}
              >
                <p>Conclua a trilha e ganhe:</p>
                <img src={Coin} alt="" className="coin-trilha" />
                <h5>50.000</h5>
              </div>
            </div>
            <div className="d-flex-row j-start-a-center flex-wrap">
              {/* <Curso header={false} progress={true} name="digitais" curso="Como transformar minha disciplina em um e-book pt.1" />
              <Curso header={false} progress={true} name="digitais" curso="Como transformar minha disciplina em um e-book pt.1" /> */}
            </div>
            <div
              className="d-flex-row j-start-a-center"
              style={{ marginLeft: 15 }}
            >
              <h2 style={{ width: '14%' }}>Cursos sugeridos</h2>
              <div className="line-wm"></div>
            </div>
            <div className="d-flex-row j-start-a-start flex-wrap">
              {/* <Curso header={false} progress={false} name="digitais" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="digitais" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="digitais" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="digitais" curso="Aprendizagem Baseada em Desafios" /> */}
            </div>
          </div>
          <div className="d-flex-column block-analitica mb-20">
            <div
              id="trilhas-column"
              className="d-flex-row j-between-a-center"
              style={{ marginTop: 15 }}
            >
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginLeft: 15 }}
              >
                <img src={Analitica} alt="" />
                <h2>
                  trilhas<span>analiticas</span>
                </h2>
              </div>
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginRight: 15 }}
              >
                <p>Conclua a trilha e ganhe:</p>
                <img src={Coin} alt="" className="coin-trilha" />
                <h5>50.000</h5>
              </div>
            </div>
            <div className="d-flex-row j-start-a-start flex-wrap">
              {/* <Curso header={false} progress={true} name="analitica" curso="Avaliação por competências e habilidades" /> */}
            </div>
            <div
              className="d-flex-row j-start-a-center"
              style={{ marginLeft: 15 }}
            >
              <h2 style={{ width: '14%' }}>Cursos sugeridos</h2>
              <div className="line-wm"></div>
            </div>
            {/* <div className="d-flex-row j-start-a-start flex-wrap">
              <Curso header={false} progress={false} name="analitica" curso="Competências do Currículo à Sala de Aula" />
              <Curso header={false} progress={false} name="analitica" curso="Competências do Currículo à Sala de Aula" />
            </div> */}
          </div>
          <div className="d-flex-column block-socio mb-20">
            <div
              id="trilhas-column"
              className="d-flex-row j-between-a-center mb-10"
              style={{ marginTop: 15 }}
            >
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginLeft: 15 }}
              >
                <img src={Socio} alt="" />
                <h2>
                  trilhas<span>socioemocionais</span>
                </h2>
              </div>
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginRight: 15 }}
              >
                <p>Conclua a trilha e ganhe:</p>
                <img src={Coin} alt="" className="coin-trilha" />
                <h5>50.000</h5>
              </div>
            </div>
            <div
              className="d-flex-row j-start-a-center"
              style={{ marginLeft: 15 }}
            >
              <h2 style={{ width: '14%' }}>Cursos sugeridos</h2>
              <div className="line-wm"></div>
            </div>
            {/* <div className="d-flex-row j-start-a-start flex-wrap">
              <Curso header={false} progress={false} name="socio" curso="Competências socio-emocionais p/ docentes" />
              <Curso header={false} progress={false} name="socio" curso="Como atuar alunos em sofrimento emocional?" />
              <Curso header={false} progress={false} name="socio" curso="Cuidar da Saúde Emocional no Ensino Superior..." />
            </div> */}
          </div>
          <div className="d-flex-column block-metodologicas mb-20">
            <div
              id="trilhas-column"
              className="d-flex-row j-between-a-center mb-10"
              style={{ marginTop: 15 }}
            >
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginLeft: 15 }}
              >
                <img src={Metodologica} alt="" />
                <h2>
                  trilhas<span>metodológicas</span>
                </h2>
              </div>
              <div
                className="d-flex-row j-start-a-center"
                style={{ marginRight: 15 }}
              >
                <p>Conclua a trilha e ganhe:</p>
                <img src={Coin} alt="" className="coin-trilha" />
                <h5>50.000</h5>
              </div>
            </div>
            <div
              className="d-flex-row j-start-a-center"
              style={{ marginLeft: 15 }}
            >
              <h2 style={{ width: '14%' }}>Cursos sugeridos</h2>
              <div className="line-wm"></div>
            </div>
            {/* <div className="d-flex-row j-start-a-start flex-wrap">
              <Curso header={false} progress={false} name="metodologica" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="metodologica" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="metodologica" curso="Aprendizagem Baseada em Desafios" />
              <Curso header={false} progress={false} name="metodologica" curso="Aprendizagem Baseada em Desafios" />
            </div> */}
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
