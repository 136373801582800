import { useState } from 'react';
import { Sidebar } from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import { Button, Radio, Calendar } from 'antd';
import * as Unicons from '@iconscout/react-unicons';

import { Tag } from '../../components/Tag';
import { Horario } from '../../components/Horario';
import { MentorMini } from '../../components/Mentor/mini';
import { ModalMentor } from '../../components/Modal/mentor';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const MentoriasPerfil = () => {
  const [value, setValue] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  function onPanelChange(value: any, mode: any) {}

  const onChange1 = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-30">
            <Link to="/mentorias/mentores">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
          </div>
          <div id="box-perfil" className="d-flex-row j-between-a-start mb-40">
            <div style={{ width: '64%' }} className="box-left">
              <div className="d-flex-row j-start-a-center mb-30">
                <img
                  src="/assets/img/mentores/mentor1.png"
                  alt="avatar"
                  className="avatar"
                />
                <div>
                  <h1>
                    <span>Olá, sou</span> Maria Antônia Paz
                  </h1>
                  <h6>
                    Tenho formação em:{' '}
                    <span>
                      Administração de Finanças pela Universidade A, 2018
                    </span>
                  </h6>
                </div>
              </div>
              <div className="curriculo mb-10">
                <h5>Meu currículo</h5>
              </div>
              <ul className="mb-20">
                <li className="mb-10">
                  • Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Facilisis massa dignissim eget volutpat. Mattis.
                </li>
                <li className="mb-10">
                  • Cursus viverra aliquam, auctor malesuada. Sapien leo
                  pulvinar tincidunt dolor vulputate. Pulvinar sed.
                </li>
                <li className="mb-10">
                  • Rhoncus ut ante mauris ornare etiam urna nisl, blandit. Est
                  purus nullam integer phasellus elit. Nunc sit.
                </li>
              </ul>
              <div className="curriculo mb-20">
                <h5>Dias e horários disponíveis</h5>
              </div>
              <div className="mb-30 w-100">
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>
                    <Tag name="Happy Money" estilo="happy-money" />
                  </Radio>
                  <Radio value={2}>
                    <Tag name="Happy Speech" estilo="happy-speech" />
                  </Radio>
                  <Radio value={3}>
                    <Tag name="Happy Code" estilo="happy-code-green" />
                  </Radio>
                  <Radio value={4}>
                    <Tag name="Metodologia" estilo="metodologia" />
                  </Radio>
                </Radio.Group>
              </div>
              <div
                id="box-calendar-datas"
                className="d-flex-row j-between-a-start"
              >
                <div id="calendar-mentoria" className="w-40">
                  <h5 className="mb-10">Selecione uma data:</h5>
                  <Calendar fullscreen={false} onPanelChange={onPanelChange} />
                </div>
                <div id="calendar-boxes-mentoria" style={{ width: '56%' }}>
                  <h3 className="azul mb-10">18/08/2021, quarta-feira</h3>
                  <h5 className="mb-10">Selecione o horário de agendamento:</h5>
                  <div>
                    <Horario check={true} inicio="8:00" fim="9:00" />
                    <Horario check={false} inicio="8:00" fim="9:00" />
                    <Horario check={false} inicio="8:00" fim="9:00" />
                    <Horario check={false} inicio="8:00" fim="9:00" />
                    <Horario check={false} inicio="8:00" fim="9:00" />
                    <Horario check={false} inicio="8:00" fim="9:00" />
                  </div>
                  <div className="d-flex-row j-end-a-center">
                    <Button onClick={onChange1} className="button-primary">
                      Agendar mentoria
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div id="box-mentores-right" style={{ width: '34%' }}>
              <h1 className="mb-20">Mais Mentores</h1>
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
              <MentorMini
                image="/assets/img/mentores/mentor1.png"
                name="Otávio Alves"
                formatura="Administração de finanças"
              />
            </div>
          </div>
        </div>
      </SidebarAndContainer>
      <ModalMentor open={isOpen} onClose={onClose} />
    </>
  );
};
