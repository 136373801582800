import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ReforcoTipoEnum } from "../../../../enum/lancamento-aula/reforco-tipo.enum";

interface ReforcoSelectOptionProps {
  value: ReforcoTipoEnum;
}

export default function ReforcoSelectOption({
  value,
}: ReforcoSelectOptionProps) {
  const getReforcoOption = {
    [ReforcoTipoEnum.TRUE]: {
      icon: <CloseCircleOutlined style={{ color: "var(--red-color)" }} />,
      text: "Sim",
      style: { color: "var(--red-color)" },
    },
    [ReforcoTipoEnum.FALSE]: {
      icon: <CheckCircleOutlined style={{ color: "var(--green-color)" }} />,
      text: "Não",
      style: { color: "var(--green-color)" },
    },
  };

  const reforcoOption = getReforcoOption[value];

  return (
    <div className={"select-options-wrapper"}>
      {reforcoOption.icon}
      <p style={reforcoOption.style}>{reforcoOption.text}</p>
    </div>
  );
}
