import { Button, Divider } from "antd";
import { FaixaEtaria, ferramentasB2C } from "./data/ferramentas-b2c";
import Style from './ferramentas-b2c.module.css';
import { Tags } from "../../../components/Tags";


export default function FerramentasB2C() {
  const data = ferramentasB2C;
  
  return (
    <div>
      {data && data.map((faixa: FaixaEtaria) => (
        <div key={faixa.faixa} className={Style.container}>
          <Divider style={{borderColor: 'var(--dark)'}}><h1>{faixa.faixa}</h1></Divider>
          {faixa.cursos.map((curso) => (
            <div key={curso.nome} className={Style.subContainer}>
              <h2 className={Style.subTitle}>{curso.nome}</h2>
              <ul>
                {curso.ferramentas.map((ferramenta) => (
                  <div key={ferramenta.id} className={Style.itemContainer}>
                    <li className={Style.item}>
                        <img
                          onClick={() => window.open(ferramenta.link)}
                          src={ferramenta.img}
                          alt={ferramenta.nome}
                        />
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}  className={Style.ferramentaInfo}>
                        <p style={{textAlign: 'center', marginBottom:5}}>{ferramenta.nome}</p>
                        <Tags tipo={ferramenta.modalidade.toLocaleLowerCase()}/>
                      </div>
                        <Button onClick={() => window.open(ferramenta.link)}>Acessar</Button>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
