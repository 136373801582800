import * as Unicons from '@iconscout/react-unicons';
import { Tag } from '../Tag';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  name: string;
  tag?: string;
  estilo?: string;
  status?: string;
  data?: string;
  dia?: string;
  horario?: string;
  width?: boolean;
}

export const Historico = (props: Props) => {
  const { name, tag, status, data, dia, horario, width } = props;
  let estilo, dot, wbox;

  if (width === true) {
    wbox = 'box-historico-50';
  } else {
    wbox = 'box-historico';
  }

  if (tag === 'Happy Code') {
    estilo = 'happy-code-green';
  }

  if (tag === 'Happy Speech') {
    estilo = 'happy-speech';
  }

  if (status === 'Concluido') {
    dot = 'green';
  }

  if (status === 'Agendado') {
    dot = 'cinza';
  }

  return (
    <>
      <div className={wbox}>
        <h1 id="title-historico">{name}</h1>
        <p className="mb-10">Mentor</p>
        <div className="d-flex-row j-start-a-center mb-10">
          <Tag name={tag} />
        </div>
        <div className="d-flex-row j-start-a-center mb-10">
          <div className={`dot-${dot}`}></div>
          <h5 id={dot}>{status}</h5>
        </div>
        <p className="mb-10">{data}</p>
        <div className="d-flex-row mb-40">
          <div className="d-flex-row j-start-a-center box-data">
            <span>{dia}</span>
            <h6>{horario}</h6>
          </div>
        </div>
        <Link to="/mentorias/historico/detalhe">
          <Button className="button-blue button-historico">Ver detalhes</Button>
        </Link>
        <Unicons.UilEllipsisH className="dot" color="var(--secondary-color)" />
      </div>
    </>
  );
};
