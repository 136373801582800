import useAccordion from './useAccordion';
import './accordion.css';

interface AccordionProps {
  children: React.ReactNode;
  titulo: string;
  info?: string;
}
export default function Accordion({ children, titulo, info }: AccordionProps) {
  const { accordionIsOpen, abreOuFechaAccordion, defineIconDoAccordion } =
    useAccordion();

  return (
    <div className="accordion-wrapper">
      <div
        onClick={abreOuFechaAccordion}
        className="accordion-wrapper__conteudo-topo"
      >
        <div className="conteudo-topo__icon-titulo">
          <i className="icon-titulo__icon">{defineIconDoAccordion()}</i>
          <h4 className="icon-titulo__titulo">{titulo}</h4>
        </div>
        <span className="conteudo-topo__info">{info}</span>
      </div>
      {accordionIsOpen && (
        <div className="accordion-wrapper__conteudo-filho">{children}</div>
      )}
    </div>
  );
}
