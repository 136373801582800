import {
  useLearnConteudoMinistradoService
} from '../../../../../../services/learn/learn-conteudo-ministrado.service';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useNotificationService
} from '../../../../../../services/notification.service';
import { IconType } from 'antd/lib/notification/interface';

export default function useConteudoAlreadyRegistered() {
  const { id } = useParams();
  const { findConteudoAlreadyRegistered } = useLearnConteudoMinistradoService();
  const location = useLocation();
  const navigate = useNavigate();
  const notificationService = useNotificationService();

  const notifyAndNavigate = (message: string, type: IconType, path: string) => {
    notificationService({ type, message });
    navigate(path);
  };

  const navigateToNextStep = () =>
    notifyAndNavigate(
      'Conteúdo já registrado, redirecionando para a próxima etapa.',
      'success',
      `/turmas/professor/lancamento-aula/create/step-2/${id}`
    );

  const navigateToPreviousStep = () => {
    notifyAndNavigate(
      'Conteúdo não registrado, redirecionando para a etapa anterior.',
      'error',
      `/turmas/professor/lancamento-aula/create/step-1/${id}`
    );
  };

  const checkIfConteudoExists = useCallback(async () => {
    if (!id) return;

    const { data } = await findConteudoAlreadyRegistered(Number(id));

    if (data && location.pathname.includes('step-1'))
      return navigateToNextStep();

    if (!data && location.pathname.includes('step-2'))
      return navigateToPreviousStep();
  }, [findConteudoAlreadyRegistered, id]);

  return {
    checkIfConteudoExists
  };
};
