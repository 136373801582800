import { AxiosResponse } from 'axios';
import { formatHttpParams, Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import http from './../../helpers/http-common';

export const baseFind = <C, D, F>(resourceURL: string) => {
  return {
    findClean: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<PageableResponse<C>>> =>
      http.get(`${resourceURL}/clean`, formatHttpParams(predicate)),
    find: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<PageableResponse<D>>> =>
      http.get(`${resourceURL}`, formatHttpParams(predicate)),
    findFull: (
      predicate: Predicate = new Predicate(),
    ): Promise<AxiosResponse<PageableResponse<F>>> =>
      http.get(`${resourceURL}/full`, formatHttpParams(predicate)),
  };
};
