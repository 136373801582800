import { IAlunosTableDTO } from "../../../../models/happy-code-api.model";
import { PageableResponse } from "../../../../models/response.model";
import { IAlunoTable } from "../../interfaces/aluno-table.interface";

interface UseAlunoTableProps {
  alunosTableData: PageableResponse<IAlunosTableDTO>;
}

export default function useAlunoTable({ alunosTableData }: UseAlunoTableProps) {
  const alunoTableContent = (): Array<IAlunoTable> => {
    return alunosTableData?.content?.map(
      ({
        alunoNome,
        idAluno,
        idMatricula,
        statusMatricula,
        quantidadeDeFaltas,
      }) => ({
        nome: alunoNome,
        codigo: "#" + idAluno,
        matricula: "#" + idMatricula,
        statusMatricula,
        quantidadeDeFaltas,
      }),
    );
  };

  const handledAlunoTableData = {
    ...alunosTableData,
    content: alunoTableContent(),
  };

  return {
    handledAlunoTableData,
  };
}
