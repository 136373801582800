import { Sidebar } from '../../components/Sidebar';
import { Avatar } from '../../components/Avatar/index';
import * as Unicons from '@iconscout/react-unicons';
import Coin from '../../assets/img/dashboard/coin.svg';
import { Button, Select } from 'antd';
import { Link } from 'react-router-dom';

import { Tag } from '../../components/Tag';

import EmojiCry from '../../assets/img/emojis/cry.png';
import EmojiSad from '../../assets/img/emojis/sad.png';
import EmojiNormal from '../../assets/img/emojis/normal.png';
import EmojiGod from '../../assets/img/emojis/god.png';
import EmojiHappy from '../../assets/img/emojis/happy.png';

import { Mentor } from '../../components/Mentor';
import { Historico } from '../../components/Historico';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const Mentorias = () => {
  const { Option } = Select;

  function handleChange(value: string) {}

  return (
    <>
      <SidebarAndContainer cursos={true}>
        <div className="dashboard">
          <div className="row">
            <div className="header d-flex-row mb-20">
              <div id="profile" className="d-flex-row j-start-a-center">
                <Avatar tipo="user" size={80} />
                <div className="box-info">
                  <h6>Quarta-feira, 15 de Setembro de 2021</h6>
                  <div className="d-flex-row">
                    <h2>
                      <span style={{ color: 'var(--blue-gray)' }}>Olá, </span>{' '}
                      Lucia Almeida{' '}
                      <span style={{ color: 'var(--blue-gray)' }}>|</span>
                    </h2>
                    <Unicons.UilBell
                      color="var(--dark)"
                      style={{ marginTop: 7 }}
                    />
                  </div>
                  <div className="d-flex-row j-start-a-center medal">
                    <Unicons.UilMedal color="var(--dark)" />
                    <h6>MEU NÍVEL: </h6>
                    <div className="position">nv.I - Instrutor iniciante</div>
                  </div>
                </div>
              </div>
              <div className="coins">
                <h6>MEUS HAPPYCOINS</h6>
                <div className="d-flex-row j-start-a-center">
                  <img src={Coin} alt="" />
                  <h1>52.500</h1>
                </div>
                <p>
                  Como obter mais <a href="/">happycoins?</a>
                </p>
              </div>
            </div>
          </div>
          <h1 className="mb-5">Minhas mentorias</h1>
          <h6 className="mb-10" id="subtitle-mentoria">
            2 mentorias agendadas
          </h6>
          <div className="d-flex-row j-between-a-start flex-wrap mb-20">
            <div className="box-white box-line w-49">
              <div className="d-flex-row j-between-a-center calendar-days">
                <div className="d-flex-row j-between-a-center select-box-mentoria">
                  Setembro, 2021
                  <Select
                    defaultValue="Todos"
                    style={{ width: 85 }}
                    onChange={handleChange}
                    className="select-fav"
                  >
                    <Option value="jack">Outubro 2021</Option>
                    <Option value="lucy">Novembro 2021</Option>
                  </Select>
                </div>
                <div className="d-flex-row j-between-a-center">
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Dom</h4>
                    <p>12</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Seg</h4>
                    <p>13</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Ter</h4>
                    <p>14</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day-select">
                    <h4>Qua</h4>
                    <p>15</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Qui</h4>
                    <p>16</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Sex</h4>
                    <p>17</p>
                  </div>
                  <div className="d-flex-column j-center-a-center day">
                    <h4>Sab</h4>
                    <p>18</p>
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="d-flex-row j-between-a-center mb-20">
                <h4 id="calendar">Quarta-feira, 15 de setembro 2021</h4>
                <h4 id="azul">Você tem 2 mentorias hoje</h4>
              </div>
              <div className="box-scroll">
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    08:00
                  </p>
                  <div className="d-flex-column box-calendar-mentor w-90">
                    <h4 className="mb-5">
                      <span>Mentor:</span> Maria Antonia Paz
                    </h4>
                    <div className="d-flex-row j-start-a-center mb-10">
                      <Tag name="Happy Code" estilo="happy-code" />
                    </div>
                  </div>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    09:00
                  </p>
                  <div className="d-flex-column box-calendar-mentor-blue w-90">
                    <h4 className="mb-5">
                      <span>Mentor:</span> Maria Antonia Paz
                    </h4>
                    <div className="d-flex-row j-start-a-center mb-10">
                      <Tag name="Happy Speech" estilo="happy-speech" />
                    </div>
                  </div>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    10:00
                  </p>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    11:00
                  </p>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    12:00
                  </p>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    12:00
                  </p>
                </div>
                <div className="d-flex-row j-start-a-start mb-10">
                  <p id="hora" className="w-10">
                    12:00
                  </p>
                </div>
              </div>
            </div>
            <div
              id="box-historico-mentoria"
              className="box-white box-line w-49"
            >
              <div className="d-flex-row j-between-a-center">
                <h4 id="title-mentorias">Mentorias realizadas</h4>
                <Link to="/mentorias/historico">
                  <Button className="button-white">
                    Ver todas{' '}
                    <Unicons.UilArrowRight color="var(--primary-color)" />
                  </Button>
                </Link>
              </div>
              <h1>10</h1>
              <div className="line"></div>
              <div className="d-flex-row j-between-a-center flex-wrap">
                <Historico
                  name="Jorge Hernandez"
                  tag="Happy Code"
                  status="Concluido"
                  data="11/08/21"
                  dia="SEG"
                  horario="8:00 - 12:00"
                  width={true}
                />
                <Historico
                  name="Jorge Hernandez"
                  tag="Happy Code"
                  status="Concluido"
                  data="11/08/21"
                  dia="SEG"
                  horario="8:00 - 12:00"
                  width={true}
                />
              </div>
            </div>
          </div>
          <div
            id="mentores-title"
            className="d-flex-row j-between-a-center mb-20"
          >
            <h1 className="mb-5">Mentores mais procurados</h1>
            <Link to="/mentorias/mentores">
              <Button className="button-primary">
                Pesquisar outros Mentores
              </Button>
            </Link>
          </div>
          <div className="d-flex-row j-between-a-start flex-wrap w-100 mb-5">
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
            <Mentor
              image="/assets/img/mentores/mentor1.png"
              name="Jorge Hernandez"
              description="Formação em: Administração de finanças maecenas vitae aenean arcu. At interdum viverra fames feugiat..."
            />
          </div>
          <div className="line"></div>
          <h2 className="mb-10">
            O que você está achando no nosso sistema de mentorias?
          </h2>
          <div className="d-flex-row j-start-a-center pb-50">
            <div className="d-flex-row j-center-a-center icone">
              <img src={EmojiCry} alt="emoji" className="emoji" />
            </div>
            <div className="d-flex-row j-center-a-center icone">
              <img src={EmojiSad} alt="emoji" className="emoji" />
            </div>
            <div className="d-flex-row j-center-a-center icone">
              <img src={EmojiNormal} alt="emoji" className="emoji" />
            </div>
            <div className="d-flex-row j-center-a-center icone">
              <img src={EmojiGod} alt="emoji" className="emoji" />
            </div>
            <div className="d-flex-row j-center-a-center icone">
              <img src={EmojiHappy} alt="emoji" className="emoji" />
            </div>
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
