import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoGrande from '../../assets/img/sidebar/logo-grande.png';
import logo from '../../assets/img/sidebar/AC color.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { clear } from '../../util/store';

export default function useSidebarComponentState() {
  const { usuario } = useGlobalContext();

  const isInstrutor = useMemo(() => {
    return usuario.funcao === 'IST';
  }, [usuario]);

  const isB2bOnly = useMemo(() => {
    return usuario?.acessoB2b && !usuario?.acessoB2c;
  }, [usuario]);

  const isPermissionamentoConecta = useMemo(() => {
    let validar: boolean;
    if (isInstrutor && usuario?.acessoB2c) {
      validar = true;
    } else if (usuario?.acessoB2c) {
      validar = true;
    } else if (usuario?.acessoComercial) {
      validar = true;
    }
    return validar;
  }, [usuario]);

  const isPermissionamentoMaterial = useMemo(() => {
    let validar: boolean;
    if (isInstrutor && usuario?.acessoB2c && usuario?.acessoB2b) {
      validar = true;
    } else if (isInstrutor && usuario?.acessoB2c) {
      validar = true;
    } else if (isInstrutor && usuario?.acessoB2b) {
      validar = true;
    }
    return validar;
  }, [usuario]);

  const isPermissionamentoGestaoSala = useMemo(() => {
    let validar: boolean;
    if (isInstrutor && usuario?.acessoB2c && usuario?.acessoB2b) {
      validar = true;
    } else if (isInstrutor && usuario?.acessoB2c) {
      validar = true;
    }
    return validar;
  }, [usuario]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuIconClick = () => setMenuIsOpen(!menuIsOpen);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [menuBurger, setMenuBurger] = useState('burger-menu-none');

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
    if (hamburgerOpen) return setMenuBurger('burger-menu-none');

    return setMenuBurger('burger-menu');
  };

  const navigate = useNavigate();

  const logout = () => {
    clear();
    navigate('/login');
  };

  const iconButton = menuIsOpen ? (
    <ChevronLeftOutlined />
  ) : (
    <ChevronRightOutlined />
  );

  const happyLogo = menuIsOpen ? logoGrande : logo;

  return {
    logout,
    hamburgerOpen,
    menuBurger,
    toggleHamburger,
    menuIconClick,
    menuIsOpen,
    usuario,
    isPermissionamentoGestaoSala,
    isPermissionamentoMaterial,
    isPermissionamentoConecta,
    iconButton,
    happyLogo,
    isB2bOnly,
  };
}
