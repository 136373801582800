export interface Ferramenta {
  id: number;
  img: string;
  link: string;
  nome: string;
}

export interface AnosFerramentas {
  ano: string
  ferramentas: Ferramenta[]
}

export const ferramentasB2B: AnosFerramentas[] = [
  {
    ano: 'Ferramentas do 1º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/',
        nome: 'STEM Play'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/scratchjr.png',
        link: 'https://codejr.org/scratchjr/index.html',
        nome: 'Scratchjr'
      }
    ]
  },
  {
    ano: 'Ferramentas do 2º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/',
        nome: 'STEM Play'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/',
        nome: 'Kahoot'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/google-earth.png',
        link: 'https://earth.google.com/web/',
        nome: 'Google Earth'
      },
    ]
  },
  {
    ano: 'Ferramentas do 3º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/',
        nome: 'STEM Play'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/',
        nome: 'Kahoot'
      },
    ]
  },
  {
    ano: 'Ferramentas do 4º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/stem-play.png',
        link: 'https://app.stemplay.io/',
        nome: 'STEM Play'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/scratch.png',
        link: 'https://scratch.mit.edu/',
        nome: 'Scratch'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/microbit.png',
        link: 'https://microbit.org/',
        nome: 'Micro:Bit'
      },
    ]
  },
  {
    ano: 'Ferramentas do 5º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/scratch.png',
        link: 'https://scratch.mit.edu/',
        nome: 'Scratch'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/kahoot.png',
        link: 'https://kahoot.it/',
        nome: 'Kahoot'
      },
    ]
  },
  {
    ano: 'Ferramentas do 6º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://editor.construct.net/',
        nome: 'Construct 3'
      },
    ]
  },
  {
    ano: 'Ferramentas do 7º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/tinkercad.png',
        link: 'https://app.stemplay.io/',
        nome: 'Tinkercad'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://editor.construct.net/',
        nome: 'Construct 3'
      },
    ]
  },
  {
    ano: 'Ferramentas do 8º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/figma.png',
        link: 'https://www.figma.com/',
        nome: 'Figma'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/canva.png',
        link: 'https://www.canva.com/',
        nome: 'Canva'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/glide.png',
        link: 'https://www.glideapps.com/',
        nome: 'Glide'
      },
    ]
  },
  {
    ano: 'Ferramentas do 9º Ano',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/quickdraw.png',
        link: 'https://quickdraw.withgoogle.com/',
        nome: 'Quickdraw'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/chat-gpt.png',
        link: 'https://chat.openai.com/',
        nome: 'ChatGPT'
      },
      {
        id: 3,
        img: './assets/img/ferramentas-b2b/charater.png',
        link: 'https://character.ai/',
        nome: 'Character'
      },
      {
        id: 4,
        img: './assets/img/ferramentas-b2b/thunkable.png',
        link: 'https://thunkable.com/',
        nome: 'Thunkable'
      },
      {
        id: 5,
        img: './assets/img/ferramentas-b2b/glide.png',
        link: 'https://www.glideapps.com/',
        nome: 'Glide'
      }
    ]
  },
  {
    ano: 'Ferramentas do 1° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://editor.construct.net/',
        nome: 'Construct 3'
      }
    ]
  },
  {
    ano: 'Ferramentas do 2° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/construct3.png',
        link: 'https://editor.construct.net/',
        nome: 'Construct 3'
      }
    ]
  },
  {
    ano: 'Ferramentas do 3° Ano Ensino Médio',
    ferramentas: [
      {
        id: 1,
        img: './assets/img/ferramentas-b2b/thunkable.png',
        link: 'https://thunkable.com/',
        nome: 'Thunkable'
      },
      {
        id: 2,
        img: './assets/img/ferramentas-b2b/glide.png',
        link: 'https://www.glideapps.com/',
        nome: 'Glide'
      },
    ]
  }
]