// import 'antd/dist/antd.css';
import './assets/sass/app.scss';
import GlobalLoading from './components/GlobalLoading/index';
import { GlobalContextProvider } from './context/GlobalContext';
import { Routes } from './routes';

export const App = () => {
  return (
    <GlobalContextProvider>
      <GlobalLoading />
      <Routes />
    </GlobalContextProvider>
  );
};
