import { Navigate } from 'react-router-dom';
import { AuthEnum } from '../enum/auth.enum';
import { getLocalStorageItem } from '../util/store';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const usuario = getLocalStorageItem(AuthEnum.USER_KEY);
  const token = getLocalStorageItem(AuthEnum.TOKEN_KEY);

  const isTokenValid = token?.access_token;
  const isUserActive = usuario?.active;

  const isUserLogged = isTokenValid && isUserActive;

  return <>{isUserLogged ? children : <Navigate to={'/login'} />}</>;
};

export default PrivateRoute;
