import { Sidebar } from '../../components/Sidebar';
import { Button, Select, Form, Input, Pagination } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import { Historico } from '../../components/Historico';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

export const MentoriasHistorico = () => {
  const { Option } = Select;

  function handleChange(value: string) {}

  return (
    <>
      <SidebarAndContainer>
        <div className="dashboard">
          <div className="d-flex-row j-start-a-center mb-20">
            <Link to="/mentorias">
              <Button className="button-blue">
                <Unicons.UilArrowLeft />
                Voltar
              </Button>
            </Link>
          </div>
          <h1 style={{ marginLeft: 0 }} className="fav-title mb-5">
            Historico de mentorias
          </h1>
          <p className="mb-20">2 mentorias agendadas 10 mentorias realizadas</p>
          <Form
            id="box-form"
            className="d-flex-row j-start-a-center w-100 mb-20"
          >
            <div className="d-flex-row j-start-a-center input-box w-100">
              <Unicons.UilSearch
                size="20"
                color="var(--dark-secondary)"
                className="iconForm"
              />
              <Form.Item name="search" noStyle={true}>
                <Input placeholder="Buscar por mentor ou período" />
              </Form.Item>
            </div>
            <div className="d-flex-row w-100 j-between-a-center select-box">
              Periodo
              <Select
                defaultValue="Todos"
                style={{ width: 85 }}
                onChange={handleChange}
                className="select-fav"
              >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
            </div>
          </Form>
          <div className="d-flex-row j-between-a-start flex-wrap w-100 mb-20">
            <Historico
              name="Jorge Hernandez"
              tag="Happy Code"
              status="Concluido"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
            <Historico
              name="Pedro Baptista"
              tag="Happy Speech"
              status="Agendado"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
            <Historico
              name="Jorge Hernandez"
              tag="Happy Code"
              status="Concluido"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
            <Historico
              name="Jorge Hernandez"
              tag="Happy Code"
              status="Concluido"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
            <Historico
              name="Pedro Baptista"
              tag="Happy Speech"
              status="Agendado"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
            <Historico
              name="Pedro Baptista"
              tag="Happy Speech"
              status="Agendado"
              data="11/08/21"
              dia="SEG"
              horario="8:00 - 12:00"
            />
          </div>
          <div className="d-flex-row j-center-a-center w-100 pb-50">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div>
      </SidebarAndContainer>
    </>
  );
};
