import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Predicate } from '../../../../models/predicate.model';
import { useAcaAulaService } from '../../../../services/aca-aula.service';
import { IAcaAulaCustomDTO } from '../../../../models/happy-code-api.model';
import { PageableResponse } from '../../../../models/response.model';
import { Operators } from '../../../../enum/operators.enum';

export default function useFindAulasCustom() {
  const { idCurso } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const [search, setSearch] = useState<string>();

  const addAulaCursoPredicate = useCallback(
    (page?: number) => {
      const aulasCursoPredicate = new Predicate();

      aulasCursoPredicate.addSort('ordem');
      aulasCursoPredicate.sort = 'asc';
      aulasCursoPredicate.addOption('curso.id', idCurso);
      aulasCursoPredicate.addOption('ativo', true);

      if (page) aulasCursoPredicate.setPage(page);

      if (search)
        aulasCursoPredicate.addOption('codigo', search, Operators.CONTAINS);

      return aulasCursoPredicate;
    },
    [idCurso, search],
  );

  const { findCustomAula } = useAcaAulaService();

  const [aulasData, setAulasData] =
    useState<PageableResponse<IAcaAulaCustomDTO>>();

  const findAulasCustom = useCallback(
    async (page?: number) => {
      const aulasCursoPredicate = addAulaCursoPredicate(page);

      await findCustomAula(aulasCursoPredicate).then(({ data }) =>
        setAulasData(data),
      );
    },
    [setAulasData, addAulaCursoPredicate],
  );

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      findAulasCustom();
    }, 1200);
    return () => clearTimeout(typingTimeout);
  }, [search]);

  return {
    aulasData,
    findAulasCustom,
    setSearch,
  };
}
