import { useCallback, useEffect, useState } from 'react';
import { IModalidadeDTO } from '../../../../../models/happy-code-api.model';
import { useModalidadeService } from '../../../../../services/modalidade.service';

export default function useFindListOfModalidades() {
  const [listOfModalidades, setListOfModalidades] = useState<IModalidadeDTO[]>(
    [],
  );

  const { findList } = useModalidadeService();

  const findListOfModalidades = useCallback(async (): Promise<void> => {
    await findList().then(({ data }) => setListOfModalidades(data));
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    findListOfModalidades();

    return () => controller.abort();
  }, [findListOfModalidades]);

  return {
    listOfModalidades,
  };
}
