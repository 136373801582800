import { Button } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

interface Props {
  width: string[];
  id: string;
  aula: string;
  url: string;
  material: boolean;
}

export const ItemAula = (props: Props) => {
  const { id, aula, url, width, material } = props;
  let botao, text;

  if (material === true) {
    botao = 'button-primary';
    text = 'Ver materiais de apoio';
  } else {
    botao = 'button-disable';
    text = 'Sem materiais de apoio';
  }

  return (
    <>
      <div className="d-flex-row j-start-a-center w-100 row-items">
        <div className="item" style={{ width: width[0], padding: 10 }}>
          {id}
        </div>
        <div
          className="d-flex-row j-between-a-center item"
          style={{ width: width[1] }}
        >
          {aula}
          <Link to={url}>
            <Button className={botao}>
              {text} <Unicons.UilArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
