import { useCallback } from "react";
import { useTurmaService } from "../../../services/turma.service";
import { useParams } from "react-router-dom";
import { boxTurmaInformacaoStore } from "../store/box-turma-informacao.store";

export default function useFindBoxTurmaInformacao() {
  const { setBoxTurmaInformacao } = boxTurmaInformacaoStore();

  const service = useTurmaService();
  const { id } = useParams();

  const findBoxTurmaInformacao = useCallback(async () => {
    if (!id) return;

    const parseIdToInt = parseInt(id);

    await service
      .findBoxTurmaInformacao(parseIdToInt)
      .then(({ data }) => {
        setBoxTurmaInformacao(data);

      })
      .catch((error) => console.error(error));
  }, [id, setBoxTurmaInformacao]);

  return {
    findBoxTurmaInformacao,
  };
}
