import {
  IFeedbackCleanDTO,
  IFeedbackDTO,
  IFeedbackFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcaFeedbackService = () => {
  const resourceURL = 'aca-feedback';

  return {
    ...baseFindList<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(
      resourceURL,
    ),
    ...baseFind<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(resourceURL),
    ...baseFindList<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(
      resourceURL,
    ),
    ...baseUpdate<IFeedbackCleanDTO, IFeedbackDTO, IFeedbackFullDTO>(
      resourceURL,
    ),
  };
};
