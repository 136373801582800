import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import { useGlobalContext } from '../../context/GlobalContext';
import { IConectaCleanDTO } from '../../models/happy-code-api.model';
import { useAcaConectaService } from '../../services/aca-conecta';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';

function ConectaApresentacao() {
  const { setIsGlobalLoading } = useGlobalContext();
  const navigate = useNavigate();
  const conectaService = useAcaConectaService();
  const [conecta, setConecta] = useState<IConectaCleanDTO>();
  const { idConecta } = useParams<any>();

  const findConecta = () => {
    setIsGlobalLoading(true);

    const parseIdToNumber = parseInt(idConecta);

    conectaService
      .findCleanById({ id: parseIdToNumber })
      .then((response) => {
        setConecta(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onVoltarTela = () => {
    navigate(`/conectas/all`);
  };

  useEffect(() => {
    findConecta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarAndContainer>
      <div className="dashboard">
        <div className="d-flex-row j-start-a-center mb-20">
          <Button className="button-blue" onClick={onVoltarTela}>
            <Unicons.UilArrowLeft />
            Voltar
          </Button>
          <h1 className="fav-title">{conecta?.nome}</h1>
        </div>
        <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
          <div id="box-aula-white" className="box-white w-70">
            <p
              className="mb-20"
              style={{ overflowWrap: 'anywhere' }}
              dangerouslySetInnerHTML={{ __html: conecta?.conteudo }}
            ></p>
          </div>
        </div>
      </div>
    </SidebarAndContainer>
  );
}

export default ConectaApresentacao;
