import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  IMatriculaCleanDTO,
  IMatriculaCustomDTO,
  IMatriculaDTO,
  IMatriculaFullDTO
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useMatriculaService = () => {
  const resourceURL = 'matricula';

  const findCustom = (
    predicate: Predicate = new Predicate(), cacheable = true
  ): Promise<AxiosResponse<IMatriculaCustomDTO[]>> => {

    let recurso = 'custom';

    if (!cacheable)
      recurso = 'custom-no-cache';

    return http.get<IMatriculaCustomDTO[]>(resourceURL + '/' + recurso + '/', formatHttpParams(predicate));

  };

  const cancelar = (id: number): Promise<IAxiosResponseCustom> =>
    http.post(`${resourceURL}/cancelar/${id}`);

  const baixarContrato = (matriculaId: number) =>
    http.get(`${resourceURL}/contrato/${matriculaId}`, {
      responseType: 'blob'
    });

  const trocarTurma = (body): Promise<IAxiosResponseCustom> =>
    http.post(`${resourceURL}/trocar-turma`, body);

  const findStudentName = (idMatricula: number): Promise<AxiosResponse<string>> =>
    http.get<string>(`${resourceURL}/student-name/${idMatricula}`);

  return {
    ...baseFind<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
      resourceURL
    ),
    ...baseFindById<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
      resourceURL
    ),
    ...baseFindList<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
      resourceURL
    ),
    ...baseCreate<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
      resourceURL
    ),
    createFull: (body): Promise<IAxiosResponseCustom<IMatriculaFullDTO>> =>
      http.post(`${resourceURL}`, body),
    patchFull: (
      toUpdate: any
    ): Promise<IAxiosResponseCustom<IMatriculaCleanDTO>> =>
      http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
    findCustom,
    cancelar,
    baixarContrato,
    trocarTurma,
    findStudentName
  };
};
