import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../global.css';
import { IUsuarioFullDTO } from '../../models/happy-code-api.model';
import { clear } from '../../util/store';
import ModalCreate from '../ModalCreate';
import { Tags } from '../Tags';
import { Avatar } from './../Avatar/index';
import './style.css';

interface Props {
  usuario: IUsuarioFullDTO;
}

const MenuDetalheUserProfile: React.FunctionComponent<Props> = ({
  usuario,
}: Props) => {
  const [isOpen] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    clear();
    navigate('/login');
  };

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1 wm-content">
        <Avatar size={115} arquivo={usuario?.arquivo} />
        <h1 id="title">{usuario?.pessoa?.nome}</h1>
        <div className="ativo">
          <div className="dot-verde"></div>
          <span>Ativo</span>
        </div>
        <h6 style={{ marginBottom: 0 }}>Email Pessoal</h6>
        <p>{usuario?.pessoa.email}</p>
        <div id="boxes-tags-detalhe">
          <Tags tipo={usuario?.funcao.descricao} />
        </div>
        <div id="boxes-tags-detalhe">
          <Tags tipo={usuario?.perfil.codigo} />
        </div>
        <div className="j-center-a-center col">
          <Link to="/usuarios/senha">
            <Button
              icon={
                <Unicons.UilEditAlt
                  size="16"
                  style={{ marginRight: 10 }}
                  color="var(--primary-color)"
                />
              }
              className="button-second"
              style={{ width: 258 }}
            >
              Alterar Senha
            </Button>
          </Link>
          <Button
            onClick={logout}
            icon={
              <Unicons.UilExport
                size="16"
                style={{ transform: 'rotate(90deg)', marginRight: 10 }}
                color="var(--red-color)"
              />
            }
            className="button-line-red"
            style={{ width: 258 }}
          >
            Sair
          </Button>
        </div>
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Usuário"
        textButton1="Criar outro usuário"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/usuarios/create/step-1"
        link2="/usuarios"
      />
    </div>
  );
};

export default MenuDetalheUserProfile;
