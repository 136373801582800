import Header from '../../../../components/Header';
import ContainerWrapper
  from '../../../../components/ContainerWrapper/container-wrapper.component';
import Title from '../../../../components/title/title.component';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import './lancamento-aula-edit.styles.scss';
import ConteudoMinistradoWrapper
  from './components/conteudo-ministrado-wrapper/conteudo-ministrado-wrapper.component';
import StudentSkillWrapper
  from './components/student-skill-wrapper/student-skill-wrapper.component';
import useLancamentoAulaEdit from './use-lancamento-aula-edit.hook';

export default function LancamentoAulaEdit() {
  const navigate = useNavigate();
  useLancamentoAulaEdit();

  return (
    <>
      <Header />
      <ContainerWrapper hasHeader>
        <div className={'lancamento-aula-edit__title-button'}>
          <Title title={'Editar Aula Lançada'} />
          <Button
            onClick={() => navigate(-1)}
            className="button-second"
            style={{
              width: '40px',
              height: '40px'
            }}
            icon={<CloseOutlined />}
          />
        </div>

        <ConteudoMinistradoWrapper />
        <StudentSkillWrapper />

      </ContainerWrapper>
    </>
  );
}
