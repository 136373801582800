import { Button } from 'antd';
import * as Unicons from '@iconscout/react-unicons';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalMentoria = (props: Props) => {
  const { open, onClose } = props;

  if (!open) return null;

  return (
    <>
      <div className="d-flex-row j-center-a-center modal-container">
        <div className="d-flex-column j-start-a-center modal">
          <div className="d-flex-row j-center-a-center circle mb-10">
            <Unicons.UilCheck color="var(--check)" size={80} />
          </div>
          <h1 className="mb-10">Mentoria agendada com sucesso</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui
            sagittis massa congue ac nisl tellus. Nulla tempor non et.
          </p>
          <div className="line"></div>
          <div className="d-flex-row button-box w-100">
            <Button className="button-primary" onClick={onClose}>
              Voltar a página inicial
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
