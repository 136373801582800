import { SentimentSatisfiedAltOutlined } from '@mui/icons-material';
import useAulaInformacaoBox from './use-aula-informacao-box.hook';
import './aula-informacao-box.styles.scss';
import { CalendarOutlined } from '@ant-design/icons';
import { formatDateToPattern } from '../../../../util/format-date.util';

export default function AulaInformacaoBox() {
  const {
    getQuantidadeAlunosLabel,
    aulaInformacaoBox
  } = useAulaInformacaoBox();

  return (
    <div className="aula-informacao-box">
      <h5 className="aula-informacao-box__titulo">
        {aulaInformacaoBox?.turmaNome ?? 'Turma'}
      </h5>

      <div className={'aula-informacao-box__data-info-icones'}>
        <div className="data-info-icones__info-icones">
          <CalendarOutlined />
          <p>{formatDateToPattern(aulaInformacaoBox?.data ?? '')}</p>
        </div>

        <div className="data-info-icones__info-icones">
          <SentimentSatisfiedAltOutlined />
          <p>{getQuantidadeAlunosLabel}</p>
        </div>
      </div>
    </div>
  );
}
