import Header from '../../../../../components/Header';
import { Button } from 'antd';
import ContainerWrapper
  from '../../../../../components/ContainerWrapper/container-wrapper.component';
import { CloseOutlined } from '@ant-design/icons';
import Title from '../../../../../components/title/title.component';
import '../lancamento-aula.styles.scss';
import AulaInformacaoBox
  from '../../../components/aula-informacao-box/aula-informacao-box.component';
import StepsBox from '../../../components/steps-box/steps-box.component';
import useBackClassroomPage from '../../../hooks/use-back-classroom-page.hook';
import ConteudoMinistradoForm
  from './components/conteudo-ministrado-form/conteudo-ministrado-form.component';
import useLancamentoAulaStep1 from './use-lancamento-aula-step-1.hook';

export default function LancamentoAulaStep1() {
  const { backClassroomPage } = useBackClassroomPage();
  const { formObject } = useLancamentoAulaStep1();

  return (
    <>
      <Header />
      <ContainerWrapper hasHeader>
        <div className={'lancamento-aula__title-button'}>
          <Title title={'Conteúdo Ministrado'} />
          <Button
            onClick={backClassroomPage}
            className="button-second"
            style={{
              width: '40px',
              height: '40px'
            }}
            icon={<CloseOutlined />}
          />
        </div>

        <div className={'lancamento-aula-wrapper'}>
          <div
            className={
              'lancamento-aula-wrapper__aula-information-steps-container'
            }
          >
            <AulaInformacaoBox />
            <StepsBox current={0} title={'Lançar Aula'} />
          </div>

          <ConteudoMinistradoForm {...formObject} />
        </div>
      </ContainerWrapper>
    </>
  );
}
