import useFindRelatorioFrequencias
  from '../../../hooks/use-find-relatorio-frequencias.hook';
import { useEffect } from 'react';
import {
  useFindConteudoAula
} from '../../../hooks/use-find-conteudo-aula.hook';
import useConteudoAlreadyRegistered
  from '../step-1/hooks/use-conteudo-already-registered.hook';
import {
  useGenericFrequenciaSkillFormHook
} from '../../../hooks/use-generic-frequencia-skill-form.hook';

export default function useLancamentoAulaStep2() {
  const { relatorioFrequencias, getRelatorioFrequencias } =
    useFindRelatorioFrequencias();
  const { getConteudoAula } = useFindConteudoAula();
  const { checkIfConteudoExists } = useConteudoAlreadyRegistered();

  const { submitForm } = useGenericFrequenciaSkillFormHook({
    isEdit: false
  });

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      getRelatorioFrequencias(),
      getConteudoAula(),
      checkIfConteudoExists()
    ]);

    return () => controller.abort();
  }, [
    getConteudoAula,
    getRelatorioFrequencias,
    checkIfConteudoExists
  ]);

  return {
    form: {
      submit: submitForm
    },
    relatorioFrequencias
  };
}
