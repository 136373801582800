import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Coin from '../../assets/img/dashboard/coin.svg';
import { IWebinarDTO } from '../../models/happy-code-api.model';
import { PresentImage } from '../ApresentarImagem';
import './webinar-card.styles.scss';

interface WebinarProps {
  webinar: IWebinarDTO;
}

export const Webinar = ({ webinar }: WebinarProps) => {
  const webinarThumbnail = webinar.capa
    ? webinar.capa
    : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY +
      webinar.arquivo.url;

  // const happyCoinsValue = webinar?.happyCoins || 0;

  return (
    <div className="webinar-card-container">
      <div className="webinar-card-container__webinar-infos">
        <div className="webinar-infos__webinar-image-title">
          <PresentImage link={webinarThumbnail} />
          <h1 className="webinar-image-title__title">{webinar?.nome}</h1>
        </div>

        <div className="webinar-infos__webinar-rewards">
          {/* <Unicons.UilGift size={24} color="var(--secondary-color)" />

          <div className="webinar-rewards__reward-label-value">
            <label className="reward-label-value__label">Recompensa</label>
            <div className="reward-label-value__rewards-coins">
              <p>h¢ {happyCoinsValue}</p>
              <img src={Coin} alt="Happy Coins" className="coin-trilha" />
            </div>
          </div> */}
        </div>
      </div>

      <Link
        to={`/webinar-apresentacao/${webinar.id}`}
        className="button-primary"
      >
        Acessar webinar
      </Link>
    </div>
  );
};
