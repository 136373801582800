import { ChangeEvent } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import { IAcaAulaCustomDTO } from '../../../../models/happy-code-api.model';

interface UseAulasPaginationProps {
  findAulasCustom: (page?: number) => Promise<void>;
  aulasData: PageableResponse<IAcaAulaCustomDTO>;
}

export default function useAulasPagination({
  findAulasCustom,
  aulasData,
}: UseAulasPaginationProps) {
  const aulasPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    findAulasCustom(page);
  };

  const currentPage = aulasData ? aulasData.pageable.pageNumber + 1 : 0;

  const countOfPages = aulasData ? aulasData.totalPages : 0;

  return {
    aulasPageChange,
    currentPage,
    countOfPages,
  };
}
