import { ChangeEvent } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import {
  ILancamentoAulasTableDTO
} from '../../../../models/happy-code-api.model';
import GenericPageableTable
  from '../../../../components/GenericPageableTable/generic-pageable-table.component';
import { useNavigate } from 'react-router-dom';
import { isAfterDate } from '../../../../util/format';
import dayjs from 'dayjs';
import { Tags } from '../../../../components/Tags';
import { getAulaNumero } from './functions/get-aula-numero.function';

interface AulasTableProps {
  aulasTableData: PageableResponse<ILancamentoAulasTableDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
  page: number;
}

export default function AulasTable({
                                     aulasTableData,
                                     onPageChange,
                                     page
                                   }: AulasTableProps) {
  const navigate = useNavigate();

  const label = (item: ILancamentoAulasTableDTO) =>
    item?.isAulaLancada ? 'Editar' : 'Lançar';

  const onClick = (item: ILancamentoAulasTableDTO) => {
    return item?.isAulaLancada
      ? navigate(`/turmas/professor/lancamento-aula/edit/${item?.aulaId}`)
      : navigate(
        `/turmas/professor/lancamento-aula/create/step-1/${item?.aulaId}`
      );
  };

  return (
    <>
      <GenericPageableTable
        data={aulasTableData}
        columns={[
          {
            key: 'nome',
            title: 'NOME',
            render: (item) =>
              `Aula ${getAulaNumero(page, aulasTableData, item)}`
          },
          {
            key: 'data',
            title: 'DATA',
            render: (item) => dayjs(item?.data).format('DD/MM/YYYY')
          },
          {
            key: 'possuiLancamento',
            title: 'STATUS',
            render: (item) => (
              <Tags tipo={item?.isAulaLancada ? 'lancado' : 'pendente'} />
            )
          },
          {
            key: 'conteudoMinistrado',
            title: 'CONTEÚDO MINISTRADO',
            render: () => '-'
          },
          {
            key: 'alunosPresentes',
            title: 'ALUNOS PRESENTES'
          },
          {
            key: 'alunosFaltosos',
            title: 'ALUNOS FALTOSOS'
          }
        ]}
        rowActions={[
          {
            label: label,
            conditionToRender: ({ isAulaLancada, data }) =>
              !isAulaLancada || isAfterDate(data),
            onClick: onClick
          }
        ]}
        onPageChange={onPageChange}
      />
    </>
  );
}
