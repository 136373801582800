import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import '../../global.css';
import './style.css';

interface TInputs {
  title?: string;
  subtitle?: string;
  textButton1?: string;
  textButton2?: string;
  styleButton1?: string;
  styleButton2?: string;
  link1?: string;
  link2?: string;
  open?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setOpen?: (boo: boolean) => void;
}

const ModalCreate: React.FunctionComponent<TInputs> = ({
  open,
  title,
  textButton1,
  textButton2,
  styleButton1,
  styleButton2,
  link1,
  link2,
  subtitle,
  setOpen,
}: TInputs) => {
  if (!open) return null;

  return (
    <div className="modalContainer">
      <div className="modalBox">
        <div className="circle-check">
          <Unicons.UilCheck size="100" color="#1EBE5E" />
        </div>
        <h3>{title}</h3>
        <h6>{subtitle}</h6>
        <div className="line"></div>
        <div className="botoes">
          <Link to={link1}>
            <Button
              id="button1"
              style={{ minWidth: 145 }}
              className={styleButton1}
            >
              {textButton1}
            </Button>
          </Link>
          <Link to={link2}>
            <Button
              id="button2"
              onClick={() => setOpen && setOpen(false)}
              className={styleButton2}
              style={{ marginLeft: 8, minWidth: 145 }}
            >
              {textButton2}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ModalCreate;
