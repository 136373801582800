import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export const useNotificationService = () => {
  return (args: ArgsProps): void => {
    notification[args.type || 'success']({
      placement: 'bottomRight',
      ...args,
    });
  };
};
