import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

export default function useFindConteudoMinistrado() {
  const { id } = useParams();

  const findConteudoMinistrado = useCallback(() => {
    if (!id) return;
  }, [id]);

  useEffect(() => {
    const controller = new AbortController();

    findConteudoMinistrado();

    return () => controller.abort();
  }, [findConteudoMinistrado]);
}
