import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Form, Tooltip } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PresentImage } from '../../../components/ApresentarImagem';
import { CursoCard } from '../../../components/Curso/curso-card.component';
import IconButton from '../../../components/icon-button/icon-button.component';
import SidebarAndContainer from '../../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../../components/title/title.component';
import { useVerifyUserAvaliacao } from './hooks/use-verify-user-avaliacao.hook';
import './trilha-id.styles.scss';
import './use-trilha-id.hook';
import useTrilhaId from './use-trilha-id.hook';

export const CursosTrilha = () => {
  const navigate = useNavigate();
  const {
    trilha,
    nome,
    onPageChange,
    setSearch,
    cursoData,
    icon,
    resultAccordionIsOpen,
    toggleResultAccordion,
    hasAvaliacao,
  } = useTrilhaId();

  useVerifyUserAvaliacao();


  return (
    <SidebarAndContainer cursos={true}>
      <div className="trilha-id-container__top-content">
        <div className="top-content__back-button-icon-title">
          <Link to="/cursos" className="button-second">
            <Unicons.UilArrowLeft />
            Voltar
          </Link>

          <div className="back-button-icon-title__icon-title">
            {icon && <PresentImage size={40} link={icon} />}
            <Title title={nome} />
          </div>
        </div>

        {/* <div className="top-content__happy-coins-container">
          <label className="happy-coins-container__label">
            Conclua a trilha e ganhe:
          </label>

          <div className="happy-coins-container__coins-value">
            <p>h¢ {formattedHappyCoins}</p>
            <img src={Coin} alt="Happy Coin" className="coin-trilha" />
          </div>
        </div> */}
      </div>

      <Form.Item className="trilha-id-container__searchbar-input">
        <i className="searchbar-input__search-icon">
          <Unicons.UilSearch size="24" color="var(--dark-secondary)" />
        </i>
        <input
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Buscar por curso"
          className="searchbar-input__input-search"
        />
      </Form.Item>

      {resultAccordionIsOpen && (
        <div className="trilha-id-container__trihas-result-banner">
          <p>
            Encontramos {cursoData?.totalElements || 0} Resultados para sua
            busca
          </p>

          <IconButton icon={Unicons.UilTimes} onClick={toggleResultAccordion} />
        </div>
      )}
      <div className="trilha-id-container__avaliacao">
        <Button
          onClick={() => navigate(`/trilha/${trilha.id}/avaliacao`)}
          disabled={!hasAvaliacao}
          className="button-primary"
        >
          Iniciar Avaliação
        </Button>

        <div
          className="trilha-id-container__info-icon"
          style={{ margin: '2px 5px' }}
        >
          <Tooltip title="É preciso concluir todos os cursos para iniciar a avaliação">
            <Unicons.UilInfoCircle size="24" color="var(--dark-secondary)" />
          </Tooltip>
        </div>
      </div>

      <div className="trilha-id-container__trilha-cards">
        {cursoData &&
          cursoData.content?.map?.((curso) => (
            <CursoCard key={curso.id} trilha={trilha} curso={curso} />
          ))}
      </div>

      <div className="d-flex-row j-center-a-center w-100 pb-50">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={cursoData ? cursoData.pageable.pageNumber + 1 : 0}
          count={cursoData ? cursoData.totalPages : 0}
        />
      </div>
    </SidebarAndContainer>
  );
};
