import { ChangeEvent, useState } from 'react';
import { TurmaStatusForInput } from '../../../../../enum/turmas/turma-status-for-input.enum';
import { TurmasInputsValuesObject } from '../../../../../types/turmas/turmas-inputs-values-object.type';
import { HandleTurmasInputsValuesFiltersObject } from '../../../../../types/turmas/handle-turmas-inputs-values-filters-object.type';
import { useTurmasPredicateStore } from '../../../../../store/turmas/turmas-predicate.store';

export default function useHandleInputsFilters() {
  const [searchbarValue, setSearchbarValue] = useState('');
  const handleSearchbarValue = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchbarValue(event.target.value);

  const [modalidadeValue, setModalidadeValue] = useState<number | string>(
    'TDS',
  );
  const handleModalidadeValue = (modalidadeId: number | string) =>
    setModalidadeValue(modalidadeId);

  const [statusValue, setStatusValue] = useState<TurmaStatusForInput>(
    TurmaStatusForInput.InProgress,
  );

  const { turmasPredicate } = useTurmasPredicateStore();
  const handleStatusValue = (status: TurmaStatusForInput) => {
    turmasPredicate.setPage(0);

    const turmaStatusForInputValues = Object.values(TurmaStatusForInput);

    if (!turmaStatusForInputValues.includes(status)) return;

    setStatusValue(status);
  };

  const [cursoValue, setCursoValue] = useState<number | string>('TDS');

  const handleCursoValue = (cursoId: number) => setCursoValue(cursoId);

  const inputValuesObject: TurmasInputsValuesObject = {
    searchbar: searchbarValue,
    status: statusValue,
    modalidade: modalidadeValue,
    curso: cursoValue,
  };

  const handleInputsFiltersObject: HandleTurmasInputsValuesFiltersObject = {
    searchbar: handleSearchbarValue,
    status: handleStatusValue,
    modalidade: handleModalidadeValue,
    curso: handleCursoValue,
  };

  return {
    inputValuesObject,
    handleInputsFiltersObject,
  };
}
