import http from '../helpers/http-common';
import { IStemplayExternalAcessoDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';

export const useStemplayService = () => {
  const resourceURL = '/v1/external';

  const stemplayLogin = (
    body: any,
  ): Promise<IAxiosResponseCustom<IStemplayExternalAcessoDTO>> => {
    return http.post(`${resourceURL}/login-stemplay`, body);
  };

  return {
    stemplayLogin,
  };
};
