import {
  FrequenciaTipoEnum
} from '../../../../../enum/lancamento-aula/frequencia-tipo.enum';
import {
  TarefaTipoEnum
} from '../../../../../enum/lancamento-aula/tarefa-tipo.enum';
import {
  IHabilidadeCompetenciaDTO,
  IRelatorioFrequenciaSkillDTO
} from '../../../../../models/happy-code-api.model';
import {
  BaseFrequenciaSkillFormInterface
} from '../../../interfaces/base-frequencia-skill-form.interface';
import {
  HabilidadeCompetenciaOption
} from '../../../interfaces/habilidade-competencia-option.interface';

export default function useEnumMapper() {
  const frequenciaTipoEnumToNumber = (tipo: FrequenciaTipoEnum): number => {
    switch (tipo) {
      case FrequenciaTipoEnum.PRESENTE:
        return 1;
      case FrequenciaTipoEnum.ATRASADO:
        return 3;
      case FrequenciaTipoEnum.AUSENTE:
        return 2;
      default:
        return 1;
    }
  };

  const stringToFrequenciaTipo = (tipo: string) => {
    if (!tipo) return FrequenciaTipoEnum.PRESENTE;

    switch (tipo) {
      case 'PRE':
        return FrequenciaTipoEnum.PRESENTE;
      case 'ATR':
        return FrequenciaTipoEnum.ATRASADO;
      case 'AST':
        return FrequenciaTipoEnum.AUSENTE;
      default:
        return FrequenciaTipoEnum.PRESENTE;
    }
  };

  const frequenciaTarefaTipoEnumToNumber = (tipo: TarefaTipoEnum): number => {
    switch (tipo) {
      case TarefaTipoEnum.NAO_FEZ:
        return 1;
      case TarefaTipoEnum.FEZ:
        return 2;
      case TarefaTipoEnum.ATRASADO:
        return 3;
      case TarefaTipoEnum.NAO_APLICAVEL:
        return 4;
      default:
        return 1;
    }
  };

  const stringToFrequenciaTarefaEnum = (tipo: string) => {
    if (!tipo) return TarefaTipoEnum.NAO_FEZ;

    switch (tipo) {
      case 'NFZ':
        return TarefaTipoEnum.NAO_FEZ;
      case 'FEZ':
        return TarefaTipoEnum.FEZ;
      case 'ATR':
        return TarefaTipoEnum.ATRASADO;
      case 'NAP':
        return TarefaTipoEnum.NAO_APLICAVEL;
      default:
        return TarefaTipoEnum.NAO_FEZ;
    }
  };

  const habilidadeCompetenciaDtoToHabilidadeCompetenciaOption = (
    dto: IHabilidadeCompetenciaDTO[]
  ): HabilidadeCompetenciaOption[] => {
    return dto.map((item) => ({
      id: item.id,
      nome: item.nome,
      value: item.nota
    }));
  };

  const relatorioFrequenciaSkillDtoToBaseFrequenciaSkillFormInterface = (
    dto: IRelatorioFrequenciaSkillDTO
  ): BaseFrequenciaSkillFormInterface => {
    return {
      presenca: stringToFrequenciaTipo(dto?.frequencia?.tipo),
      frequenciaTarefa: stringToFrequenciaTarefaEnum(dto?.frequencia?.tarefaTipo),
      reforco: dto.frequencia.reforco ? 'Sim' : 'Não',
      habilidades:
        habilidadeCompetenciaDtoToHabilidadeCompetenciaOption(dto.habilidades),
      competencias:
        habilidadeCompetenciaDtoToHabilidadeCompetenciaOption(dto.competencias),
      projetoLink: dto.projeto?.link,
      projetoValue: dto.projeto?.value
    };
  };

  return {
    frequenciaTipo: {
      toNumber: frequenciaTipoEnumToNumber
    },
    frequenciaTarefa: {
      toNumber: frequenciaTarefaTipoEnumToNumber
    },
    relatorioFrequenciaSkillDtoToBaseFrequenciaSkillFormInterface
  };
}
