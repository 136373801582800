import { useCallback, useState } from "react";
import { ITipoConteudoCleanDTO } from "../../../../../../../../models/happy-code-api.model";
import { useLearnTipoConteudoService } from "../../../../../../../../services/learn/learn-tipo-conteudo.service";
import { Predicate } from "../../../../../../../../models/predicate.model";
import { convertToTitleCase } from "../../../../../../../../util/format-to-title-case.util";
import { SelectOptionInterface } from "../../../../../../interfaces/select-option.interface";

export default function useFindTipoConteudoMinistrado() {
  const service = useLearnTipoConteudoService();

  const [tipoConteudoMinistrado, setTipoConteudoMinistrado] = useState<
    SelectOptionInterface[]
  >([]);

  const buildPredicate = useCallback(() => {
    const predicate = new Predicate();

    predicate.addOption("ativo", true);

    return predicate;
  }, []);

  const mapDataToTipoConteudoMinistrado = useCallback(
    (data: ITipoConteudoCleanDTO[]): SelectOptionInterface[] =>
      data.map(({ id, descricao }) => ({
        value: id,
        label: convertToTitleCase(descricao),
      })),
    [],
  );

  const findTipoConteudoMinistrado = useCallback(async () => {
    const predicate = buildPredicate();

    await service
      .findCleanList(predicate)
      .then(({ data }) =>
        setTipoConteudoMinistrado(mapDataToTipoConteudoMinistrado(data)),
      );
  }, [buildPredicate, mapDataToTipoConteudoMinistrado]);

  return {
    tipoConteudoMinistrado,
    findTipoConteudoMinistrado,
  };
}
