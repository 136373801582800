import '../../lancamento-aula-edit.styles.scss';
import './student-skill-wrapper.styles.scss';
import useStudentSkillWrapper from './use-student-skill-wrapper.hook';
import StudentAccordion
  from '../../../../components/student-accordion/student-accordion.component';

export default function StudentSkillWrapper() {

  const {
    relatorioFrequencias,
    form
  } = useStudentSkillWrapper();

  return (
    <div className={'student-skill-wrapper'}>
      <h2 className={'student-skill-wrapper__title'}>Skills dos Alunos</h2>

      {relatorioFrequencias?.map((relatorioFrequencia, index) => (
        <StudentAccordion
          key={index}
          relatorio={relatorioFrequencia}
          textButton={'Editar'}
          form={form}
        />
      ))}
    </div>
  );
}
